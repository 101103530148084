import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { getActiveCommunity } from '../../selectors/CommunitySelectors';
import { imageFetch } from '../../services/preSignedAws';

export function CommunityFavicon() {
  const activeCommunity = useSelector(getActiveCommunity);
  const publicCommunityDetails = useSelector((state) => state?.community?.publicCommunityDetails);

  // condition to make sure custom css is applied on verify email, mobile and create account
  if (activeCommunity?.id) {
    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{activeCommunity?.name}</title>
          <link rel="icon" href={activeCommunity?.icon && imageFetch(activeCommunity.icon, {}, false)} />
          {/* <link title="custom-style" rel="stylesheet" href="http://localhost:3000/ggCss.css" crossOrigin /> */}
          {activeCommunity?.custom_css && <style>{activeCommunity?.custom_css}</style>}
        </Helmet>
      </HelmetProvider>
    );
  }
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{publicCommunityDetails?.name}</title>
        <link rel="icon" href={publicCommunityDetails?.icon && imageFetch(publicCommunityDetails.icon, {}, false)} />
        {/* <link title="custom-style" rel="stylesheet" href="http://localhost:3000/custom.css" crossOrigin /> */}
        {publicCommunityDetails?.custom_css && <style>{publicCommunityDetails?.custom_css}</style>}
      </Helmet>
    </HelmetProvider>
  );
}

export function PublicCommunityFavicon() {
  const activeCommunity = useSelector((state) => state?.community?.publicCommunityDetails);
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{activeCommunity?.name}</title>
        <link rel="icon" href={activeCommunity?.icon && imageFetch(activeCommunity.icon, {}, false)} />
      </Helmet>
    </HelmetProvider>
  );
}
