import { useDispatch } from 'react-redux';
import { getAuthToken } from '../constants/authUtils';
import { history, redirectToLoginDomain } from '../constants/utils';
import { setRedirectUrl } from '../store/actions/authActions';
import { store } from '../store/configureStore';

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();

  try {
    const authToken = getAuthToken();

    // Ye first routing
    // Verification
    if (authToken) {
      return <>{children}</>;
    }
    // Redirect to login domain

    const pathName = history.location.pathname;
    const blackListPath = ['/signin', '/'];

    if (blackListPath.indexOf(pathName) < 0 && !store?.getState()?.auth?.isManualLogout) {
      dispatch(setRedirectUrl(pathName.concat(history.location.search ? history.location.search : '')));
    }
    redirectToLoginDomain();
    return null;
  } catch (error) {
    console.log('🚀 ~ file: ProtectedRoute.js ~ line 31 ~ ProtectedRoute ~ error', error);
    // Redirect to login domain

    redirectToLoginDomain();

    return null;
  }
}

export default ProtectedRoute;
