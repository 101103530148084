import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';
import { LOGIN_IFRAME_ID } from '../constants';
import { deleteAuthCookie, setAuthCookie } from '../constants/authUtils';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { parseAxiosErrorMessage, redirectToLoginDomain, history } from '../constants/utils';
import AnalyticsService from '../services/AnalyticsService';
import {
  bubbleSignInRequest,
  checkEmailExists,
  customLoginRequest,
  googleSignInRequest,
  login,
  logoutUser,
  registerWithEmail,
  verifyAuthCode,
} from '../services/authServices';
import NotificationService from '../services/notificationService';
import {
  AUTH_LOG_OUT,
  AUTH_LOG_OUT_BACKGROUD,
  AUTH_LOG_OUT_SUCCESS,
  BUBBLE_SIGN_IN_FAILED,
  BUBBLE_SIGN_IN_REQUEST,
  BUBBLE_SIGN_IN_SUCCESS,
  CHECK_EMAIL_FAILED,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CUSTOM_LOGIN_FAILED,
  CUSTOM_LOGIN_REQUEST,
  CUSTOM_LOGIN_SUCCESS,
  GOOGLE_SIGN_IN_FAILED,
  GOOGLE_SIGN_IN_REQUEST,
  GOOGLE_SIGN_IN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SIGN_UP_WITH_EMAIL_FAILED,
  SIGN_UP_WITH_EMAIL_REQUEST,
  SIGN_UP_WITH_EMAIL_SUCCESS,
} from '../store/actions/actionTypes';
import {
  authSetRefreshToken,
  authSetToken,
  deleteCheckEmail,
  setIsManualLogout,
  setOnboarded,
  setRedirectUrl,
  setRoomToken,
  setUserEmail,
  setUserId,
} from '../store/actions/authActions';
import { setUser } from '../store/actions/userActions';
import { store } from '../store/configureStore';
import { postCrossDomainMessage } from '../views/login/loginUtils';

const redirectToLoginApp = () => {
  window.location.href = `${process.env.REACT_APP_LOGIN_CLIENT}/login?request_host=${window.location.host}`;
};

export function* loginRequest() {
  yield takeLatest(LOGIN_REQUEST, function* ({ payload }) {
    try {
      const response = yield login(payload);

      const {
        token, user, refresh_token, onboarding_status,
      } = response;

      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(payload.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        user.data.attributes.onboarding_status = onboarding_status;
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);
        if (user.data.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user.data.attributes.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data.attributes));
      }
      yield put({
        type: LOGIN_SUCCESS,
        payload: response,
      });

      if (payload.requestHost) {
        postCrossDomainMessage(
          LOGIN_IFRAME_ID,
          {
            token: response.token,
            refreshToken: refresh_token.crypted_token,
          },
          payload.requestHost,
        );
        setTimeout(() => {
          window.location.href = `https://${payload.requestHost}`;
        }, 0);
      }

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: LOGIN_FAILED,
        payload: {},
      });
    }
  });
}

export function* checkEmailRequest() {
  yield takeLatest(CHECK_EMAIL_REQUEST, function* ({ payload }) {
    try {
      yield checkEmailExists(payload);

      yield put({
        type: CHECK_EMAIL_SUCCESS,
        payload,
      });
    } catch (error) {
      yield put({
        type: CHECK_EMAIL_FAILED,
        payload,
      });
    }
  });
}

export function* authLogout() {
  yield takeLatest(AUTH_LOG_OUT, function* ({ payload }) {
    try {
      if (payload?.isManualLogout) {
        yield put(setIsManualLogout());
        yield logoutUser();
        localStorage.removeItem('ReferredBy');
        localStorage.removeItem('MyReferralCode');
        localStorage.removeItem('InviteCode');
      }

      const mixpanelProperties = sessionStorage.getItem('MixpanelSessionProperties');
      if (mixpanelProperties) {
        AnalyticsService.logMixpanelEvent('Community Session Duration', JSON.parse(mixpanelProperties));
        const StageIsConnected = JSON.parse(sessionStorage.getItem('StageIsConnected') || false);
        if (StageIsConnected) {
          AnalyticsService.logMixpanelEvent('Stage Connected Duration', JSON.parse(mixpanelProperties));
        }
      }

      yield deleteAuthCookie();
      const scenes_login_enable = store.getState()?.community?.publicCommunityDetails?.scenes_login_enable;
      const custom_sso = store.getState()?.community?.publicCommunityDetails?.custom_sso || [];
      const logout_redirect_uri = custom_sso[0]?.logout_metadata?.logout_redirect_uri;
      const logout_uri = custom_sso[0]?.logout_metadata?.logout_uri;

      if (custom_sso && custom_sso.length > 0) {
        if (scenes_login_enable) {
          if (logout_uri) {
            const client_id = custom_sso[0]?.client_id;
            const logout_redirect_param = custom_sso[0]?.logout_metadata?.logout_redirect_param;

            let logoutURI = null;
            if (!(logout_redirect_param && logout_redirect_uri)) {
              logoutURI = `${logout_uri}?client_id=${client_id}`;
            } else if (logout_redirect_param && logout_redirect_uri) {
              logoutURI = `${logout_uri}?client_id=${client_id}&${logout_redirect_param}=${logout_redirect_uri}`;
            } else {
              redirectToLoginDomain();
              return;
            }
            if (logoutURI) {
              window.location.href = logoutURI;
            } else {
              redirectToLoginApp();
            }
          } else {
            redirectToLoginDomain();
            return;
          }
        } else if (!scenes_login_enable) {
          if (logout_redirect_uri || logout_uri) {
            if (custom_sso[0]?.logout_metadata) {
              const client_id = custom_sso[0]?.client_id;
              const logout_redirect_uri = custom_sso[0]?.logout_metadata?.logout_redirect_uri;
              const logout_uri = custom_sso[0]?.logout_metadata?.logout_uri;
              const logout_redirect_param = custom_sso[0]?.logout_metadata?.logout_redirect_param;

              let logoutURI = null;

              if (logout_uri) {
                if (!(logout_redirect_param && logout_redirect_uri)) {
                  logoutURI = `${logout_uri}?client_id=${client_id}`;
                } else if (logout_redirect_param && logout_redirect_uri) {
                  logoutURI = `${logout_uri}?client_id=${client_id}&${logout_redirect_param}=${logout_redirect_uri}`;
                } else {
                  redirectToLoginDomain();
                  return;
                }
              } else {
                redirectToLoginDomain();
                return;
              }

              if (logoutURI) {
                window.location.href = logoutURI;
              } else {
                redirectToLoginApp();
              }
            } else {
              redirectToLoginApp();
            }
          } else {
            redirectToLoginDomain();
          }
        }
      } else {
        redirectToLoginApp();
      }

      yield put({
        type: AUTH_LOG_OUT_SUCCESS,
      });
      AnalyticsService.clearUser();
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export function* authLogoutBackgroud() {
  yield takeLatest(AUTH_LOG_OUT_BACKGROUD, function* ({ payload }) {
    try {
      yield put(setIsManualLogout());
      localStorage.removeItem('ReferredBy');
      localStorage.removeItem('MyReferralCode');
      localStorage.removeItem('InviteCode');

      const mixpanelProperties = sessionStorage.getItem('MixpanelSessionProperties');
      if (mixpanelProperties) {
        AnalyticsService.logMixpanelEvent('Community Session Duration', JSON.parse(mixpanelProperties));
        const StageIsConnected = JSON.parse(sessionStorage.getItem('StageIsConnected') || false);
        if (StageIsConnected) {
          AnalyticsService.logMixpanelEvent('Stage Connected Duration', JSON.parse(mixpanelProperties));
        }
      }

      yield deleteAuthCookie();
      const scenes_login_enable = store.getState()?.community?.publicCommunityDetails?.scenes_login_enable;
      const custom_sso = store.getState()?.community?.publicCommunityDetails?.custom_sso || [];
      const logout_redirect_uri = custom_sso[0]?.logout_metadata?.logout_redirect_uri;
      const logout_uri = custom_sso[0]?.logout_metadata?.logout_uri;

      if (custom_sso && custom_sso.length > 0) {
        if (scenes_login_enable) {
          if (logout_uri) {
            const client_id = custom_sso[0]?.client_id;
            const logout_redirect_param = custom_sso[0]?.logout_metadata?.logout_redirect_param;

            let logoutURI = null;
            if (!(logout_redirect_param && logout_redirect_uri)) {
              logoutURI = `${logout_uri}?client_id=${client_id}`;
            } else if (logout_redirect_param && logout_redirect_uri) {
              logoutURI = `${logout_uri}?client_id=${client_id}&${logout_redirect_param}=${logout_redirect_uri}`;
            } else {
              redirectToLoginDomain();
              return;
            }
            if (logoutURI) {
              window.location.href = logoutURI;
            } else {
              redirectToLoginApp();
            }
          } else {
            redirectToLoginDomain();
            return;
          }
        } else if (!scenes_login_enable) {
          if (logout_redirect_uri || logout_uri) {
            if (custom_sso[0]?.logout_metadata) {
              const client_id = custom_sso[0]?.client_id;
              const logout_redirect_uri = custom_sso[0]?.logout_metadata?.logout_redirect_uri;
              const logout_uri = custom_sso[0]?.logout_metadata?.logout_uri;
              const logout_redirect_param = custom_sso[0]?.logout_metadata?.logout_redirect_param;

              let logoutURI = null;

              if (logout_uri) {
                if (!(logout_redirect_param && logout_redirect_uri)) {
                  logoutURI = `${logout_uri}?client_id=${client_id}`;
                } else if (logout_redirect_param && logout_redirect_uri) {
                  logoutURI = `${logout_uri}?client_id=${client_id}&${logout_redirect_param}=${logout_redirect_uri}`;
                } else {
                  redirectToLoginDomain();
                  return;
                }
              } else {
                redirectToLoginDomain();
                return;
              }

              if (logoutURI) {
                window.location.href = logoutURI;
              } else {
                redirectToLoginApp();
              }
            } else {
              redirectToLoginApp();
            }
          } else {
            redirectToLoginDomain();
          }
        }
      } else {
        redirectToLoginApp();
      }
      yield put({
        type: AUTH_LOG_OUT_SUCCESS,
      });
      AnalyticsService.clearUser();
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export function* signUpWithEmail() {
  yield takeLatest(SIGN_UP_WITH_EMAIL_REQUEST, function* ({ payload }) {
    try {
      const response = yield registerWithEmail(payload);

      const {
        token, user, refresh_token, onboarding_status,
      } = response;

      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(payload.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        user.data.attributes.onboarding_status = onboarding_status;
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);

        if (user.data.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user.data.attributes.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data.attributes));
      }
      yield put({
        type: SIGN_UP_WITH_EMAIL_SUCCESS,
        payload: response,
      });

      NotificationService.success(SUCCESS_MESSAGES.SIGNUP_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: SIGN_UP_WITH_EMAIL_FAILED,
        payload,
      });
    }
  });
}

export function* googleSignIn() {
  yield takeLatest(GOOGLE_SIGN_IN_REQUEST, function* ({ payload }) {
    try {
      const response = yield googleSignInRequest(payload);

      yield put({
        type: GOOGLE_SIGN_IN_SUCCESS,
        payload: response,
      });

      const {
        token, user, refresh_token, onboarding_status,
      } = response;

      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(payload.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        user.data.attributes.onboarding_status = onboarding_status;
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);

        if (user.data.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user.data.attributes.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data.attributes));
      }

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: GOOGLE_SIGN_IN_FAILED,
      });
    }
  });
}

export function* bubbleSignIn() {
  yield takeLatest(BUBBLE_SIGN_IN_REQUEST, function* ({ payload }) {
    try {
      const response = yield bubbleSignInRequest(payload);
      const { redirectUrl } = store.getState().auth;
      yield put({
        type: BUBBLE_SIGN_IN_SUCCESS,
        payload: response,
      });

      const {
        token, user, refresh_token, onboarding_status,
      } = response;
      setAuthCookie(token, refresh_token?.crypted_token, user?.data?.id);
      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(user?.data?.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        user.data.attributes.onboarding_status = onboarding_status;
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);

        if (user?.data?.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user?.data?.attributes?.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        if (user?.data?.attributes?.referral_code) {
          localStorage.setItem('MyReferralCode', user.data.attributes?.referral_code);
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data.attributes));
      }
      setTimeout(() => {
        if (redirectUrl) {
          window.location.href = window.location.origin + redirectUrl;
        } else {
          window.location.href = window.location.origin;
        }
      }, 0);

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();
      yield put({
        type: BUBBLE_SIGN_IN_FAILED,
      });
    }
  });
}

export function* customLoginSaga() {
  yield takeLatest(CUSTOM_LOGIN_REQUEST, function* ({ payload }) {
    try {
      const data = yield customLoginRequest(payload);
      const code = data.redirect_uri.split('?code=')[1];
      const response = yield verifyAuthCode(code);
      const { redirectUrl } = store.getState().auth;
      yield put({
        type: CUSTOM_LOGIN_SUCCESS,
        payload: response,
      });

      const {
        token, user, refresh_token, onboarding_status,
      } = response;
      setAuthCookie(token, refresh_token?.crypted_token, user?.data?.id);
      if (token) {
        yield put(authSetToken(response.token));
        yield put(setUserEmail(user?.data?.email));
        yield put(deleteCheckEmail());
      }

      if (refresh_token) {
        yield put(authSetRefreshToken(refresh_token.crypted_token));
      }

      if (user) {
        user.data.attributes.onboarding_status = onboarding_status;
        AnalyticsService.setMixpanelIdentify(user.data.attributes.id);
        AnalyticsService.setUserAttributes(user.data.attributes);

        if (user?.data?.attributes.id) {
          yield put(setUserId(user.data.attributes.id));
        }
        if (user?.data?.attributes?.room_token) {
          yield put(setRoomToken(user.data.attributes.room_token));
        }
        if (user?.data?.attributes?.referral_code) {
          localStorage.setItem('MyReferralCode', user.data.attributes?.referral_code);
        }
        yield put(setOnboarded(user.data.attributes.onboarding_status));
        yield put(setUser(user.data.attributes));
      }
      setTimeout(() => {
        if (redirectUrl) {
          window.location.href = window.location.origin + redirectUrl;
        } else {
          window.location.href = window.location.origin;
        }
      }, 0);

      NotificationService.success(SUCCESS_MESSAGES.LOGIN_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      AnalyticsService.clearUser();

      yield put({
        type: CUSTOM_LOGIN_FAILED,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(checkEmailRequest),
    fork(signUpWithEmail),
    fork(googleSignIn),
    fork(authLogout),
    fork(authLogoutBackgroud),
    fork(bubbleSignIn),
    fork(customLoginSaga),
  ]);
}
