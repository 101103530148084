import { FORUM_REQUEST_ORIGINS } from '../../constants';
import {
  ADD_FORUM_POST_COMMENT_REPLY_REQUEST,
  ADD_FORUM_POST_COMMENT_REQUEST,
  CREATE_FORUM_POST_REQUEST,
  DELETE_FORUM_POST_COMMENT_REPLY_REQUEST,
  DELETE_FORUM_POST_COMMENT_REQUEST,
  DELETE_FORUM_POST_REQUEST,
  FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST,
  FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE,
  FETCH_FORUM_POST_COMMENT_REQUEST,
  FETCH_FORUM_POST_COMMENT_SET_PAGE,
  FETCH_FORUM_POST_REQUEST,
  FETCH_FORUM_POST_SET_PAGE,
  FORUM_BOOKMARK_POST_REQUEST,
  FORUM_POSTS_RESET,
  FORUM_POST_COMMENTS_RESET,
  FORUM_POST_COMMENT_DOWN_VOTE_REQUEST,
  FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST,
  FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST,
  FORUM_POST_COMMENT_UP_VOTE_REQUEST,
  FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST,
  FORUM_POST_DOWN_VOTE_REQUEST,
  FORUM_POST_UP_VOTE_FROM_LIST_REQUEST,
  FORUM_POST_UP_VOTE_REQUEST,
  MARK_TOP_ANSWER,
  MARK_TOP_ANSWER_REQUEST,
  PIN_FORUM_POST_REQUEST,
  UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST,
  UPDATE_FORUM_POST_COMMENT_REQUEST,
  UPDATE_FORUM_POST_REQUEST,
} from './actionTypes';

export const createForumPost = (payload) => ({
  type: CREATE_FORUM_POST_REQUEST,
  payload,
});

export const updateForumPost = (
  communityId,
  channelId,
  postId,
  body,
  originType = FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE,
  history,
  groupId,
) => ({
  type: UPDATE_FORUM_POST_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    originType,
    history,
    ...body,
    group_id: groupId,
  },
});

export const bookmarkForumPost = (
  communityId,
  channelId,
  postId,
  groupId,
  originType = FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE,
) => ({
  type: FORUM_BOOKMARK_POST_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    originType,
    group_id: groupId,
  },
});

export const deleteForumPost = (
  communityId,
  channelId,
  postId,
  originType = FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE,
  groupId,
) => ({
  type: DELETE_FORUM_POST_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, originType, group_id: groupId,
  },
});

export const fetchForumPost = (communityId, channelId, page, groupId, isResetPost = false) => ({
  type: FETCH_FORUM_POST_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, page, isResetPost, group_id: groupId,
  },
});

export const setForumPagePost = (page) => ({
  type: FETCH_FORUM_POST_SET_PAGE,
  payload: { page },
});

export const fetchForumPostComment = (communityId, channelId, postId, page, groupId, isResetPost = false) => ({
  type: FETCH_FORUM_POST_COMMENT_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, page, isResetPost, group_id: groupId,
  },
});

export const addForumPostComment = (
  communityId,
  channelId,
  postId,
  description,
  groupId,
  mentionUserIds,
  image_url,
) => ({
  type: ADD_FORUM_POST_COMMENT_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    description,
    group_id: groupId,
    mentionUserIds,
    image_url,
  },
});

export const updateForumPostComment = (
  communityId,
  channelId,
  postId,
  commentId,
  description,
  groupId,
  mentionUserIds,
  image_url,
) => ({
  type: UPDATE_FORUM_POST_COMMENT_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    description,
    group_id: groupId,
    mentionUserIds,
    image_url,
  },
});

export const deleteForumPostComment = (communityId, channelId, postId, commentId, groupId) => ({
  type: DELETE_FORUM_POST_COMMENT_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    group_id: groupId,
  },
});

export const setPageForumPostComment = (commentPage) => ({
  type: FETCH_FORUM_POST_COMMENT_SET_PAGE,
  payload: { commentPage },
});

export const upVoteForumPost = (communityId, channelId, postId, groupId) => ({
  type: FORUM_POST_UP_VOTE_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, group_id: groupId,
  },
});

export const downVoteForumPost = (communityId, channelId, postId, groupId) => ({
  type: FORUM_POST_DOWN_VOTE_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, group_id: groupId,
  },
});

export const upVoteForumPostFromList = (communityId, channelId, postId, groupId) => ({
  type: FORUM_POST_UP_VOTE_FROM_LIST_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, group_id: groupId,
  },
});

export const downVoteForumPostFromList = (communityId, channelId, postId, groupId) => ({
  type: FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST,
  payload: {
    community_id: communityId, channel_id: channelId, post_id: postId, group_id: groupId,
  },
});

export const forumPostsReset = () => ({
  type: FORUM_POSTS_RESET,
});

export const forumPostCommentsReset = () => ({
  type: FORUM_POST_COMMENTS_RESET,
});

export const fetchForumPostCommentReplies = (
  communityId,
  channelId,
  postId,
  commentId,
  page = 1,
  groupId,
  isResetPost = false,
  mentionUserIds,
) => ({
  type: FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    page,
    comment_id: commentId,
    isResetPost,
    group_id: groupId,
    mentionUserIds,
  },
});

export const setPageForumPostReplyComment = (commentId, commentReplyPage) => ({
  type: FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE,
  payload: { commentId, commentReplyPage },
});

export const addForumPostCommentReply = (
  communityId,
  channelId,
  postId,
  commentId,
  description,
  groupId,
  mentionUserIds,
  image_url,
) => ({
  type: ADD_FORUM_POST_COMMENT_REPLY_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    description,
    group_id: groupId,
    mentionUserIds,
    image_url,
  },
});

export const updateForumPostCommentReply = (
  communityId,
  channelId,
  postId,
  commentId,
  replyId,
  description,
  groupId,
  mentionUserIds,
  image_url,
) => ({
  type: UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    reply_id: replyId,
    description,
    group_id: groupId,
    mentionUserIds,
    image_url,
  },
});

export const deleteForumPostCommentReply = (communityId, channelId, postId, commentId, replyId, groupId) => ({
  type: DELETE_FORUM_POST_COMMENT_REPLY_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    reply_id: replyId,
    group_id: groupId,
  },
});

export const addForumPostCommentUpVote = (communityId, channelId, postId, commentId, groupId) => ({
  type: FORUM_POST_COMMENT_UP_VOTE_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    group_id: groupId,
  },
});

export const addForumPostCommentDownVote = (communityId, channelId, postId, commentId, groupId) => ({
  type: FORUM_POST_COMMENT_DOWN_VOTE_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    group_id: groupId,
  },
});

export const addForumPostCommentReplyUpVote = (communityId, channelId, postId, commentId, replyId, groupId) => ({
  type: FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    reply_id: replyId,
    group_id: groupId,
  },
});

export const addForumPostCommentReplyDownVote = (communityId, channelId, postId, commentId, replyId, groupId) => ({
  type: FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    reply_id: replyId,
    group_id: groupId,
  },
});

export const pinForumPost = (
  communityId,
  channelId,
  postId,
  groupId,
  pinned,
) => ({
  type: PIN_FORUM_POST_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    group_id: groupId,
    pinned,
  },
});


export const markTopAnswer = (communityId, channelId, postId, commentId, groupId, flag) => ({
  type: MARK_TOP_ANSWER_REQUEST,
  payload: {
    community_id: communityId,
    channel_id: channelId,
    post_id: postId,
    comment_id: commentId,
    group_id: groupId,
    flag: flag
  },
});
