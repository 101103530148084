export const SUCCESS_MESSAGES = {
  LOGIN_SUCCESS: 'Login successfully',
  SIGNUP_SUCCESS: 'Signup successfully',
  EMAIL_VERIFIED: 'Email verified successfully',
  OTP_SENT_MOBILE: 'OTP sent to the mobile number',
  OTP_SENT_EMAIL: 'Code sent to the email',
  MOBILE_VERIFY: 'Mobile number verified successfully',
  ACCOUNT_CREATED: 'Account created successfully',
  POST_CREATE: 'New post added!',
  CHANNEL_CREATE: 'Channel created successfully',
  POST_UPDATE: 'Post updated successfully',
  POST_DELETE: 'Post deleted!',
  COMMENT_CREATE: 'New comment added!',
  COMMENT_UPDATE: 'Comment updated successfully',
  COMMENT_DELETE: 'Comment deleted!',
  COMMENT_REPLY_CREATE: 'New reply added!',
  CHANNEL_CATEGORY_CREATE: 'Category created successfully',
  COMMENT_REPLY_UPDATE: 'Comment reply updated successfully',
  COMMENT_REPLY_DELETE: 'Reply deleted!',
  ROLE_CREATE: 'New Role created successfully',
  ROLE_UPDATE: 'Role updated successfully',
  ROLE_DELETE: 'Role deleted successfully',
  CHANNEL_UPDATE: 'Channel updated successfully',
  CHANNEL_CATEGORY_UPDATE: 'Channel Category updated successfully',
  UPDATE_SUCCESS: 'Changes saved successfully!',
  COIN_UPDATE: 'Coin settings updated successfully!',
  COIN_CREATE: 'Coin setup successfull',
  COIN_DELETE: 'Coin deleted!',
  REPORT_USER: 'Your report has been submitted for review.',
  EVENT_CREATE: 'New event created!',
  EVENT_UPDATE: 'Event updated!',
  EVENT_DELETE: 'Event deleted!',
  EVENT_CANCEL: 'Event cancelled',
  BOOKMARK_CREATE: 'Post added to your bookmarks',
  BOOKMARK_DELETE: 'Post deleted from your bookmarks',
  CUSTOM_DOMAIN_REMOVED: 'Custom domain removed successfully',
  CREATE_CUSTOM_SSO_SUCCESS: 'SSO create successfully',
  UPDATE_CUSTOM_SSO_SUCCESS: 'SSO updated successfully',
  DELETE_CUSTOM_SSO_SUCCESS: 'SSO deleted successfully',
  ADD_CUSTOM_CSS_SUCCESS: 'Custom Css added successfully',
  REMOVE_CUSTOM_CSS_SUCCESS: 'Custom Css removed successfully',
  CREATE_GROUP_SUCCESS: 'Group created successfully',
  UPDATE_GROUP_SUCCESS: 'Group updated successfully',
  DELETE_GROUP_SUCCESS: 'Group deleted successfully',
  ADD_MOBILE_THEMING_SUCCESS: 'Mobile theming added successfully',
};

export const ERROR_MESSAGES = {
  SOMETHING_WENT_WRONG: 'Something went wrong!',
};
