import { Route } from 'react-router-dom';
import CommunityMemberVerification from './CommunityMemberVerification';
import ProtectedRoute from './ProtectedRoute';

const CustomRoute = ({ component: Component, isProtected, skipCommunityMemberVerification, ...rest }) => {
  // Return simple route
  if (!isProtected) {
    if (skipCommunityMemberVerification) {
      return <Route component={Component} {...rest} />;
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <CommunityMemberVerification>
            <Component {...props} />
          </CommunityMemberVerification>
        )}
      />
    );
  }

  if (skipCommunityMemberVerification) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <ProtectedRoute>
            <Component {...props} />
          </ProtectedRoute>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ProtectedRoute>
          <CommunityMemberVerification>
            <Component {...props} />
          </CommunityMemberVerification>
        </ProtectedRoute>
      )}
    />
  );
};
export default CustomRoute;
