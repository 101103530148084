import { all, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { history, parseAxiosErrorMessage } from '../constants/utils';
import {
  addCustomCss,
  addMobileTheming,
  changeCommunityAppearance,
  checkCommunityMember,
  createCommunityRequest,
  createGroup,
  deleteCustomCss,
  deleteGroup,
  fetchCategoriesRequest,
  fetchCommunityFeed,
  fetchGroups,
  fetchPublicCommunityDetails,
  joinCommunityService,
  myCommunitiesRequest,
  toggleGroups,
  updateCommunityRequest,
  updateGroup,
} from '../services/communityService';
import NotificationService from '../services/notificationService';
import {
  CREATE_COMMUNITY_REQUEST,
  CREATE_COMMUNITY_SUCCESS,
  UPDATE_COMMUNITY_REQUEST,
  UPDATE_COMMUNITY_SUCCESS,
  FETCH_ALL_COMMUNITY_REQUEST,
  FETCH_ALL_COMMUNITY_SUCCESS,
  FETCH_COMMUNITY_FEED,
  FETCH_COMMUNITY_FEED_SUCCESS,
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST,
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
  SET_ACTIVE_COMMUNITY,
  SET_ACTIVE_COMMUNITY_REQUEST,
  SET_COMMUNITY_LOADING,
  SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST,
  UPDATE_COMMUNITY_DATA,
  UPDATE_COMMUNITY_FAILED,
  CHANGE_COMMUNITY_APPEARANCE_REQUEST,
  CHANGE_COMMUNITY_APPEARANCE_SUCCESS,
  CHANGE_COMMUNITY_APPEARANCE_FAILED,
  ADD_CUSTOM_DOMAIN_REQUEST,
  ADD_CUSTOM_DOMAIN_SUCCESS,
  ADD_CUSTOM_DOMAIN_FAILED,
  VERIFY_CUSTOM_DOMAIN_REQUEST,
  VERIFY_CUSTOM_DOMAIN_SUCCESS,
  VERIFY_CUSTOM_DOMAIN_FAILED,
  JOIN_COMMUNITY_REQUEST,
  FETCH_CUSTOM_DOMAIN_DATA_REQUEST,
  REMOVE_CUSTOM_DOMAIN_REQUEST,
  REMOVE_CUSTOM_DOMAIN_FAILED,
  REMOVE_CUSTOM_DOMAIN_SUCCESS,
  ADD_CUSTOM_CSS_REQUEST,
  ADD_CUSTOM_CSS_FAILED,
  REMOVE_CUSTOM_CSS_REQUEST,
  REMOVE_CUSTOM_CSS_FAILED,
  ADD_CUSTOM_CSS_SUCCESS,
  REMOVE_CUSTOM_CSS_SUCCESS,
  VERIFY_COMMUNITY_MEMBER_REQUEST,
  VERIFY_COMMUNITY_MEMBER_SUCCESS,
  VERIFY_COMMUNITY_MEMBER_FAILED,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILED,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILED,
  FETCH_GROUPS_REQUEST,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  TOGGLE_GROUPS_REQUEST,
  TOGGLE_GROUPS_SUCCESS,
  SET_ACTIVE_GROUP_REQUEST,
  SET_GROUP_LOADING,
  SET_ACTIVE_GROUP,
  SET_INITIAL_COMMUNITY_DATA,
  DELETE_ACTIVE_CHANNEL,
  SET_COMMUNITY_PERMISSIONS,
  SET_HAS_CHANGED_CSS,
  SET_COMMUNITY_MEMBER_FLAG,
  ADD_MOBILE_THEMING_REQUEST,
  SET_COMMUNITY_SHOP_SUCCESS,
  UPDATE_PUBLIC_COMMUNITY_SETTINGS,
  UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST,
  SET_UNREAD_DM_COUNT,
  GET_GROUP_TAG,
} from '../store/actions/actionTypes';
import {
  fetchAllCommunity,
  setActiveCommunity,
  setActiveGroup,
  setVerifyCommunityMemberLoading,
} from '../store/actions/communityActions';
import { fetchAllCommunityChannels } from '../services/channelService';
import { setActiveChannel } from '../store/actions/channelActions';
import { postFeedDataModel } from '../services/models/communities/feedModel';
import {
  getCustomDomainData,
  removeCustomDomain,
  updateCustomDomain,
  verifyCustomDomain,
} from '../services/CustomDomainService';
import { ERROR_MESSAGES, SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { communityPermissionModal } from '../store/models/community/communityModel';
import { store } from '../store/configureStore';
import { fetchTags } from '../services/TagService';

function* fetchPublicCommunityDetailsSaga() {
  yield takeLatest(FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicCommunityDetails(payload);
      yield put({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchCategoriesSaga() {
  yield takeLatest(FETCH_CATEGORIES_REQUEST, function* () {
    try {
      const response = yield fetchCategoriesRequest();

      yield put({
        type: FETCH_CATEGORIES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createCommunitySaga() {
  yield takeLatest(CREATE_COMMUNITY_REQUEST, function* ({ payload }) {
    try {
      const response = yield createCommunityRequest(payload);

      yield put(setActiveCommunity(response?.data));
      yield put(fetchAllCommunity());

      yield put({
        type: CREATE_COMMUNITY_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateCommunitySaga() {
  yield takeLatest(UPDATE_COMMUNITY_REQUEST, function* ({ payload }) {
    try {
      let response = yield updateCommunityRequest(payload.community_id, payload.community_data);

      if (payload?.community_data?.hasOwnProperty('enable_groups')) {
        response = yield toggleGroups(payload.community_data.enable_groups);
      }

      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: response?.data,
      });

      const newGroupData = payload?.group_data;
      if (newGroupData?.id) {
        newGroupData.icon = response?.data?.icon;
        newGroupData.name = response?.data?.name;
        yield put({
          type: UPDATE_GROUP_SUCCESS,
          payload: newGroupData,
        });
      }

      yield put({
        type: UPDATE_COMMUNITY_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: UPDATE_COMMUNITY_FAILED,
      });
    }
  });
}

function* setActiveCommunitySaga() {
  yield takeLatest(SET_ACTIVE_COMMUNITY_REQUEST, function* ({ payload }) {
    try {
      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: true,
      });

      let community_id = payload?.id;

      if (payload?.get_active_community) {
        const response = yield myCommunitiesRequest();
        const hostedCommunities = response?.data?.hosted_communities || [];
        const joinedCommunities = response?.data?.my_communities || [];
        const communities = [...hostedCommunities, ...joinedCommunities];

        if (communities.length > 0) {
          community_id = communities[0]?.id;
        }

        yield put({
          type: FETCH_ALL_COMMUNITY_SUCCESS,
          payload: { communities, hostedCommunities, joinedCommunities },
        });
      }

      if (community_id) {
        yield put({
          type: SET_ACTIVE_COMMUNITY,
          payload: community_id,
        });
      }

      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: false,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: false,
      });
    }
  });
}

function* setNewActiveCommunitySaga() {
  yield takeLatest(SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST, function* ({ payload }) {
    try {
      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: true,
      });

      const community_id = payload?.id;

      if (community_id) {
        yield put({
          type: SET_ACTIVE_COMMUNITY,
          payload: Number(community_id),
        });
      }

      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: false,
      });

      let group_id = payload?.group_id;
      if (group_id) {
        if (payload?.currentGroups?.find((group) => group?.id == group_id)?.id) {
          yield put({
            type: SET_ACTIVE_GROUP,
            payload: group_id,
          });
        } else {
          const response = yield fetchGroups();

          const communityData = {
            // ...response?.data?.community,
            // groups: response?.data?.groups || [],
            // Moving
            community: { ...response?.data?.community, groups: response?.data?.groups },
            community_permissions: response?.data?.community_permissions,
            community_shop: response?.data?.community_shop,
          };

          yield put({
            type: SET_INITIAL_COMMUNITY_DATA,
            payload: communityData,
          });

          yield put({
            type: SET_UNREAD_DM_COUNT,
            payload: response?.data?.unread_dms_count,
          });

          yield put({
            type: SET_COMMUNITY_SHOP_SUCCESS,
            payload: {
              ...response?.data?.community_shop,
              stripe: { isStripeConnected: response?.data?.community_shop?.stripe_account_status === 'connected' },
            },
          });

          if (response.data.community_permissions?.length) {
            const communityPermissions = communityPermissionModal(response.data.community_permissions);
            yield put({
              type: SET_COMMUNITY_PERMISSIONS,
              payload: communityPermissions,
            });
          }

          const currentGroup = communityData?.community.groups?.find((d) => d.main_group);
          const doesGroupIdExists = !!communityData?.community.groups?.find((group) => group?.id == group_id)?.id;

          // To make sure on remounting of layout index.js the selected group stays mounted instead of parent group.
          if (!doesGroupIdExists) group_id = currentGroup?.id;
          if (!group_id && communityData?.community?.groups?.length > 0) {
            group_id = communityData?.community?.groups?.[0]?.id;
          } else if (communityData?.community?.groups?.length <= 0) {
            history.replace('/404');
          }

          yield put({
            type: SET_ACTIVE_GROUP,
            payload: group_id,
          });
        }
      }

      if (community_id && payload?.channel_id) {
        let currentChannel;

        // First check in current channels
        if (Array.isArray(payload.currentCommunityChannels)) {
          for (const data of payload.currentCommunityChannels) {
            if (data.community_id !== Number(community_id)) {
              break;
            }

            currentChannel = data?.channels?.find((c) => c.id === payload?.channel_id);
            if (currentChannel) {
              break;
            }
          }
        }

        // If new channel not found then fetch all the new channels for relevant community
        if (!currentChannel) {
          const response = yield fetchAllCommunityChannels({ id: community_id, groupId: group_id });
          const channels = response?.data?.group_categories;

          for (const data of channels) {
            currentChannel = data?.channels?.find((c) => c.id === payload?.channel_id);
            if (currentChannel) {
              break;
            }
          }
        }

        console.log('------- currentChannel -------', currentChannel);
        if (currentChannel) {
          yield put(setActiveChannel(currentChannel));
        } else {
          console.log('------- NOT FOUND CONDITION --------');
          payload?.history.replace('/not-found');
        }
      }

      if (payload?.setActiveGroupLoading) {
        yield put({
          type: SET_GROUP_LOADING,
          payload: false,
        });
      }
      const tags = yield fetchTags(community_id, group_id)

      yield put({
        type: GET_GROUP_TAG,
        payload: tags?.data?.data
      })

    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: SET_COMMUNITY_LOADING,
        payload: false,
      });
    }
  });
}

function* fetchAllCommunities() {
  yield takeLatest(FETCH_ALL_COMMUNITY_REQUEST, function* () {
    try {
      const response = yield myCommunitiesRequest();
      const hostedCommunities = response?.data?.hosted_communities || [];
      const joinedCommunities = response?.data?.my_communities || [];
      const communities = [...hostedCommunities, ...joinedCommunities];

      yield put({
        type: FETCH_ALL_COMMUNITY_SUCCESS,
        payload: { communities, hostedCommunities, joinedCommunities },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchCommunityFeedSaga() {
  yield takeLatest(FETCH_COMMUNITY_FEED, function* ({ payload }) {
    try {
      const response = yield fetchCommunityFeed(payload);
      const calendarEvents = response?.calendar_events || [];

      yield put({
        type: FETCH_COMMUNITY_FEED_SUCCESS,
        payload: { ...postFeedDataModel(response?.data), calendarEvents, isReset: payload.isReset },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* changeCommunityAppearanceRequest() {
  yield takeLatest(CHANGE_COMMUNITY_APPEARANCE_REQUEST, function* ({ payload }) {
    try {
      const response = yield changeCommunityAppearance(payload.communityId, payload.appearanceData);

      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: response?.data,
      });

      yield put({
        type: CHANGE_COMMUNITY_APPEARANCE_SUCCESS,
      });

      NotificationService.success(SUCCESS_MESSAGES.UPDATE_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: CHANGE_COMMUNITY_APPEARANCE_FAILED,
      });
    }
  });
}

function* addCustomDomainSaga() {
  yield takeLatest(ADD_CUSTOM_DOMAIN_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateCustomDomain(payload);
      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: response?.data,
      });

      yield put({
        type: ADD_CUSTOM_DOMAIN_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: ADD_CUSTOM_DOMAIN_FAILED,
      });
    }
  });
}

function* verifyCustomDomainSaga() {
  yield takeLatest(VERIFY_CUSTOM_DOMAIN_REQUEST, function* ({ payload }) {
    try {
      const response = yield verifyCustomDomain();

      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: response?.data,
      });

      yield put({
        type: VERIFY_CUSTOM_DOMAIN_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      if (message === 'Domain verification failed') {
        NotificationService.error(
          message,
          'if you have just added above fields to your domain then try again after sometime as it may take up to a few hours for DNS changes to take effect.',
        );
      } else {
        NotificationService.error(message);
      }
      yield put({
        type: VERIFY_CUSTOM_DOMAIN_FAILED,
      });
    }
  });
}

function* fetchCustomDomainDataSaga() {
  yield takeLatest(FETCH_CUSTOM_DOMAIN_DATA_REQUEST, function* () {
    try {
      const response = yield getCustomDomainData();

      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: response?.data?.attributes,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* removeCustomDomainSaga() {
  yield takeLatest(REMOVE_CUSTOM_DOMAIN_REQUEST, function* ({ communityId }) {
    try {
      const response = yield removeCustomDomain();
      if (response?.status == 204) {
        yield put({
          type: REMOVE_CUSTOM_DOMAIN_SUCCESS,
        });
        NotificationService.success(SUCCESS_MESSAGES.CUSTOM_DOMAIN_REMOVED);
        yield put({
          type: UPDATE_COMMUNITY_DATA,
          payload: {
            id: communityId,
            domain_address: '',
            domain_token: null,
            domain_expiry: null,
            domain_status: null,
          },
        });
      } else {
        yield put({
          type: REMOVE_CUSTOM_DOMAIN_FAILED,
        });
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: REMOVE_CUSTOM_DOMAIN_FAILED,
      });
    }
  });
}

function* addCustomCssSaga() {
  yield takeLatest(ADD_CUSTOM_CSS_REQUEST, function* ({ custom_css }) {
    try {
      const response = yield addCustomCss(custom_css);

      NotificationService.success(SUCCESS_MESSAGES.ADD_CUSTOM_CSS_SUCCESS);
      yield put({
        type: ADD_CUSTOM_CSS_SUCCESS,
      });
      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: {
          id: response?.data?.id,
          custom_css: response?.data?.custom_css,
        },
      });
      yield put({
        type: SET_HAS_CHANGED_CSS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: ADD_CUSTOM_CSS_FAILED,
      });
    }
  });
}

function* removeCustomCssSaga() {
  yield takeLatest(REMOVE_CUSTOM_CSS_REQUEST, function* () {
    try {
      const response = yield deleteCustomCss();

      NotificationService.success(SUCCESS_MESSAGES.REMOVE_CUSTOM_CSS_SUCCESS);
      yield put({
        type: REMOVE_CUSTOM_CSS_SUCCESS,
      });
      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: {
          id: response?.data?.id,
          custom_css: response?.data?.custom_css,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: REMOVE_CUSTOM_CSS_FAILED,
      });
    }
  });
}
function* checkCommunityMemberSaga() {
  yield takeLatest(VERIFY_COMMUNITY_MEMBER_REQUEST, function* () {
    try {
      yield put(setVerifyCommunityMemberLoading(true));

      const response = yield checkCommunityMember();

      yield put({
        type: VERIFY_COMMUNITY_MEMBER_SUCCESS,
        payload: response?.data?.community_user,
      });

      yield put(setVerifyCommunityMemberLoading(false));
    } catch (error) {
      yield put(setVerifyCommunityMemberLoading(false));
      if (error.response.status >= 500 && error.response.status <= 510) {
        store.dispatch({
          type: SET_COMMUNITY_MEMBER_FLAG,
          payload: null,
        });
        history.replace('/public/internal-server-error');
      } else {
        store.dispatch({
          type: SET_COMMUNITY_MEMBER_FLAG,
          payload: false,
        });
      }
    }
  });
}

function* toggleGroupsSaga() {
  yield takeLatest(TOGGLE_GROUPS_REQUEST, function* ({ enable }) {
    try {
      const response = yield toggleGroups(enable);
      yield put({
        type: TOGGLE_GROUPS_SUCCESS,
        payload: enable,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchGroupsSaga() {
  yield takeLatest(FETCH_GROUPS_REQUEST, function* () {
    try {
      const response = yield fetchGroups();

      const communityData = {
        // ...response?.data?.community,
        // groups: response?.data?.groups || [],
        // Moving
        community: { ...response?.data?.community, groups: response?.data?.groups },
        community_permissions: response?.data?.community_permissions,
        community_shop: response?.data?.community_shop,
      };

      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: communityData,
      });

      yield put({
        type: SET_UNREAD_DM_COUNT,
        payload: response?.data?.unread_dms_count,
      });

      yield put({
        type: FETCH_GROUPS_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: FETCH_GROUPS_FAILED,
      });
    }
  });
}

function* createGroupSaga() {
  yield takeLatest(CREATE_GROUP_REQUEST, function* ({ payload }) {
    try {
      const response = yield createGroup(payload.groupData, payload.community_role_ids);

      yield put({
        type: CREATE_GROUP_SUCCESS,
        payload: response?.data,
      });
      if (payload.resetForm) {
        payload.resetForm();
      }
      NotificationService.success(SUCCESS_MESSAGES.CREATE_GROUP_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: CREATE_GROUP_FAILED,
      });
    }
  });
}

function* updateGroupSaga() {
  yield takeLatest(UPDATE_GROUP_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateGroup(payload.id, payload.group);

      yield put({
        type: UPDATE_GROUP_SUCCESS,
        payload: response?.data,
      });
      NotificationService.success(SUCCESS_MESSAGES.UPDATE_GROUP_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: UPDATE_GROUP_FAILED,
      });
    }
  });
}

function* deleteGroupSaga() {
  yield takeLatest(DELETE_GROUP_REQUEST, function* ({ payload }) {
    try {
      const response = yield deleteGroup(payload.groupId);

      yield put({
        type: DELETE_GROUP_SUCCESS,
        payload: payload.groupId,
      });

      history.replace('/');
      NotificationService.success(SUCCESS_MESSAGES.DELETE_GROUP_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: DELETE_GROUP_FAILED,
      });
    }
  });
}

function* setActiveGroupSaga() {
  yield takeLatest(SET_ACTIVE_GROUP_REQUEST, function* ({ payload }) {
    try {
      yield put({
        type: SET_GROUP_LOADING,
        payload: true,
      });

      let group_id = payload?.id;
      let community_id = payload?.communityId
      if (payload?.get_all_group_and_set_active) {
        const response = yield fetchGroups();

        const communityData = {
          // ...response?.data?.community,
          // groups: response?.data?.groups || [],
          // Moving
          community: { ...response?.data?.community, groups: response?.data?.groups },
          community_permissions: response?.data?.community_permissions,
          community_shop: response?.data?.community_shop,
        };

        yield put({
          type: SET_INITIAL_COMMUNITY_DATA,
          payload: communityData,
        });

        yield put({
          type: SET_UNREAD_DM_COUNT,
          payload: response?.data?.unread_dms_count,
        });

        yield put({
          type: SET_COMMUNITY_SHOP_SUCCESS,
          payload: {
            ...response?.data?.community_shop,
            stripe: { isStripeConnected: response?.data?.community_shop?.stripe_account_status === 'connected' },
          },
        });

        if (response.data.community_permissions?.length) {
          const communityPermissions = communityPermissionModal(response.data.community_permissions);
          yield put({
            type: SET_COMMUNITY_PERMISSIONS,
            payload: communityPermissions,
          });
        }

        const currentGroup = communityData?.community?.groups?.find((d) => d.main_group);
        const doesGroupIdExists = !!communityData?.community?.groups?.find((group) => group?.id == group_id)?.id;

        // To make sure on remounting of layout index.js the selected group stays mounted instead of parent group.
        if (!doesGroupIdExists) group_id = currentGroup?.id;
        if (!group_id && communityData?.community?.groups?.length > 0) {
          group_id = communityData?.community?.groups?.[0]?.id;
        } else if (communityData?.community?.groups?.length <= 0) {
          history.replace('/404');
        }
      }

      if (group_id) {
        yield put({
          type: SET_ACTIVE_GROUP,
          payload: group_id,
        });
      }

      const tags = yield fetchTags(community_id, group_id)

      yield put({
        type: GET_GROUP_TAG,
        payload: tags?.data?.data
      })

      if (payload?.to_delete_active_channel) {
        yield put({
          type: DELETE_ACTIVE_CHANNEL,
        });
      }

      if (!payload?.skip_reset_group_loading) {
        yield put({
          type: SET_GROUP_LOADING,
          payload: false,
        });
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: SET_GROUP_LOADING,
        payload: false,
      });
    }
  });
}

function* addMobileThemingSaga() {
  yield takeLatest(ADD_MOBILE_THEMING_REQUEST, function* ({ payload }) {
    try {
      const response = yield addMobileTheming(payload.themingObj);

      NotificationService.success(SUCCESS_MESSAGES.ADD_MOBILE_THEMING_SUCCESS);
      yield put({
        type: UPDATE_COMMUNITY_DATA,
        payload: {
          id: response?.data?.id,
          mobile_appearance_metadata: response?.data?.mobile_appearance_metadata,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    } finally {
      if (payload.actions) payload.actions.setSubmitting(false);
    }
  });
}

function* updatePublicCommunitySettingsSaga() {
  yield takeLatest(UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST, function* ({ payload }) {
    try {
      yield put({
        type: UPDATE_PUBLIC_COMMUNITY_SETTINGS,
        payload,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchCategoriesSaga),
    fork(createCommunitySaga),
    fork(updateCommunitySaga),
    fork(setActiveCommunitySaga),
    fork(fetchAllCommunities),
    fork(setNewActiveCommunitySaga),
    fork(fetchCommunityFeedSaga),
    fork(changeCommunityAppearanceRequest),
    fork(fetchPublicCommunityDetailsSaga),
    fork(addCustomDomainSaga),
    fork(verifyCustomDomainSaga),
    fork(fetchCustomDomainDataSaga),
    fork(removeCustomDomainSaga),
    fork(addCustomCssSaga),
    fork(removeCustomCssSaga),
    fork(checkCommunityMemberSaga),
    fork(fetchGroupsSaga),
    fork(createGroupSaga),
    fork(updateGroupSaga),
    fork(deleteGroupSaga),
    fork(toggleGroupsSaga),
    fork(setActiveGroupSaga),
    fork(addMobileThemingSaga),
    fork(updatePublicCommunitySettingsSaga),
  ]);
}
