import server from '../api/serverVersionless';
import server2 from '../api/server';

export const fetchRoles = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/communities/${data.activeCommunityId}/community_roles`)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const createRole = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .post(`/communities/${data.activeCommunityId}/community_roles`, data.body)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const updateRoles = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .put(`/communities/${data.community_id}/community_roles/${data.id}`, data.body)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const deleteRole = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .delete(`/communities/${data.community_id}/community_roles/${data.id}`)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getUserCommunityRoleListService = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/communities/${data.id}/user_community_roles/list`, {
        params: {
          next_page_id: data.next_page_id || '',
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getUserRoles = (communityId, userId) => server.get(`/profile/${userId}?community_id=${communityId}`);

export const getSelectedUserRoles = (communityId, userId) =>
  server.get(`/communities/${communityId}/user_community_roles?user_id=${userId}`);

export const addSelectedUserRole = (communityId, roleId, userId) =>
  server.post(`/communities/${communityId}/community_roles/${roleId}/user_community_roles`, {
    user_community_role: {
      user_id: userId,
    },
  });

export const deleteSelectedUserRole = (communityId, communityRoleId, roleId) =>
  server.delete(`/communities/${communityId}/community_roles/${communityRoleId}/user_community_roles/${roleId}`);

export const searchCommunityMember = async (data) => {
  return new Promise((resolve, reject) => {
    server
      .post(`/communities/${data.id}/user_community_roles/search`, data.body)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getUserOfSelectedCommunityRole = (roleId, next_page_id = '') =>
  server.get(`user_community_roles/user_list?next_page_id=${next_page_id}&community_role_id=${roleId}`);

export const removeUserRole = (communityRoleId, userId) => {
  return new Promise((resolve, reject) => {
    server
      .delete(`/community_roles/${communityRoleId}/user_community_roles/remove`, {
        params: {
          user_id: userId,
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getRecentMembers = async (data = {}) => {
  return new Promise((resolve, reject) => {
    server
      .get(`/user_community_roles/recently_joined`, {
        params: {
          next_page_id: data?.next_page_id || '',
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getExistingMembers = async (data = {}) => {
  return new Promise((resolve, reject) => {
    server
      .get(`/user_community_roles/people_list`, {
        params: {
          next_page_id: data?.next_page_id || '',
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const searchCommunityMembers = async (data) => {
  return new Promise((resolve, reject) => {
    server2
      .post(`/communities/${data.id}/people_search`, {
        search:data.search || '',
        page:data?.page || 1,
        community_role_ids: data?.community_role_ids || ''
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const getPermissionsForRole = async (roleId) => {
  return new Promise((resolve, reject) => {
    server
      .get(`/community_roles/${roleId}`)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const fetchCommunityRoles = async () => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/community_roles/list`)
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};
