import { lazy } from 'react';

import { ROUTE_PATH } from '../constants';

const TextChannelOverview = lazy(() => import(/* webpackChunkName: "TextChannelOverview" */'../views/channel/textchannel/TextChannel'));
const CalendarPostDetailsPublic = lazy(() => import(/* webpackChunkName: "calendarPostDetailsPublic" */'../components/public/calendarPostDetailsPublic'));
const ChannelCategorySettings = lazy(() => import(/* webpackChunkName: "ChannelCategorySettings" */'../views/channelCategorySettings'));
const OverviewSettings = lazy(() => import(/* webpackChunkName: "OverviewSettings" */'../views/overviewSettings'));
const ChannelSettings = lazy(() => import(/* webpackChunkName: "ChannelSettings" */'../views/channelSettings'));
const ManageAccount = lazy(() => import(/* webpackChunkName: "ManageAccount" */ '../views/manageAccount'));
const CommunityLanding = lazy(() => import(/* webpackChunkName: "CommunityLanding" */'../views/community/CommunityLanding'));
const EnterCommunity = lazy(() => import(/* webpackChunkName: "EnterCommunity" */'../views/community/EnterCommunity'));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */'../views/layout'));
const AskForPhoneVerification = lazy(() => import(/* webpackChunkName: "AskForPhoneVerification" */'../views/login/AskForPhoneVerification'));
const BubbleCallback = lazy(() => import(/* webpackChunkName: "BubbleCallback" */'../views/login/BubbleCallback'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */'../views/login/Login'));
const VerifyEmailOTP = lazy(() => import(/* webpackChunkName: "VerifyEmailOTP" */'../views/login/VerifyEmailOTP'));
const OnboardingCreateAccount = lazy(() => import(/* webpackChunkName: "CreateAccount" */'../views/register/onboarding/CreateAccount'));
const Settings = lazy(() => import(/* webpackChunkName: "Settings" */'../views/settings'));
const Switcher = lazy(() => import(/* webpackChunkName: "Switcher" */'../views/switcher'));
const Inbox = lazy(() => import(/* webpackChunkName: "Inbox" */'../views/inbox'));
const OnboardingApplicationForm = lazy(() => import(/* webpackChunkName: "OnboardingApplicationForm" */'../views/register/onboarding/ApplicationForm'));
const OnboardingApplicationStatus = lazy(() => import(/* webpackChunkName: "OnboardingApplicationStatus" */'../views/register/onboarding/ApplicationStatus'));
const ApplicationError = lazy(() => import(/* webpackChunkName: "ApplicationError" */'../views/register/onboarding/ApplicationError'));
const GroupSettings = lazy(() => import(/* webpackChunkName: "GroupSettings" */'../views/groupSettings'));
const InternalServerError = lazy(() => import(/* webpackChunkName: "InternalServerError" */'../components/shared/tailwind/InternalServerError'));
const PublicLayout = lazy(() => import(/* webpackChunkName: "PublicOverview" */'../views/layout/PublicLayout'));
const NoData404 = lazy(() => import(/* webpackChunkName: "InternalServerError" */'../components/shared/tailwind/NoData404'));
const StreamChannel = lazy(() => import('../views/channel/stream/StreamChannel'));
const indexRoutes = [
  {
    path: '/login',
    component: Login,
    skipCommunityMemberVerification: true,
    exact: true,
  },
  {
    path: '/oauth2/callback',
    component: BubbleCallback,
    skipCommunityMemberVerification: true,
    exact: true,
  },
  {
    path: '/signin',
    component: CommunityLanding,
    skipCommunityMemberVerification: true,
    exact: true,
  },
  {
    path: '/enter-community',
    component: EnterCommunity,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  // {
  //   path: '/signup',
  //   component: Signup,
  //   exact: true,
  // },
  // {
  //   path: '/forgot-password',
  //   component: ForgotPassword,
  //   exact: true,
  // },
  // {
  //   path: '/reset-password/:email/:reset_token',
  //   component: ResetPassword,
  //   exact: true,
  // },
  {
    path: '/register/email/verify-otp',
    component: VerifyEmailOTP,
    isProtected: true,
    skipCommunityMemberVerification: true,
    exact: true,
  },
  {
    path: '/onboarding/mobile',
    component: AskForPhoneVerification,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  {
    path: '/onboarding/create-account',
    component: OnboardingCreateAccount,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  {
    path: '/onboarding/application-form',
    component: OnboardingApplicationForm,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  {
    path: '/onboarding/application-status',
    component: OnboardingApplicationStatus,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  {
    path: '/onboarding/application-error',
    component: ApplicationError,
    skipCommunityMemberVerification: true,
    isProtected: true,
    exact: true,
  },
  // {
  //   path: '/onboarding/invite-friend',
  //   component: OnboardingInviteFriends,
  //   isProtected: true,
  //   exact: true,
  // },
  // {
  //   path: '/onboarding/create-community',
  //   component: OnboardingGetStartedPage,
  //   isProtected: true,
  //   exact: true,
  // },
  {
    path: '/community-settings',
    component: Settings,
    isProtected: true,
  },
  {
    path: '/channel-settings',
    component: ChannelSettings,
    isProtected: true,
  },
  {
    path: '/manage-account',
    component: ManageAccount,
    isProtected: true,
  },
  {
    path: '/overview-settings',
    component: OverviewSettings,
    isProtected: true,
  },
  {
    path: '/channel-category-settings',
    component: ChannelCategorySettings,
    isProtected: true,
  },
  {
    path: '/group-settings',
    component: GroupSettings,
    isProtected: true,
  },
  {
    path: '/community-switcher',
    component: Switcher,
    isProtected: true,
  },
  {
    path: '/inbox',
    component: Inbox,
    isProtected: true,
    exact: true,
  },
  {
    path: '/guest',
    component: PublicLayout,
    skipCommunityMemberVerification: true,
    isProtected: false,
  },
  {
    path: '/public/event',
    component: CalendarPostDetailsPublic,
    skipCommunityMemberVerification: true,
    isProtected: false,
  },
  {
    path: '/public/internal-server-error',
    component: InternalServerError,
    skipCommunityMemberVerification: true,
    isProtected: true,
  },
  {
    path: '/404',
    component: NoData404,
    isProtected: false,
    skipCommunityMemberVerification: true,
  },
  {
    path: ROUTE_PATH.TEXT_CHANNEL_EMBEDDED,
    component: TextChannelOverview,
    isProtected: true,
    exact: true,
  },
  {
    path: ROUTE_PATH.STREAMING_CHANNEL_VIEW,
    component: StreamChannel,
  },
  {
    path: '/',
    component: MainLayout,
    isProtected: true,
  },
];

export default indexRoutes;
