/* eslint-disable no-case-declarations */
import { downVoteItem, upVoteItem } from '../../services/models/forum/forumModel';
import {
  ADD_FORUM_POST_COMMENT_REPLY_SUCCESS,
  AUTH_LOG_OUT_SUCCESS,
  CREATE_FORUM_POST_COMPLETED,
  CREATE_FORUM_POST_REQUEST,
  DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS,
  DELETE_FORUM_POST_REQUEST,
  DELETE_FORUM_POST_SUCCESS,
  FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE,
  FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS,
  FETCH_FORUM_POST_COMMENT_SET_PAGE,
  FETCH_FORUM_POST_COMMENT_SUCCESS,
  FETCH_FORUM_POST_ERROR,
  FETCH_FORUM_POST_REQUEST,
  FETCH_FORUM_POST_SET_PAGE,
  FETCH_FORUM_POST_SUCCESS,
  FORUM_POSTS_RESET,
  FORUM_POST_COMMENTS_RESET,
  FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS,
  FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS,
  FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS,
  FORUM_POST_COMMENT_UP_VOTE_SUCCESS,
  FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS,
  FORUM_POST_DOWN_VOTE_SUCCESS,
  FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS,
  FORUM_POST_UP_VOTE_SUCCESS,
  MARK_TOP_ANSWER,
  PIN_FORUM_POST_FAILED,
  PIN_FORUM_POST_REQUEST,
  PIN_FORUM_POST_SUCCESS,
  UPDATE_FORUM_POST_COMPLETED,
  UPDATE_FORUM_POST_REQUEST,
} from '../actions/actionTypes';

const initialState = {
  page: null,
  posts: [],
  totalPages: 1,
  comments: [],
  currentForumPost: {},
  commentTotalPages: 1,
  commentPage: null,
  allCommentReplies: {},
  permissions: {},
  postPermissions: {},
  onForumPostLoading: false,
  forumPostCreateOrUpdateLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FORUM_POST_REQUEST:
      return { ...state, forumPostCreateOrUpdateLoading: true };
    case CREATE_FORUM_POST_COMPLETED:
      return { ...state, forumPostCreateOrUpdateLoading: false };

    case UPDATE_FORUM_POST_REQUEST:
      return { ...state, forumPostCreateOrUpdateLoading: true };
    case UPDATE_FORUM_POST_COMPLETED:
      return { ...state, forumPostCreateOrUpdateLoading: false };

    case FETCH_FORUM_POST_REQUEST:
      return {
        ...state,
        onForumPostLoading: true,
      };

    case FETCH_FORUM_POST_SUCCESS:
      return {
        ...state,
        posts: action?.payload?.isResetPost
          ? action?.payload?.posts
          : [...state.posts, ...action?.payload?.posts],
        page: action?.payload?.page,
        totalPages: action?.payload?.totalPages,
        permissions: action?.payload?.permissions,
        onForumPostLoading: false,
      };

    case FETCH_FORUM_POST_ERROR:
      return {
        ...state,
        onForumPostLoading: false,
      };

    case FETCH_FORUM_POST_SET_PAGE:
      return {
        ...state,
        page: action?.payload?.page,
      };

    case FETCH_FORUM_POST_COMMENT_SUCCESS:
      return {
        ...state,
        comments: action?.payload?.isResetPost
          ? action?.payload?.comments
          : [...state.comments, ...action?.payload?.comments],
        commentPage: action?.payload?.page,
        commentTotalPages: action?.payload?.totalPages,
        currentForumPost: action?.payload?.forumPost,
        postPermissions: action?.payload?.permissions,
      };

    case FETCH_FORUM_POST_COMMENT_SET_PAGE:
      return {
        ...state,
        commentPage: action?.payload?.commentPage,
      };

    case FORUM_POST_UP_VOTE_SUCCESS:
      return {
        ...state,
        currentForumPost: upVoteItem(state.currentForumPost),
      };

    case FORUM_POST_DOWN_VOTE_SUCCESS:
      return {
        ...state,
        currentForumPost: downVoteItem(state.currentForumPost),
      };

    case FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS:
      const currentUpPostIndex = state.posts.findIndex((d) => d?.id === action?.payload);
      if (currentUpPostIndex >= 0) {
        const upVotedItem = upVoteItem(state.posts[currentUpPostIndex]);
        state.posts.splice(currentUpPostIndex, 1, upVotedItem);
      }

      return {
        ...state,
        posts: [...state.posts],
      };

    case FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS:
      const currentDownPostIndex = state.posts.findIndex((d) => d?.id === action?.payload);
      if (currentDownPostIndex >= 0) {
        const downVotedItem = downVoteItem(state.posts[currentDownPostIndex]);
        state.posts.splice(currentDownPostIndex, 1, downVotedItem);
      }

      return {
        ...state,
        posts: [...state.posts],
      };

    case FORUM_POSTS_RESET:
      return {
        ...state,
        posts: [],
        page: null,
        totalPages: 1,
      };

    case FORUM_POST_COMMENTS_RESET:
      return {
        ...state,
        comments: [],
        replies: [],
        commentReplyPage: null,
        commentReplyTotalPages: null,
        commentPage: null,
        allCommentReplies: {},
        commentTotalPages: null,
        currentForumPost: null,
        isForumPostDeleted: false,
      };

    case FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS:
      const currentCommentId = action?.payload?.commentId;
      if (currentCommentId) {
        return {
          ...state,
          allCommentReplies: {
            ...state.allCommentReplies,
            [currentCommentId]: {
              replies: action?.payload?.isResetPost
                ? action?.payload?.replies
                : [...(state.allCommentReplies[currentCommentId]?.replies || []), ...action?.payload?.replies],
              commentReplyPage: action?.payload?.page,
              commentReplyTotalPages: action?.payload?.totalPages,
            },
          },
        };
      }

    case FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE:
      const commentId = action?.payload?.commentId;
      const currentCommentReplyPage = state.allCommentReplies[commentId] || {};

      return {
        ...state,
        allCommentReplies: {
          ...state.allCommentReplies,
          [commentId]: {
            ...currentCommentReplyPage,
            commentReplyPage: action?.payload?.commentReplyPage,
          },
        },
      };

    case ADD_FORUM_POST_COMMENT_REPLY_SUCCESS:
      const commentIdFromReply = action?.payload;
      const currentCommentIndex = state.comments.findIndex((d) => d?.id === commentIdFromReply);

      if (currentCommentIndex >= 0) {
        state.comments[currentCommentIndex].replies_count = state.comments[currentCommentIndex].replies_count + 1;
      }

      return {
        ...state,
        comments: [...state.comments],
      };

    case FORUM_POST_COMMENT_UP_VOTE_SUCCESS:
      const commentIdFromCommentUpVote = action?.payload;
      const currentUpVoteCommentIndex = state.comments.findIndex((d) => d?.id === commentIdFromCommentUpVote);

      if (currentUpVoteCommentIndex >= 0) {
        state.comments.splice(currentUpVoteCommentIndex, 1, upVoteItem(state.comments[currentUpVoteCommentIndex]));
      }

      return {
        ...state,
        comments: [...state.comments],
      };

    case FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS:
      const commentIdFromCommentDownVote = action?.payload;
      const currentDownVoteCommentIndex = state.comments.findIndex((d) => d?.id === commentIdFromCommentDownVote);

      if (currentDownVoteCommentIndex >= 0) {
        state.comments.splice(
          currentDownVoteCommentIndex,
          1,
          downVoteItem(state.comments[currentDownVoteCommentIndex]),
        );
      }

      return {
        ...state,
        comments: [...state.comments],
      };

    case FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS:
      const commentIdUpVote = action?.payload?.comment_id;
      const replyIdUpVote = action?.payload?.reply_id;
      const currentCommentFromUpVote = state.allCommentReplies[commentIdUpVote];

      if (currentCommentFromUpVote) {
        const currentRepliesFromUpVote = currentCommentFromUpVote.replies;
        const currentReplyFromUpVoteIndex = currentRepliesFromUpVote.findIndex((d) => d?.id === replyIdUpVote);
        currentRepliesFromUpVote.splice(
          currentReplyFromUpVoteIndex,
          1,
          upVoteItem(currentRepliesFromUpVote[currentReplyFromUpVoteIndex]),
        );

        return {
          ...state,
          allCommentReplies: {
            ...state.allCommentReplies,
            [commentIdUpVote]: {
              ...currentCommentFromUpVote,
              replies: [...currentRepliesFromUpVote],
            },
          },
        };
      }

    case FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS:
      const commentIdDownVote = action?.payload?.comment_id;
      const replyIdDownVote = action?.payload?.reply_id;
      const currentCommentFromDownVote = state.allCommentReplies[commentIdDownVote];

      if (currentCommentFromDownVote) {
        const currentRepliesFromDownVote = currentCommentFromDownVote.replies;
        const currentReplyFromDownVoteIndex = currentRepliesFromDownVote.findIndex((d) => d?.id === replyIdDownVote);
        currentRepliesFromDownVote.splice(
          currentReplyFromDownVoteIndex,
          1,
          downVoteItem(currentRepliesFromDownVote[currentReplyFromDownVoteIndex]),
        );

        return {
          ...state,
          allCommentReplies: {
            ...state.allCommentReplies,
            [commentIdDownVote]: {
              ...currentCommentFromDownVote,
              replies: [...currentRepliesFromDownVote],
            },
          },
        };
      }

    case DELETE_FORUM_POST_REQUEST:
      return { ...state, isForumPostDeleted: false };

    case DELETE_FORUM_POST_SUCCESS:
      return { ...state, isForumPostDeleted: true };

    case AUTH_LOG_OUT_SUCCESS:
      return initialState;

    case DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS:
      const commentIdFromDeleteReply = action?.payload;
      const currentCommentFromDeleteIndex = state.comments.findIndex((d) => d?.id === commentIdFromDeleteReply);

      if (currentCommentFromDeleteIndex >= 0) {
        state.comments[currentCommentFromDeleteIndex].replies_count = state.comments[currentCommentFromDeleteIndex].replies_count - 1;
      }

      return {
        ...state,
        comments: [...state.comments],
      };

    case PIN_FORUM_POST_REQUEST:
      return { ...state, pinningPost: true };
    case PIN_FORUM_POST_SUCCESS:
      return { ...state, pinningPost: false };
    case PIN_FORUM_POST_FAILED:
      return { ...state, pinningPost: false };
    case MARK_TOP_ANSWER:
      const commentTopAnswerId = action?.payload?.id;
      const newCommentArray = [...state?.comments?.filter(val => val?.id == commentTopAnswerId), ...state?.comments?.filter(val => val?.id != commentTopAnswerId)]
      newCommentArray[0].top_answer = action?.payload?.top_answer
      if (!action?.payload?.top_answer) {
        newCommentArray.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
      }
      return {
        ...state,
        comments: [...newCommentArray],
      };
    default:
      return state;
  }
};

export default reducer;
