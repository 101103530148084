import { all, fork, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { FORUM_REQUEST_ORIGINS } from '../constants';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { parseAxiosErrorMessage } from '../constants/utils';
import {
  addForumPostCommentReplyService,
  addForumPostCommentService,
  fetchForumPostCommentRepliesService,
  fetchForumPostCommentService,
  fetchForumPostService,
  forumCommentDownVoteService,
  forumCommentUpVoteService,
  forumDownVoteService,
  forumUpVoteService,
  forumReplyUpVoteService,
  forumReplyDownVoteService,
  createForumPostService,
  updateForumPostService,
  updateForumPostCommentService,
  updateForumPostCommentReplyService,
  forumBookMarkPostService,
  deleteForumPostService,
  deleteForumPostCommentService,
  deleteForumPostCommentReplyService,
  pinForumPostService,
  markTopAnswer,
} from '../services/forumService';
import { postCommentDataModel, postCommentReplyDataModel, postDataModel } from '../services/models/forum/forumModel';
import NotificationService from '../services/notificationService';
import {
  ADD_FORUM_POST_COMMENT_REPLY_REQUEST,
  ADD_FORUM_POST_COMMENT_REPLY_SUCCESS,
  ADD_FORUM_POST_COMMENT_REQUEST,
  CREATE_FORUM_POST_COMPLETED,
  CREATE_FORUM_POST_REQUEST,
  DELETE_FORUM_POST_COMMENT_REPLY_REQUEST,
  DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS,
  DELETE_FORUM_POST_COMMENT_REQUEST,
  DELETE_FORUM_POST_REQUEST,
  DELETE_FORUM_POST_SUCCESS,
  FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST,
  FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS,
  FETCH_FORUM_POST_COMMENT_REQUEST,
  FETCH_FORUM_POST_COMMENT_SUCCESS,
  FETCH_FORUM_POST_ERROR,
  FETCH_FORUM_POST_REQUEST,
  FETCH_FORUM_POST_SUCCESS,
  FORUM_BOOKMARK_POST_REQUEST,
  FORUM_POST_COMMENT_DOWN_VOTE_REQUEST,
  FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS,
  FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST,
  FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS,
  FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST,
  FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS,
  FORUM_POST_COMMENT_UP_VOTE_REQUEST,
  FORUM_POST_COMMENT_UP_VOTE_SUCCESS,
  FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST,
  FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS,
  FORUM_POST_DOWN_VOTE_REQUEST,
  FORUM_POST_DOWN_VOTE_SUCCESS,
  FORUM_POST_UP_VOTE_FROM_LIST_REQUEST,
  FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS,
  FORUM_POST_UP_VOTE_REQUEST,
  FORUM_POST_UP_VOTE_SUCCESS,
  PIN_FORUM_POST_FAILED,
  PIN_FORUM_POST_REQUEST,
  PIN_FORUM_POST_SUCCESS,
  SET_USER_BOOKMARKS_COUNT,
  UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST,
  UPDATE_FORUM_POST_COMMENT_REQUEST,
  UPDATE_FORUM_POST_COMPLETED,
  UPDATE_FORUM_POST_REQUEST,
  UPDATE_FORUM_POST_SUCCESS,
  MARK_TOP_ANSWER,
  MARK_TOP_ANSWER_REQUEST
} from '../store/actions/actionTypes';
import { fetchCommunityFeed } from '../store/actions/communityActions';
import { fetchForumPost, fetchForumPostComment, fetchForumPostCommentReplies } from '../store/actions/forumActions';

function* createForumPostSaga() {
  yield takeLatest(CREATE_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      const response = yield createForumPostService(payload);

      if (payload?.history) {
        payload?.history?.push(
          `/communities/${payload.community_id}/group/${payload.group_id}/channels/${payload.channel_id}/forumposts/${response?.data?.id}`,
        );
      }

      yield put({
        type: CREATE_FORUM_POST_COMPLETED,
      });

      NotificationService.success(SUCCESS_MESSAGES.POST_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: CREATE_FORUM_POST_COMPLETED,
      });
    }
  });
}

function* updateForumPostSaga() {
  yield takeLatest(UPDATE_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      const routeData = {
        community_id: payload.community_id,
        channel_id: payload.channel_id,
        group_id: payload.group_id,
        post_id: payload.post_id,
      };

      const data = {
        forum_post: {
          description: payload.description,
          image_url: payload.image_url,
          post_tags: payload.post_tags
        },
      };

      if (payload?.videoChange === "added") {
        data.video_upload_ids = payload.video_upload_ids;
      }

      if (payload?.videoChange === "removed") {
        data.video_upload_ids = [];
      }

      yield updateForumPostService(routeData, data);

      switch (payload.originType) {
        case FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE:
          payload?.history?.push(
            `/communities/${payload.community_id}/group/${payload.group_id}/channels/${payload.channel_id}/forumposts`,
          );
          break;

        case FORUM_REQUEST_ORIGINS.FORUM_DETAILS_PAGE:
          payload?.history?.push(
            `/communities/${payload.community_id}/group/${payload.group_id}/channels/${payload.channel_id}/forumposts/${payload.post_id}`,
          );
          break;

        case FORUM_REQUEST_ORIGINS.COMMUNITY_FEED_PAGE:
          payload?.history?.push('/');
          break;

        default:
          break;
      }

      yield put({
        type: UPDATE_FORUM_POST_COMPLETED,
      });

      NotificationService.success(SUCCESS_MESSAGES.POST_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: UPDATE_FORUM_POST_COMPLETED,
      });
    }
  });
}

function* fetchForumPostsSaga() {
  yield takeLatest(FETCH_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchForumPostService(payload);
      yield put({
        type: FETCH_FORUM_POST_SUCCESS,
        payload: { ...postDataModel(response), isResetPost: payload.isResetPost },
      });
    } catch (error) {
      yield put({
        type: FETCH_FORUM_POST_ERROR,
      });

      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchForumPostCommentSaga() {
  yield takeLatest(FETCH_FORUM_POST_COMMENT_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchForumPostCommentService(payload);
      yield put({
        type: FETCH_FORUM_POST_COMMENT_SUCCESS,
        payload: { ...postCommentDataModel(response), isResetPost: payload.isResetPost },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* addForumPostCommentSaga() {
  yield takeLatest(ADD_FORUM_POST_COMMENT_REQUEST, function* ({ payload }) {
    try {
      yield addForumPostCommentService(payload);

      yield put(
        fetchForumPostComment(payload.community_id, payload.channel_id, payload.post_id, 1, payload.group_id, true),
      );

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateForumPostCommentSaga() {
  yield takeLatest(UPDATE_FORUM_POST_COMMENT_REQUEST, function* ({ payload }) {
    try {
      yield updateForumPostCommentService(payload);
      yield put(
        fetchForumPostComment(payload.community_id, payload.channel_id, payload.post_id, 1, payload.group_id, true),
      );

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumPostUpVoteSaga() {
  yield takeLatest(FORUM_POST_UP_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumUpVoteService(payload);
      yield put({
        type: FORUM_POST_UP_VOTE_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumPostDownVoteSaga() {
  yield takeLatest(FORUM_POST_DOWN_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumDownVoteService(payload);
      yield put({
        type: FORUM_POST_DOWN_VOTE_SUCCESS,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumPostUpVoteFromListSaga() {
  yield takeLatest(FORUM_POST_UP_VOTE_FROM_LIST_REQUEST, function* ({ payload }) {
    try {
      yield forumUpVoteService(payload);
      yield put({
        type: FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS,
        payload: payload.post_id,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumPostDownVoteFromSaga() {
  yield takeLatest(FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST, function* ({ payload }) {
    try {
      yield forumDownVoteService(payload);
      yield put({
        type: FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS,
        payload: payload.post_id,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchForumPostCommentRepliesSaga() {
  yield takeEvery(FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchForumPostCommentRepliesService(payload);
      yield put({
        type: FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS,
        payload: {
          ...postCommentReplyDataModel(response),
          isResetPost: payload.isResetPost,
          commentId: payload.comment_id,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* addForumPostCommentReplySaga() {
  yield takeLatest(ADD_FORUM_POST_COMMENT_REPLY_REQUEST, function* ({ payload }) {
    try {
      yield addForumPostCommentReplyService(payload);

      yield put(
        fetchForumPostCommentReplies(
          payload.community_id,
          payload.channel_id,
          payload.post_id,
          payload.comment_id,
          1,
          payload.group_id,
          true,
          payload.mentionUserIds,
          payload.image_url,
        ),
      );

      yield put({
        type: ADD_FORUM_POST_COMMENT_REPLY_SUCCESS,
        payload: payload.comment_id,
      });

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_REPLY_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateForumPostCommentReplySaga() {
  yield takeLatest(UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST, function* ({ payload }) {
    try {
      yield updateForumPostCommentReplyService(payload);

      yield put(
        fetchForumPostCommentReplies(
          payload.community_id,
          payload.channel_id,
          payload.post_id,
          payload.comment_id,
          1,
          payload.group_id,
          true,
          payload.mentionUserIds,
          payload.image_url,
        ),
      );

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_REPLY_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumCommentUpVoteSaga() {
  yield takeLatest(FORUM_POST_COMMENT_UP_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumCommentUpVoteService(payload);
      yield put({
        type: FORUM_POST_COMMENT_UP_VOTE_SUCCESS,
        payload: payload.comment_id,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumCommentDownVoteSaga() {
  yield takeLatest(FORUM_POST_COMMENT_DOWN_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumCommentDownVoteService(payload);
      yield put({
        type: FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS,
        payload: payload.comment_id,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumCommentReplyUpVoteSaga() {
  yield takeLatest(FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumReplyUpVoteService(payload);
      yield put({
        type: FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS,
        payload: { comment_id: payload.comment_id, reply_id: payload.reply_id },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumCommentReplyDownVoteSaga() {
  yield takeLatest(FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST, function* ({ payload }) {
    try {
      yield forumReplyDownVoteService(payload);
      yield put({
        type: FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS,
        payload: { comment_id: payload.comment_id, reply_id: payload.reply_id },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* forumBookMarkPostSaga() {
  yield takeLatest(FORUM_BOOKMARK_POST_REQUEST, function* ({ payload }) {
    try {
      const data = yield forumBookMarkPostService(payload);

      if (data?.data?.id) {
        yield put({
          type: SET_USER_BOOKMARKS_COUNT,
          payload: { count: data?.data?.attributes?.current_user_bookmarks_count },
        });
      } else {
        yield put({
          type: SET_USER_BOOKMARKS_COUNT,
          payload: { count: data?.bookmarks_count },
        });
      }

      switch (payload.originType) {
        case FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE:
          yield put(fetchForumPost(payload.community_id, payload.channel_id, 1, payload.group_id, true));
          break;

        case FORUM_REQUEST_ORIGINS.FORUM_DETAILS_PAGE:
          yield put(
            fetchForumPostComment(payload.community_id, payload.channel_id, payload.post_id, 1, payload.group_id, true),
          );
          break;

        case FORUM_REQUEST_ORIGINS.COMMUNITY_FEED_PAGE:
          yield put(
            fetchCommunityFeed({ id: payload.community_id, page: 1, isReset: true, groupId: payload.group_id }),
          );
          break;

        default:
          break;
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* deleteForumPostSaga() {
  yield takeLatest(DELETE_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      yield deleteForumPostService(payload);
      switch (payload.originType) {
        case FORUM_REQUEST_ORIGINS.FORUM_LIST_PAGE:
          yield put(fetchForumPost(payload.community_id, payload.channel_id, 1, payload.group_id, true));
          break;

        case FORUM_REQUEST_ORIGINS.FORUM_DETAILS_PAGE:
          yield put({
            type: DELETE_FORUM_POST_SUCCESS,
          });
          break;

        case FORUM_REQUEST_ORIGINS.COMMUNITY_FEED_PAGE:
          yield put(
            fetchCommunityFeed({ id: payload.community_id, page: 1, isReset: true, groupId: payload.group_id }),
          );
          break;

        default:
          break;
      }

      NotificationService.success(SUCCESS_MESSAGES.POST_DELETE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* deleteForumCommentSaga() {
  yield takeLatest(DELETE_FORUM_POST_COMMENT_REQUEST, function* ({ payload }) {
    try {
      yield deleteForumPostCommentService(payload);
      yield put(
        fetchForumPostComment(payload.community_id, payload.channel_id, payload.post_id, 1, payload.group_id, true),
      );

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_DELETE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* deleteForumPostCommentReplySaga() {
  yield takeLatest(DELETE_FORUM_POST_COMMENT_REPLY_REQUEST, function* ({ payload }) {
    try {
      yield deleteForumPostCommentReplyService(payload);

      yield put(
        fetchForumPostCommentReplies(
          payload.community_id,
          payload.channel_id,
          payload.post_id,
          payload.comment_id,
          1,
          payload.group_id,
          true,
        ),
      );

      yield put({
        type: DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS,
        payload: payload.comment_id,
      });

      NotificationService.success(SUCCESS_MESSAGES.COMMENT_REPLY_DELETE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* pinForumPostSaga() {
  yield takeLatest(PIN_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      yield pinForumPostService(payload);
      yield put({
        type: PIN_FORUM_POST_SUCCESS,
      });

      yield put(fetchForumPost(payload.community_id, payload.channel_id, 1, payload.group_id, true));
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: PIN_FORUM_POST_FAILED,
      });
    }
  });
}

function* forumPostMarkAnswerSaga() {
  yield takeLatest(MARK_TOP_ANSWER_REQUEST, function* ({ payload }) {
    try {
      const { community_id, group_id, channel_id, post_id, comment_id, flag } = payload
      const flagState = flag ? false : true
      const response = yield markTopAnswer(community_id, group_id, channel_id, post_id, comment_id, flagState);
      yield put({
        type: MARK_TOP_ANSWER,
        payload: { id: response?.data?.id, top_answer: response?.data?.attributes?.top_answer }
      });
      NotificationService.success("Top Answer Marked Successfully")
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(createForumPostSaga),
    fork(fetchForumPostsSaga),
    fork(fetchForumPostCommentSaga),
    fork(addForumPostCommentSaga),
    fork(forumPostUpVoteSaga),
    fork(forumPostDownVoteSaga),
    fork(forumPostUpVoteFromListSaga),
    fork(forumPostDownVoteFromSaga),
    fork(fetchForumPostCommentRepliesSaga),
    fork(addForumPostCommentReplySaga),
    fork(forumCommentUpVoteSaga),
    fork(forumCommentDownVoteSaga),
    fork(forumCommentReplyUpVoteSaga),
    fork(forumCommentReplyDownVoteSaga),
    fork(updateForumPostSaga),
    fork(updateForumPostCommentSaga),
    fork(updateForumPostCommentReplySaga),
    fork(forumBookMarkPostSaga),
    fork(deleteForumPostSaga),
    fork(deleteForumCommentSaga),
    fork(deleteForumPostCommentReplySaga),
    fork(pinForumPostSaga),
    fork(forumPostMarkAnswerSaga)
  ]);
}
