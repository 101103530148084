import {
  AUTH_LOG_OUT_SUCCESS,
  CREATE_CHANNEL_CATEGORY_REQUEST,
  CREATE_CHANNEL_CATEGORY_SUCCESS,
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  CREATE_FORUM_POST_REQUEST,
  CREATE_FORUM_POST_SUCCESS,
  DELETE_ACTIVE_CHANNEL,
  FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS,
  SET_ACTIVE_CHANNEL,
  SET_ACTIVE_CHANNEL_ID,
  SET_ACTIVE_CHANNEL_TYPE,
  SET_CURRENT_CHANNELS,
  UPDATE_CHANNEL_CATEGORY_REQUEST,
  UPDATE_CHANNEL_CATEGORY_SUCCESS,
  UPDATE_CHANNEL_COMMUNITY,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  UPDATE_COIN_COUNT,
  UPDATE_CHANNEL_LIST
} from '../actions/actionTypes';
import { channelCategoryModel } from '../models/community';

const initialState = {
  channels: [],
  configs: {},
  activeChannelData: {},
  // communityOwner: null,
  // communityMember: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action?.payload?.data?.group_categories.map((el) => {
          if (el) {
            el.isCollapsed = true;
          }
          return el;
        }),
        configs: channelCategoryModel(action?.payload?.data),
        communityOwner: action?.payload?.data?.community_owner,
        // communityMember: action?.payload?.data?.community_member,
        coinsCount: action?.payload?.data?.coins_count || 0,
      };

    case SET_ACTIVE_CHANNEL:
      return { ...state, activeChannelData: action?.payload?.channelData };

    case SET_CURRENT_CHANNELS:
      return {
        ...state,
        channels: action?.payload,
      };

    case SET_ACTIVE_CHANNEL_ID:
      return { ...state, activeChannelData: { ...state.activeChannelData, id: action?.payload?.channelID } };
    case SET_ACTIVE_CHANNEL_TYPE:
      return { ...state, activeChannelData: { ...state.activeChannelData, channel_type: action?.payload?.type } };
    case DELETE_ACTIVE_CHANNEL:
      return { ...state, activeChannelData: {} };

    case CREATE_CHANNEL_REQUEST:
      return { ...state, channelCreated: false };
    case CREATE_CHANNEL_SUCCESS:
      return { ...state, channelCreated: true };

    case UPDATE_CHANNEL_REQUEST:
      return { ...state, channelUpdated: false };
    case UPDATE_CHANNEL_SUCCESS:
      return { ...state, channelUpdated: true };

    case CREATE_CHANNEL_CATEGORY_REQUEST:
      return { ...state, channelCategoryCreated: false };
    case CREATE_CHANNEL_CATEGORY_SUCCESS:
      const newCategoryObj = { ...action?.payload, channels: [] };

      return {
        ...state,
        channels: [...state?.channels, newCategoryObj].map((el) => {
          if (el) {
            el.isCollapsed = true;
          }
          return el;
        }),
        channelCategoryCreated: true,
      };

    case UPDATE_CHANNEL_CATEGORY_REQUEST:
      return { ...state, channelCategoryUpdated: false };
    case UPDATE_CHANNEL_CATEGORY_SUCCESS:
      return { ...state, channelCategoryUpdated: true };

    case UPDATE_CHANNEL_COMMUNITY:
      return {
        ...state,
        config: { ...state.config, community: { ...(state?.config?.community || {}), ...(action.payload || {}) } },
      };

    case UPDATE_COIN_COUNT:
      if (action?.payload !== null && action?.payload !== undefined) {
        return { ...state, coinsCount: action?.payload };
      }
      return state;

    case UPDATE_CHANNEL_LIST:
      return {
        ...state,
        channels: action?.payload,
      };

    case AUTH_LOG_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
