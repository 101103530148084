import server from '../api/server';

export const userForumBookmarks = (data) => server.get(`/users/bookmarks/forums`, data);

export const userResourceBookmarks = (data) => server.get(`/users/bookmarks/resources`, data);

export const userBookmarkForumUpVoteService = async ({ community_id, channel_id, post_id, group_id }) => {
  return await new Promise((resolve, reject) => {
    server
      .put(`/communities/${community_id}/groups/${group_id}/channels/${channel_id}/forum_posts/${post_id}/upvote`, {
        forum_post_comment: {
          vote_flag: true,
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};

export const userBookmarkForumDownVoteService = async ({ community_id, channel_id, post_id, group_id }) => {
  return await new Promise((resolve, reject) => {
    server
      .put(`/communities/${community_id}/groups/${group_id}/channels/${channel_id}/forum_posts/${post_id}/downvote`, {
        forum_post_comment: {
          vote_flag: true,
        },
      })
      .then((response) => {
        const data = response.data;
        resolve(data);
      })
      .catch(reject);
  });
};
