import { createBrowserHistory } from 'history';
import { isPlainObject } from 'lodash';
import { store } from '../store/configureStore';
import { useLocation } from 'react-router-dom';
import React from 'react';

export const history = createBrowserHistory();

export const copyToClipboard = (containerId) => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }

  if (document.selection) {
    const range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerId));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(document.getElementById(containerId));
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }
};

export const parseAxiosErrorMessage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (errorObject.message) {
      message = errorObject.message;
    } else if (errorObject.data && typeof errorObject.data === 'string') {
      message = errorObject.data;
    } else if (errorObject.error && typeof errorObject.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (isPlainObject(errorObject?.errors)) {
      Object.keys(errorObject?.errors).forEach((key) => {
        message = `${key} ${errorObject?.errors?.[key]?.[0]}`;
      });
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    } else if (errorObject?.reason) {
      message = errorObject.reason;
    } else if (errorObject?.errors?.length) {
      message = errorObject.errors;
    } else if (errorObject?.[0]?.length) {
      message = errorObject?.[0];
    }
  }
  return message;
};

export const parseAxiosErrorMessageStage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (errorObject?.message) {
      message = errorObject?.message;
    } else if (Array.isArray(errorObject) && typeof errorObject[0] === 'string') {
      message = errorObject[0];
    } else if (errorObject?.errors && typeof errorObject?.errors === 'string') {
      message = errorObject?.errors;
    } else if (errorObject?.data && typeof errorObject?.data === 'string') {
      message = errorObject?.data;
    } else if (errorObject?.error && typeof errorObject?.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    }
  }
  return message;
};

export const parseAxiosErrorMessageRole = (error) => {
  if (error?.response?.data?.errors?.role?.length) {
    return `Role name ${error.response.data.errors.role[0]}`;
  }
  return '';
};

export const numberDiff = (num1, num2) => Number(num1) - Number(num2);

export const getButtonCSS = (accentColor) => ({
  backgroundColor: accentColor,
  // borderColor: accentColor,
  // color: '#ffffff',
});

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + (string?.slice(1)?.toLowerCase() || '');
  }
  return '';
};

// REF: https://github.com/component/humanize-number/blob/master/index.js
export const humanizeNumber = (n, options) => {
  if (!n) {
    return;
  }
  options = options || {};
  const d = options.delimiter || ',';
  const s = options.separator || '.';
  n = n.toString().split('.');
  n[0] = n[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${d}`);
  return n.join(s);
};

export const prepareUserImageName = (first_name, last_name) => {
  if (!first_name && !last_name) {
    return '';
  }
  return `${first_name?.charAt(0)?.toUpperCase() || ''}${last_name?.charAt(0)?.toUpperCase() || ''}`;
};

export const redirectToLoginDomain = (redirectUrl = '') => {
  history.replace('/signin');
  // let redirect_url = window.location.href;
  // if (redirectUrl) {
  //   redirect_url = redirectUrl;
  // }
  // window.location.href = `${process.env.REACT_APP_LOGIN_CLIENT}/login?request_host=${window.location.host}&redirect_url=${redirect_url}`;
};

export const swapArrayElements = (swapFromIndex, swapToIndex, arr = []) => {
  if (!arr?.length) return arr;
  const newArr = [...arr];
  [newArr[swapFromIndex], newArr[swapToIndex]] = [newArr[swapToIndex], newArr[swapFromIndex]];
  return newArr;
};

export const getCurrentTimeZone = (time = new Date()) => {
  const date = new Date(time).toTimeString();
  const timeZoneFull = date?.split('(')[1]?.split(')')[0];
  if (timeZoneFull?.toUpperCase() === timeZoneFull) {
    return timeZoneFull;
  }
  const timeZoneArr = timeZoneFull?.split(' ');
  let timeZone = '';
  timeZoneArr?.forEach((item) => {
    timeZone += item[0];
  });
  return timeZone;
};

export const randomString = (length = 8, chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
};

export const isPastDate = (date) => new Date(date).setHours(0, 0, 0, 0) <= new Date('10/11/2022').setHours(0, 0, 0, 0);

export const getGreetings = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();

  let greet = $translatei18n('GoodMorning');

  if (hrs < 12) greet = $translatei18n('GoodMorning');
  else if (hrs >= 12 && hrs <= 17) greet = $translatei18n('GoodAfternoon');
  else if (hrs >= 17 && hrs <= 24) greet = $translatei18n('GoodEvening');

  return greet;
};

export function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
  }
