import { I18n } from 'i18n-js';
import en from './translations/en.json';
import he from './translations/he.json';

const i18n = new I18n({
  ...en,
  ...he,
});

i18n.enableFallback = true;
i18n.missingBehavior = 'guess';
i18n.missingTranslationPrefix = '';
i18n.defaultLocale = localStorage.getItem('currentLocal') || 'en';
i18n.locale = localStorage.getItem('currentLocal') || 'en'; // uncomment this line to set hebrew lang

i18n.translations = {
  en,
  he,
};

export const getCurrentLocale = () => i18n.locale;

export const setLocale = (locale) => {
  i18n.locale = locale;
};

export default i18n.translate.bind(i18n);
