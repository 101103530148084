import mixpanel from 'mixpanel-browser';
class AnalyticsService {
  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN); // Your Mixpanel Project Token
    if (window.location.hostname === "unext.scenes.social") {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_UNEXT_TOKEN, {}, "unext_internal");
    }
    if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com"  ) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_SHARAN, {}, "sharan_internal");
    }
    if (window.location.hostname === "epicrew.scenes.social") {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_WARIKOO, {}, "warikoo_internal");
    }
    if (window.location.hostname === "app.peerplays.zone" ) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_PEERPLAYS, {}, "peerplays_internal");
    }
  }


  static setMixpanelIdentify = (userId) => {
    if (userId && mixpanel) {
      mixpanel.identify(userId.toString());
      if (window.location.hostname === "unext.scenes.social") {
        mixpanel.unext_internal.identify(userId.toString());
      }
      if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
        mixpanel.sharan_internal.identify(userId.toString());
      }
      if (window.location.hostname === "epicrew.scenes.social") {
        mixpanel.warikoo_internal.identify(userId.toString());
      }
      if (window.location.hostname === "app.peerplays.zone" ) {
        mixpanel.peerplays_internal.identify(userId.toString());
      }
    }
  };

  static logMixpanelEvent = (eventName = '', params = {}) => {
    if (mixpanel) {
      mixpanel.track(eventName, {
        ...params,
        Source: 'Browser',
      });
      if (window.location.hostname === "unext.scenes.social") {
        mixpanel.unext_internal.track(eventName, {
          ...params,
          Source: 'Browser',
        });
      }
      if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
        mixpanel.sharan_internal.track(eventName, {
          ...params,
          Source: 'Browser',
        });
      }
      if (window.location.hostname === "epicrew.scenes.social") {
        mixpanel.warikoo_internal.track(eventName, {
          ...params,
          Source: 'Browser',
        });
      }
      if (window.location.hostname === "app.peerplays.zone" ) {
        mixpanel.peerplays_internal.track(eventName, {
          ...params,
          Source: 'Browser',
        });
      }
    }
  };

  static logMixpanelTimingEvent = (eventName = '') => {
    if (mixpanel) {
      mixpanel.time_event(eventName);
      if (window.location.hostname === "unext.scenes.social") {
        mixpanel.unext_internal.time_event(eventName);
      }
      if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
        mixpanel.sharan_internal.time_event(eventName);
      }
      if (window.location.hostname === "epicrew.scenes.social") {
        mixpanel.warikoo_internal.time_event(eventName);
      }
      if (window.location.hostname === "app.peerplays.zone" ) {
        mixpanel.peerplays_internal.time_event(eventName);
      }
    }
  };

  static setUserAttributes = (data) => {
    if (data.first_name) {
      if (mixpanel) {
        mixpanel.people.set('$first_name', data.first_name);
        mixpanel.people.set('$name', `${data.name || data.first_name}}`);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('$first_name', data.first_name);
          mixpanel.unext_internal.people.set('$name', `${data.name || data.first_name}}`);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('$first_name', data.first_name);
          mixpanel.sharan_internal.people.set('$name', `${data.name || data.first_name}}`);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('$first_name', data.first_name);
          mixpanel.warikoo_internal.people.set('$name', `${data.name || data.first_name}}`);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('$first_name', data.first_name);
          mixpanel.peerplays_internal.people.set('$name', `${data.name || data.first_name}}`);
        }
      }
    }

    if (data.last_name) {
      if (mixpanel) {
        mixpanel.people.set('$last_name', data.last_name);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('$last_name', data.last_name);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('$last_name', data.last_name);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('$last_name', data.last_name);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('$last_name', data.last_name);
        }
      }
    }

    if (data.email) {
      if (mixpanel) {
        mixpanel.people.set('$email', data.email);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('$email', data.email);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('$email', data.email);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('$email', data.email);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('$email', data.email);
        }
      }
    }

    if (data.phone_number) {
      if (mixpanel) {
        mixpanel.people.set('$phone', data.phone_number);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('$phone', data.phone_number);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('$phone', data.phone_number);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('$phone', data.phone_number);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('$phone', data.phone_number);
        }
      }
    }

    if (data.gender) {
      if (mixpanel) {
        mixpanel.people.set('Gender', data.gender);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('Gender', data.gender);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('Gender', data.gender);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('Gender', data.gender);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('Gender', data.gender);
        }
      }
    }

    if (data.dob) {
      if (mixpanel) {
        mixpanel.people.set('DOB', data.dob);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('DOB', data.dob);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('DOB', data.dob);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('DOB', data.dob);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('DOB', data.dob);
        }
      }
    }

    if (data.age) {
      if (mixpanel) {
        mixpanel.people.set('Age', data.age);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('Age', data.age);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('Age', data.age);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('Age', data.age);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('Age', data.age);
        }
      }
    }

    if (data.personal_referral_code) {
      if (mixpanel) {
        mixpanel.people.set('Personal Referral Code', data.personal_referral_code);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('Personal Referral Code', data.personal_referral_code);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('Personal Referral Code', data.personal_referral_code);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('Personal Referral Code', data.personal_referral_code);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('Personal Referral Code', data.personal_referral_code);
        }
      }
    }

    if (data.invited_via_referral_code) {
      if (mixpanel) {
        mixpanel.people.set('Invited Via Referral Code', data.invited_via_referral_code);
        if (window.location.hostname === "unext.scenes.social") {
          mixpanel.unext_internal.people.set('Invited Via Referral Code', data.invited_via_referral_code);
        }
        if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
          mixpanel.sharan_internal.people.set('Invited Via Referral Code', data.invited_via_referral_code);
        }
        if (window.location.hostname === "epicrew.scenes.social") {
          mixpanel.warikoo_internal.people.set('Invited Via Referral Code', data.invited_via_referral_code);
        }
        if (window.location.hostname === "app.peerplays.zone" ) {
          mixpanel.peerplays_internal.people.set('Invited Via Referral Code', data.invited_via_referral_code);
        }
      }
    }
  };

  static clearUser = () => {
    if (mixpanel) {
      mixpanel.reset();
      if (window.location.hostname === "unext.scenes.social") {
        mixpanel.unext_internal.reset();
      }
      if (window.location.hostname === "financewithsharan.scenes.social" || window.location.hostname === "community.financewsharan.com" ) {
        mixpanel.sharan_internal.reset();
      }
      if (window.location.hostname === "epicrew.scenes.social") {
        mixpanel.warikoo_internal.reset();
      }
      if (window.location.hostname === "app.peerplays.zone" ) {
        mixpanel.peerplays_internal.reset();
      }
    }
  };
}

export { AnalyticsService as default };
