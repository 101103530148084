import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  GET_CUSTOM_SSO_LIST,
  CREATE_CUSTOM_SSO_REQUEST,
  UPDATE_CUSTOM_SSO_REQUEST,
  DELETE_CUSTOM_SSO_REQUEST,
  SET_CUSTOM_SSO_LIST,
  CREATE_CUSTOM_SSO_SUCCESS,
  CREATE_CUSTOM_SSO_FAILED,
  UPDATE_CUSTOM_SSO_SUCCESS,
  DELETE_CUSTOM_SSO_SUCCESS,
  UPDATE_CUSTOM_SSO_FAILED,
  DELETE_CUSTOM_SSO_FAILED,
  UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
  REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
} from '../store/actions/actionTypes';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { createCustomSso, deleteCustomSso, fetchCustomSsoList, updateCustomSso } from '../services/ssoService';

function* getCustomSsoListSaga() {
  yield takeLatest(GET_CUSTOM_SSO_LIST, function* () {
    try {
      const ssoList = yield fetchCustomSsoList();
      yield put({
        type: SET_CUSTOM_SSO_LIST,
        payload: ssoList?.data,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createCustomSsoSaga() {
  yield takeLatest(CREATE_CUSTOM_SSO_REQUEST, function* ({ payload }) {
    try {
      const newSsoData = yield createCustomSso(payload);
      yield put({
        type: CREATE_CUSTOM_SSO_SUCCESS,
        payload: newSsoData?.data,
      });
      if (newSsoData?.data?.enable) {
        yield put({
          type: UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
          payload: newSsoData?.data,
        });
      }
      NotificationService.success(SUCCESS_MESSAGES.CREATE_CUSTOM_SSO_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: CREATE_CUSTOM_SSO_FAILED,
      });
    }
  });
}

function* updateCustomSsoSaga() {
  yield takeLatest(UPDATE_CUSTOM_SSO_REQUEST, function* ({ payload }) {
    try {
      const updatedSsoData = yield updateCustomSso(payload.requestBody, payload.id);
      yield put({
        type: UPDATE_CUSTOM_SSO_SUCCESS,
        payload: updatedSsoData?.data,
      });
      if (updatedSsoData?.data?.enable) {
        yield put({
          type: UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
          payload: updatedSsoData?.data,
        });
      } else {
        yield put({
          type: REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
        });
      }
      NotificationService.success(SUCCESS_MESSAGES.UPDATE_CUSTOM_SSO_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: UPDATE_CUSTOM_SSO_FAILED,
      });
    }
  });
}

function* deleteCustomSsoSaga() {
  yield takeLatest(DELETE_CUSTOM_SSO_REQUEST, function* ({ id }) {
    try {
      const response = yield deleteCustomSso(id);
      yield put({
        type: DELETE_CUSTOM_SSO_SUCCESS,
        id,
      });
      yield put({
        type: REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
      });
      NotificationService.success(SUCCESS_MESSAGES.DELETE_CUSTOM_SSO_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: DELETE_CUSTOM_SSO_FAILED,
      });
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(getCustomSsoListSaga),
    fork(createCustomSsoSaga),
    fork(updateCustomSsoSaga),
    fork(deleteCustomSsoSaga),
  ]);
}
