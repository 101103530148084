import { ADD_RESOURCE_PERMISSIONS } from '../actions/actionTypes';
import { getResourcePermissionConfig } from '../models/community';

const initialState = {
  channelPermissionsConfig: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESOURCE_PERMISSIONS:
      return {
        ...state,
        channelPermissionsConfig: getResourcePermissionConfig(action.channelPermissions),
      };

    default:
      return state;
  }
};

export default reducer;
