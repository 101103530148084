export const getForumPermissionsConfig = (permissions) => {
  const permissionsConfig = {};
  permissions.forEach((permission) => {
    permissionsConfig[permission] = true;
  });

  const config = {
    canReadPosts: !!permissionsConfig.READ_POSTS,
    canCreatePosts: !!permissionsConfig.CREATE_POSTS,
    canCommentOnPosts: !!permissionsConfig.COMMENT_POSTS,
    canManagePosts: !!permissionsConfig.MANAGE_POSTS,
  };

  return config;
};

export const getPostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.attributes?.forum_post_id] = item?.attributes;
  });
  return postIds;
};

export const postDataModel = (data, forceValues) => {
  const {
    forum_posts: {
      data: posts,
      included,
      links: { page, pages: totalPages },
    },
    forum_post_votes,
    channel_permissions,
    forum_bookmarks,
  } = data;

  const forumPosts = [];
  const includedData = {};
  const postVotes = forum_post_votes?.data;
  const postsBookmarked = forum_bookmarks?.data;

  const votedPostsIds = getPostsIdsForData(postVotes);
  const bookmarkedPostsIds = getPostsIdsForData(postsBookmarked);
  let permissions;

  included.forEach((item) => {
    const {
      attributes: { first_name, last_name, image, id, cool_human_badge_url, is_cool_human },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: `${first_name} ${last_name}`,
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
      first_name,
      last_name,
    };
  });

  posts.forEach((post) => {
    let postData = post.attributes;
    const {
      relationships: { user },
    } = post;

    const postVoted = votedPostsIds[post.id];

    postData.user = includedData[`${user.data.id}-${user.data.type}`];
    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }
    postData.hasBookmarked = !!bookmarkedPostsIds[post.id];
    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }
    forumPosts.push(postData);
  });

  if (channel_permissions) {
    permissions = getForumPermissionsConfig(channel_permissions);
  }
  return { posts: forumPosts, permissions, page, totalPages };
};

export const postCommentDataModel = (data) => {
  const {
    forum_post_comments: {
      data: comments,
      included,
      links: { page, pages: totalPages },
    },
    forum_comment_votes: { data: commentVotes },
    channel_permissions,
    forum_post: forumPost,
  } = data;
  const postComments = [];
  const includedData = {};
  let permissions;

  included.forEach((item) => {
    const {
      attributes: { first_name, last_name, image, id, cool_human_badge_url, is_cool_human },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: `${first_name} ${last_name}`,
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
    };
  });

  comments.forEach((comment) => {
    const commentData = comment.attributes;
    const commentVoted = commentVotes.find(
      (item) => item.attributes.forum_post_comment_id.toString() === comment.id,
    );

    const {
      relationships: { user },
    } = comment;
    if (commentVoted) {
      commentData.voted = true;
      commentData.vote = commentVoted?.attributes?.vote_flag;
    }

    commentData.user = includedData[`${user.data.id}-${user.data.type}`];
    postComments.push(commentData);
  });

  if (channel_permissions) {
    permissions = getForumPermissionsConfig(channel_permissions);
  }

  const { posts } = postDataModel({
    forum_posts: {
      data: [forumPost.data],
      included: forumPost.included,
      links: {}
    },
    forum_bookmarks: data?.forum_bookmark,
    forum_post_votes: data?.forum_post_vote
  });

  return { comments: postComments, permissions, page, totalPages, forumPost: posts[0] };
};

export const postCommentReplyDataModel = (data) => {
  const {
    forum_comment_replies: {
      data: replies,
      included,
      links: { page, pages: totalPages },
    },
    forum_post_comment: {
      data: { id: commentId },
    },
    forum_comment_reply_votes: { data: replyVotes },
  } = data;
  const commentReplies = [];
  const includedData = {};

  included.forEach((item) => {
    const {
      attributes: { first_name, last_name, image, id, cool_human_badge_url, is_cool_human },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: `${first_name} ${last_name}`,
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
    };
  });

  replies.forEach((reply) => {
    const replyData = reply.attributes;
    const {
      relationships: { user },
    } = reply;
    const replyVoted = replyVotes.find(
      (item) => item.attributes.forum_comment_reply_id.toString() === reply.id,
    );

    if (replyVoted) {
      replyData.voted = true;
      replyData.vote = replyVoted?.attributes?.vote_flag;
    }

    replyData.user = includedData[`${user.data.id}-${user.data.type}`];
    commentReplies.push(replyData);
  });

  return { replies: commentReplies, commentId, page, totalPages };
};

export const downVoteItem = (item) => {
  const updatedItem = { ...item };
  if (updatedItem && updatedItem.voted) {
    if (!updatedItem.vote) {
      updatedItem.downvotes_count -= 1;
      delete updatedItem.voted;
      delete updatedItem.vote;
    } else {
      updatedItem.downvotes_count += 1;
      updatedItem.upvotes_count -= 1;
      updatedItem.vote = false;
    }
  } else {
    updatedItem.downvotes_count += 1;
    updatedItem.voted = true;
    updatedItem.vote = false;
  }
  return updatedItem;
};

export const upVoteItem = (item) => {
  const updatedItem = { ...item };
  if (updatedItem && updatedItem.voted) {
    if (updatedItem.vote) {
      updatedItem.upvotes_count -= 1;
      delete updatedItem.voted;
      delete updatedItem.vote;
    } else {
      updatedItem.upvotes_count += 1;
      updatedItem.downvotes_count -= 1;
      updatedItem.vote = true;
    }
  } else {
    updatedItem.upvotes_count += 1;
    updatedItem.voted = true;
    updatedItem.vote = true;
  }
  return updatedItem;
};
