import { kickUserFromChannel } from '../../services/channelService';
import {
  CREATE_CHANNEL_CATEGORY_REQUEST,
  CREATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_REQUEST,
  DELETE_ACTIVE_CHANNEL,
  FETCH_ALL_COMMUNITY_CHANNELS_REQUEST,
  SET_ACTIVE_CHANNEL,
  SET_ACTIVE_CHANNEL_ID,
  SET_ACTIVE_CHANNEL_TYPE,
  UPDATE_CHANNEL_CATEGORY_REQUEST,
  UPDATE_COIN_COUNT,
  UPDATE_CHANNEL_COMMUNITY,
  SET_CURRENT_CHANNELS,
  UPDATE_CATEGORY_POSITION,
  UPDATE_CHANNEL_POSITION,
} from './actionTypes';

export const fetchAllCommunityChannels = (payload) => ({
  type: FETCH_ALL_COMMUNITY_CHANNELS_REQUEST,
  payload,
});

export const setActiveChannel = (channelData) => ({
  type: SET_ACTIVE_CHANNEL,
  payload: { channelData },
});

export const setCurrentChannels = (channelData) => ({
  type: SET_CURRENT_CHANNELS,
  payload: channelData,
});

export const setActiveChannelId = (channelID) => ({
  type: SET_ACTIVE_CHANNEL_ID,
  payload: { channelID },
});

export const setActiveChannelType = (type) => ({
  type: SET_ACTIVE_CHANNEL_TYPE,
  payload: { type },
});

export const deleteActiveChannel = () => ({
  type: DELETE_ACTIVE_CHANNEL,
});

export const createCommunityChannel = (payload) => ({
  type: CREATE_CHANNEL_REQUEST,
  payload,
});

export const updateCommunityChannel = (payload) => ({
  type: UPDATE_CHANNEL_REQUEST,
  payload,
});

export const createChannelCategory = (payload) => ({
  type: CREATE_CHANNEL_CATEGORY_REQUEST,
  payload,
});

export const updateChannelCategory = (payload) => ({
  type: UPDATE_CHANNEL_CATEGORY_REQUEST,
  payload,
});

export const kickUser = (communityId, channelId, user_id, callback, groupId) => async () => {
  await kickUserFromChannel(communityId, channelId, user_id, groupId);
  callback();
};

export const updateCoinCount = (payload) => ({
  type: UPDATE_COIN_COUNT,
  payload,
});

export const updateChannelCommunity = (payload) => ({
  type: UPDATE_CHANNEL_COMMUNITY,
  payload,
});

export const updateCategoryPosition = (payload) => ({
  type: UPDATE_CATEGORY_POSITION,
  payload,
});

export const updateChannelPosition = (payload) => ({
  type: UPDATE_CHANNEL_POSITION,
  payload,
});
