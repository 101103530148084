import _ from 'lodash';
import {
  AUTH_LOG_OUT_SUCCESS,
  FETCH_FOLLOWEE_SUCCESS,
  FETCH_FOLLOWERS_SUCCESS,
  SET_STAGE_AUDIO_PERMISSION_ACCEPTED,
  SET_STAGE_VIDEO_PERMISSION_ACCEPTED,
  SET_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  stageAudioPermissionAccepted: false,
  stageVideoPermissionAccepted: false,
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  let newFollowers;
  let newFollowee;
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload.user };
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;
    case UPDATE_USER_REQUEST:
      return { ...state, isUpdating: true };
    case UPDATE_USER_SUCCESS:
      return { ...state, ...action.payload.userData, isUpdating: false };
    case UPDATE_USER_FAILED:
      return { ...state, isUpdating: false };
    case FETCH_FOLLOWERS_SUCCESS:
      if (!action?.payload?.reset) { newFollowers = _.cloneDeep(state.followers); } else newFollowers = { data: [], nextPageId: '' };
      newFollowers.nextPageId = action.payload.nextPageId;
      newFollowers.data.push(...action.payload.data);
      return { ...state, followers: newFollowers };
    case FETCH_FOLLOWEE_SUCCESS:
      if (!action?.payload?.reset) { newFollowee = _.cloneDeep(state.followee); } else newFollowee = { data: [], nextPageId: '' };
      newFollowee.nextPageId = action.payload.nextPageId;
      newFollowee.data.push(...action.payload.data);
      return { ...state, followee: newFollowee };
    case SET_STAGE_AUDIO_PERMISSION_ACCEPTED:
      return { ...state, stageAudioPermissionAccepted: action.payload };
    case SET_STAGE_VIDEO_PERMISSION_ACCEPTED:
      return { ...state, stageVideoPermissionAccepted: action.payload };
    default:
      return state;
  }
};

export default reducer;
