import server from '../api/server';

export const createTag = (community_id, group_id, tag_name) => new Promise((resolve, reject) => {
  server.post(`/communities/${community_id}/groups/${group_id}/post_tags`, { name: tag_name })
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const fetchTags = (community_id, group_id) => new Promise((resolve, reject) => {
  server.get(`/communities/${community_id}/groups/${group_id}/post_tags`).then((response) => {
    resolve(response)
  })
    .catch(reject)
})

export const updateTag = (community_id, group_id, tag_id, tag_name) => new Promise((resolve, reject) => {
  server.put(`/communities/${community_id}/groups/${group_id}/post_tags/${tag_id}`, { name: tag_name }).then((response) => {
    resolve(response)
  })
    .catch(reject)
})

export const deleteTag = (community_id, group_id, tag_id) => new Promise((resolve, reject) => {
  server.delete(`/communities/${community_id}/groups/${group_id}/post_tags/${tag_id}`).then((response) => {
    resolve(response)
  })
    .catch(reject)
})
