import { all, fork, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import { getCalendarEventDetails, getCalendarEventsNew, getZoomEventSignature, getZoomEvents, getStreamingEvents } from '../services/CalendarService';
import {
  ADD_CALENDAR_PERMISSIONS,
  GET_CALENDER_EVENT_REQUEST,
  GET_CALENDER_EVENT_SUCCESS,
  SET_CALENDER_EVENT_LOADING,
  GET_ZOOM_SIGNATURE_REQUEST,
  GET_ZOOM_SIGNATURE_SUCCESS,
  GET_ZOOM_EVENT,
  GET_ZOOM_EVENT_SUCCESS,
  GET_STREAMING_EVENT,
  GET_STREAMING_EVENT_SUCCESS,
  GET_CALENDER_EVENT_REMINDER,
} from '../store/actions/actionTypes';
import { fetchCommunityFeed } from '../services/communityService';
import server from '../api/server';
import { fetchAllCommunityChannels } from '../services/channelService';

function* getCalendarEventDetailsByEventId() {
  try {
    yield takeEvery(GET_CALENDER_EVENT_REQUEST, function* ({ payload: { channel_id, community_id, event_id, activeGroupId } }) {
      const resCalEvent = yield getCalendarEventDetails(community_id, channel_id, event_id, activeGroupId);
      yield put({
        type: ADD_CALENDAR_PERMISSIONS,
        channelPermissions: resCalEvent?.data?.data?.calendar_channel_permissions,
        stagePermissions: resCalEvent?.data?.data?.stage_channel_permissions || resCalEvent?.data?.data?.zoom_channel_permissions,
      });
      yield put({
        type: GET_CALENDER_EVENT_SUCCESS,
        payload: resCalEvent?.data?.data?.calendar_event,
        channelDetails: resCalEvent?.data?.data?.calendar_channel,
        stageChannelDetails: resCalEvent?.data?.data?.stage_channel || resCalEvent?.data?.data?.zoom_channel || resCalEvent?.data?.data?.streaming_channel,
      });
      if (resCalEvent?.data?.data?.calendar_event_reminder && Object?.keys(resCalEvent?.data?.data?.calendar_event_reminder)?.length !== 0) {
        yield put({
          type: GET_CALENDER_EVENT_REMINDER,
          payload: true
        })
      }
      else {
        yield put({
          type: GET_CALENDER_EVENT_REMINDER,
          payload: false
        })
      }
      yield put({
        type: SET_CALENDER_EVENT_LOADING,
        payload: false,
      });
    });
  } catch (error) {
    yield put({
      type: SET_CALENDER_EVENT_LOADING,
      payload: false,
    });
    const message = parseAxiosErrorMessage(error);
    NotificationService.error(message);
  }
}

function* getZoomEventSignatureSaga() {
  try {
    yield takeEvery(
      GET_ZOOM_SIGNATURE_REQUEST,
      function* ({ payload: { community_id, event_id, activeGroupId } }) {
        const resCalEvent = yield getZoomEventSignature(community_id, event_id, activeGroupId);
        yield put({
          type: GET_ZOOM_SIGNATURE_SUCCESS,
          zoomToken: resCalEvent?.data?.data?.signature,
          zoomZAK: resCalEvent?.data?.data?.zak_token,
        });

      },
    );
  } catch (error) {
    yield put({
      type: SET_CALENDER_EVENT_LOADING,
      payload: false,
    });
    const message = parseAxiosErrorMessage(error);
    NotificationService.error(message);
  }
}
function* getZoomEventsSaga() {
  try {
    yield takeEvery(
      GET_ZOOM_EVENT,
      function* ({ payload: { community_id, activeGroupId, channel_id } }) {
        const response = yield getZoomEvents(community_id, activeGroupId, channel_id);
        yield put({
          type: GET_ZOOM_EVENT_SUCCESS,
          payload: response?.data?.data,
        });
      },
    );
  } catch (error) {
    yield put({
      type: SET_CALENDER_EVENT_LOADING,
      payload: false,
    });
    const message = parseAxiosErrorMessage(error);
    NotificationService.error(message);
  }
}
function* getStreamingEventsSaga() {
  try {
    yield takeEvery(
      GET_STREAMING_EVENT,
      function* ({ payload: { community_id, activeGroupId, channel_id } }) {
        const response = yield getStreamingEvents(community_id, activeGroupId, channel_id);

        yield put({
          type: GET_STREAMING_EVENT_SUCCESS,
          payload: response?.data?.data,
        });
      },
    );
  } catch (error) {
    yield put({
      type: SET_CALENDER_EVENT_LOADING,
      payload: false,
    });
    const message = parseAxiosErrorMessage(error);
    NotificationService.error(message);
  }
}


export default function* rootSaga() {
  yield all([fork(getCalendarEventDetailsByEventId), fork(getZoomEventSignatureSaga), fork(getZoomEventsSaga), fork(getStreamingEventsSaga)]);
}
