import server from '../api/server';
import axios from 'axios';

const CLOUDFRONT_URL = process.env.REACT_APP_CLOUDFRONT_URL;
const CLOUDFRONT_DOC_URL = process.env.REACT_APP_CLOUDFRONT_DOC_URL;
const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;

export const getPresignedPostData = (url = 'onboarding/request_presigned_url', file_name) => {
  const params = {};
  if (file_name) {
    params.file_name = file_name;
  }
  return new Promise((resolve, reject) => {
    server
      .get(`/${url}`, { params })
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.url_fields).forEach((key) => {
      formData.append(key, presignedPostData.url_fields[key]);
    });

    formData.append('Content-Type', file.type);
    formData.append('file', file.uri);

    axios
      .post(presignedPostData.url, formData)
      .then((response) => {
        response.status === 201 ? resolve(response) : reject(response.statusText);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const imageFetch = (url, edits = {}, noEdit = false) => {
  if (!url) return null;

  if (isURL(url)) {
    return url;
  }

  if (Object.keys(edits).length === 0 && edits.constructor === Object && !noEdit) {
    edits = {
      resize: {
        height: 1080,
        width: 1920,
        fit: 'inside',
      },
    };
  }
  const imageRequest = JSON.stringify({
    bucket: S3_BUCKET,
    key: url,
    edits,
  });
  const responseUrl = `${CLOUDFRONT_URL}${btoa(imageRequest)}`;

  return responseUrl;
};

export const documentFetch = (url) => {
  if (isURL(url)) {
    return url;
  }

  const responseUrl = `${CLOUDFRONT_DOC_URL}${url}`;

  return responseUrl;
};

function isURL(url) {
  if (!url) return false;
  var pattern = new RegExp(
    '^(http(s)?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))|' + // OR ip (v4) address
      'localhost' + // OR localhost
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return pattern.test(url);
}
