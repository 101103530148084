import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { parseAxiosErrorMessage } from '../constants/utils';
import {
  createForumPostService,
  fetchAllCommunityChannels,
  createCommunityChannel,
  createChannelCategory,
  updateCommunityChannel,
  updateChannelCategory,
  updateCategoryPositionService,
  updateChannelPositionService,
} from '../services/channelService';
import NotificationService from '../services/notificationService';
import {
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_CATEGORY_REQUEST,
  CREATE_CHANNEL_CATEGORY_SUCCESS,
  FETCH_ALL_COMMUNITY_CHANNELS_REQUEST,
  FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_CATEGORY_REQUEST,
  UPDATE_CHANNEL_CATEGORY_SUCCESS,
  FETCH_GROUPS_REQUEST,
  UPDATE_CATEGORY_POSITION,
  UPDATE_CHANNEL_POSITION,
} from '../store/actions/actionTypes';
import { fetchAllCommunityChannels as fetchAllCommunityChannelsSagaCall, setCurrentChannels } from '../store/actions/channelActions';

let prevCommunityId;

function* fetchAllCommunityChannelsSaga() {
  yield takeLatest(FETCH_ALL_COMMUNITY_CHANNELS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchAllCommunityChannels(payload);
      const { community } = response.data;

      prevCommunityId = community?.id;

      yield put({
        type: FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createCommunityChannelSaga() {
  yield takeLatest(CREATE_CHANNEL_REQUEST, function* ({ payload }) {
    try {
      const response = yield createCommunityChannel(payload);
      yield put({
        type: CREATE_CHANNEL_SUCCESS,
        payload: response,
      });

      const { data } = response;

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateCommunityChannelSaga() {
  yield takeLatest(UPDATE_CHANNEL_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateCommunityChannel(payload);

      yield put({
        type: UPDATE_CHANNEL_SUCCESS,
        payload: response,
      });

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createChannelCategorySaga() {
  yield takeLatest(CREATE_CHANNEL_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const response = yield createChannelCategory(payload);

      yield put({
        type: CREATE_CHANNEL_CATEGORY_SUCCESS,
        payload: response?.data,
      });

      const { data } = response;

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateChannelCategorySaga() {
  yield takeLatest(UPDATE_CHANNEL_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateChannelCategory(payload);

      yield put({
        type: UPDATE_CHANNEL_CATEGORY_SUCCESS,
        payload: response,
      });

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateCategoryPositionSaga() {
  yield takeLatest(UPDATE_CATEGORY_POSITION, function* ({ payload }) {
    try {
      yield put(setCurrentChannels(payload?.orderedChannel));

      yield updateCategoryPositionService(payload?.groupId, payload?.categoryId, payload?.position);
      yield put(fetchAllCommunityChannelsSagaCall({ groupId: payload?.groupId }));
      // NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_UPDATE);
    } catch (error) {
      yield put(setCurrentChannels(payload?.currentChannel));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateChannelPositionSaga() {
  yield takeLatest(UPDATE_CHANNEL_POSITION, function* ({ payload }) {
    try {
      yield put(setCurrentChannels(payload?.orderedChannel));

      yield updateChannelPositionService(payload?.groupId, payload?.channelId, payload?.position);
      yield put(fetchAllCommunityChannelsSagaCall({ groupId: payload?.groupId }));
      // NotificationService.success(SUCCESS_MESSAGES.CHANNEL_UPDATE);
    } catch (error) {
      yield put(setCurrentChannels(payload?.currentChannel));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllCommunityChannelsSaga),
    fork(createCommunityChannelSaga),
    fork(createChannelCategorySaga),
    fork(updateCommunityChannelSaga),
    fork(updateChannelCategorySaga),
    fork(updateCategoryPositionSaga),
    fork(updateChannelPositionSaga),
  ]);
}
