import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  CHECK_EMAIL_REQUEST,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_FAILED,
  SIGN_UP_WITH_EMAIL_SUCCESS,
  SIGN_UP_WITH_EMAIL_FAILED,
  AUTH_SET_ONBOARDED,
  AUTH_DELETE_ONBOARDED,
  AUTH_SET_ROOM_TOKEN,
  LOGIN_USER_ID,
  LOGINED_USER_EMAIL,
  AUTH_SET_TOKEN,
  AUTH_SET_REFRESH_TOKEN,
  CHECK_EMAIL_DELETE,
  GOOGLE_SIGN_IN_SUCCESS,
  GOOGLE_SIGN_IN_FAILED,
  AUTH_LOG_OUT,
  SET_AGORA_UID,
  SET_AGORA_UID_SHARE,
  MUTED_IDS,
  SET_VOICE_ROOM_TOKEN,
  SET_IS_MANUAL_LOGOUT,
  BUBBLE_SIGN_IN_SUCCESS,
  BUBBLE_SIGN_IN_FAILED,
  SET_REDIRECT_URL,
  AUTH_LOG_OUT_SUCCESS,
  CUSTOM_LOGIN_SUCCESS,
  CUSTOM_LOGIN_FAILED,
} from '../actions/actionTypes';

const initialState = {
  loader: false, // We can imporve this based on the loader implemtation
  token: null,
  refreshToken: null,
  loginSuccess: false,
  voiceRoomToken: null,
  agoraUID: null,
  agoraUIDShare: null,
  mutedIds: [],
  isManualLogout: false,
  redirectUrl: null
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
        loader: false,
      };
    case SET_IS_MANUAL_LOGOUT:
      return {
        ...state,
        isManualLogout: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loginSuccess: false,
        loader: false,
      };

    case CHECK_EMAIL_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        checkEmail: {
          isEmailExists: true,
          currentEmail: action.payload.email,
        },
        loader: false,
      };
    case CHECK_EMAIL_FAILED:
      return {
        ...state,
        checkEmail: {
          isEmailExists: false,
          currentEmail: action.payload.email,
        },
        loader: false,
      };
    case CHECK_EMAIL_DELETE:
      return { ...state, checkEmail: null };

    case SIGN_UP_WITH_EMAIL_SUCCESS:
      return {
        ...state,
        signUpSuccess: true,
      };

    case SIGN_UP_WITH_EMAIL_FAILED:
      return {
        ...state,
        signUpSuccess: false,
      };

    case AUTH_SET_ONBOARDED:
      return { ...state, onboarded: action.payload.onboarded };
    case AUTH_DELETE_ONBOARDED:
      return { ...state, onboarded: null };
    case AUTH_SET_ROOM_TOKEN:
      return { ...state, roomToken: action.payload.roomToken };
    case LOGIN_USER_ID:
      return { ...state, id: action.payload };
    case LOGINED_USER_EMAIL:
      return { ...state, loginedEmail: action.payload };
    case AUTH_SET_TOKEN:
      return { ...state, token: action.payload.token };
    case AUTH_SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload.token };

    case GOOGLE_SIGN_IN_SUCCESS:
      return { ...state, googleSignInSuccess: true };
    case GOOGLE_SIGN_IN_FAILED:
      return { ...state, googleSignInSuccess: false };

    case BUBBLE_SIGN_IN_SUCCESS:
      return { ...state, bubbleSignInSuccess: true };
    case BUBBLE_SIGN_IN_FAILED:
      return { ...state, bubbleSignInSuccess: false };

    case CUSTOM_LOGIN_SUCCESS:
      return { ...state, customLoginSuccess: true };
    case CUSTOM_LOGIN_FAILED:
      return { ...state, customLoginSuccess: false };

    case AUTH_LOG_OUT_SUCCESS:
      return { ...initialState, isManualLogout: state.isManualLogout };
    case SET_VOICE_ROOM_TOKEN:
      return { ...state, voiceRoomToken: action.payload.voiceRoomToken };

    case SET_AGORA_UID:
      return { ...state, agoraUID: action.payload.agoraUID };
    case SET_AGORA_UID_SHARE:
      return { ...state, agoraUIDShare: action.payload.agoraUID };
    case SET_REDIRECT_URL:
      return { ...state, redirectUrl: action.payload };
    default:
      return state;
  }
}
