import {
  ADD_CUSTOM_DOMAIN_REQUEST,
  CHANGE_COMMUNITY_APPEARANCE_REQUEST,
  COMMUNITY_FEED_RESET,
  COMMUNITY_RESOURCE_FEED_SET_LIKE,
  CREATE_COMMUNITY_REQUEST,
  FETCH_ALL_COMMUNITY_REQUEST,
  FETCH_CATEGORIES_REQUEST,
  FETCH_COMMUNITY_FEED,
  FETCH_COMMUNITY_FEED_SET_PAGE,
  FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST,
  SET_ACTIVE_COMMUNITY_REQUEST,
  SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST,
  UPDATE_COMMUNITY_DATA,
  UPDATE_COMMUNITY_REQUEST,
  VERIFY_CUSTOM_DOMAIN_REQUEST,
  FETCH_CUSTOM_DOMAIN_DATA_REQUEST,
  REMOVE_CUSTOM_DOMAIN_REQUEST,
  ADD_CUSTOM_CSS_REQUEST,
  REMOVE_CUSTOM_CSS_REQUEST,
  VERIFY_COMMUNITY_MEMBER_REQUEST,
  SET_VERIFY_COMMUNITY_MEMBER_LOADING,
  CREATE_GROUP_REQUEST,
  DELETE_GROUP_REQUEST,
  FETCH_GROUPS_REQUEST,
  UPDATE_GROUP_REQUEST,
  TOGGLE_GROUPS_REQUEST,
  SET_ACTIVE_GROUP_REQUEST,
  SET_COMMUNITY_MEMBER_FLAG,
  ADD_MOBILE_THEMING_REQUEST,
  UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST,
  SET_BRANCH_SETUP_FAILED,
  SET_UNREAD_DM_COUNT,
  SET_IS_PREMIUM,
  GET_GROUP_TAG
} from './actionTypes';

// eslint-disable-next-line max-len
export const fetchPublicCommunityDetails = (payload) => ({ type: FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST, payload });

export const updatePublicCommunitySettingData = (payload) => ({ type: UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST, payload });

export const fetchCategories = () => ({ type: FETCH_CATEGORIES_REQUEST });

export const createCommunity = (payload) => ({ type: CREATE_COMMUNITY_REQUEST, payload });

export const updateCommunity = (payload) => ({ type: UPDATE_COMMUNITY_REQUEST, payload });

export const setActiveCommunity = (payload) => ({
  type: SET_ACTIVE_COMMUNITY_REQUEST,
  payload,
});

export const setNewActiveCommunityWithChannel = (payload) => ({
  type: SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST,
  payload,
});

export const fetchAllCommunity = () => ({
  type: FETCH_ALL_COMMUNITY_REQUEST,
});

export const fetchCommunityFeed = (payload) => ({
  type: FETCH_COMMUNITY_FEED,
  payload,
});

export const setPageCommunityFeed = (page) => ({
  type: FETCH_COMMUNITY_FEED_SET_PAGE,
  payload: { page },
});

export const resetCommunityFeed = () => ({
  type: COMMUNITY_FEED_RESET,
});

export const setCommunityResourceFeed = (newLikes, postId) => ({
  type: COMMUNITY_RESOURCE_FEED_SET_LIKE,
  payload: { like_count: newLikes, post_id: postId },
});

export const updateCommunityData = (payload) => ({
  type: UPDATE_COMMUNITY_DATA,
  payload,
});

export const changeCommunityAppearanceRequest = (payload) => ({
  type: CHANGE_COMMUNITY_APPEARANCE_REQUEST,
  payload,
});

export const addCustomDomain = (payload) => ({
  type: ADD_CUSTOM_DOMAIN_REQUEST,
  payload,
});

export const verifyCustomDomain = (payload) => ({
  type: VERIFY_CUSTOM_DOMAIN_REQUEST,
  payload,
});

export const fetchCustomDomainData = () => ({
  type: FETCH_CUSTOM_DOMAIN_DATA_REQUEST,
});

export const verifyCommunityMember = () => ({
  type: VERIFY_COMMUNITY_MEMBER_REQUEST,
});

export const setVerifyCommunityMemberLoading = (loadingStatus) => ({
  type: SET_VERIFY_COMMUNITY_MEMBER_LOADING,
  payload: loadingStatus,
});

export const removeCustomDomain = (communityId) => ({
  type: REMOVE_CUSTOM_DOMAIN_REQUEST,
  communityId,
});

export const addCustomCss = (custom_css) => ({
  type: ADD_CUSTOM_CSS_REQUEST,
  custom_css,
});

export const removeCustomCss = () => ({
  type: REMOVE_CUSTOM_CSS_REQUEST,
});

export const fetchGroups = () => ({
  type: FETCH_GROUPS_REQUEST,
});

export const createGroup = (payload) => ({
  type: CREATE_GROUP_REQUEST,
  payload,
});

export const updateGroup = (payload) => ({
  type: UPDATE_GROUP_REQUEST,
  payload,
});

export const deleteGroup = (payload) => ({
  type: DELETE_GROUP_REQUEST,
  payload,
});

export const toggleGroups = (enable) => ({
  type: TOGGLE_GROUPS_REQUEST,
  enable,
});

// Tag Actions
export const getAllTags = (payload) => ({
  type: GET_GROUP_TAG,
  payload
})

// eslint-disable-next-line max-len
export const setActiveGroup = (communityId, id, to_delete_active_channel = false, fetchGroupAndSetActive = false, skip_reset_group_loading = false) => ({
  type: SET_ACTIVE_GROUP_REQUEST,
  payload: {
    communityId,
    id,
    get_all_group_and_set_active: fetchGroupAndSetActive,
    to_delete_active_channel,
    skip_reset_group_loading,
  },
});

export const setCommunityMemberFlag = (status) => ({
  type: SET_COMMUNITY_MEMBER_FLAG,
  payload: status,
});

export const addMobileTheming = (payload) => ({
  type: ADD_MOBILE_THEMING_REQUEST,
  payload,
});

export const setBranchInitError = (state) => ({
  type: SET_BRANCH_SETUP_FAILED,
  payload: state,
});

export const setUnreadDmCount = (state) => {
  return {
    type: SET_UNREAD_DM_COUNT,
    payload: state
  }
}

export const setPremiumModal = (state) => {
  return {
    type: SET_IS_PREMIUM,
    payload: state
  }
}
