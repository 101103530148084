import { createSelector } from 'reselect';

const getCommunity = (state) => state?.community;

export const getCommunityState = createSelector([getCommunity], (community) => community);

export const getCommunityUserData = createSelector([getCommunityState], (community) => community?.communityUser);

export const getActiveCommunity = createSelector([getCommunity], (community) => community?.currentCommunity);

export const getActiveCommunityId = createSelector([getCommunity], (community) => community?.activeCommunity);
const getOfflineChat = (state) => state.offlineChat;
export const getActiveCommunityAccentColor = createSelector(
  [getActiveCommunity],
  (community) => community?.accent_color || '#3469AF',
);

export const getActiveCommunityFontsClass = createSelector([getActiveCommunity], (community) => ({
  primaryFont: `font-${community?.appearance_metadata?.primary_font || 'Geomanist'} font-semibold ${
    community?.appearance_metadata?.primary_font === 'Inter' ? 'tracking-tight' : 'tracking-normal'
  }`,
  secondaryFont: `font-${community?.appearance_metadata?.secondary_font || 'Geomanist'} ${
    community?.appearance_metadata?.secondary_font === 'Inter' ? 'tracking-tight' : 'tracking-normal'
  }`,
}));

export const getRequestHostFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.request_host,
);

export const getRedirectUrlFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.redirect_url,
);

export const getSlugIdFromCommunityPublicDetails = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.slug_id,
);

export const getActiveCommunityViaPublicCommunity = createSelector(
  [getCommunity],
  (community) => community?.publicCommunityDetails?.id,
);

export const getGroups = createSelector([getActiveCommunity], (community) => community?.groups);

/**
 * All the groups related selectors
 */

export const getActiveGroupId = createSelector([getCommunity], (community) => community?.activeGroup);

export const getActiveGroup = createSelector([getCommunity], (community) =>
  community?.currentCommunity?.groups?.find((data) => Number(data?.id) === Number(community?.activeGroup)),
);

export const isCurrentActiveGroupIsMainGroup = createSelector([getActiveGroup], (group) => group?.main_group);

// export const getActiveGroup = () => 34;
export const getChatLayout = createSelector(
  [getActiveCommunity],
  (communityState) => communityState?.appearance_metadata?.chat_layout,
);

export const getOfflineTextChannelMessages = createSelector(
  [getOfflineChat],
  (offlineChat) => offlineChat.textMessages,
);

export const getOfflinePrivateMessages = createSelector([getOfflineChat], (offlineChat) => offlineChat.privateMessages);

export const getOfflineStageMessages = createSelector([getOfflineChat], (offlineChat) => offlineChat.stageMessages);

export const getCommunityOwner = createSelector([getCommunity], (community) => community?.communityOwner);

export const getBranchInitError = createSelector([getCommunity], (community) => community?.branchSetupFailed);

export const getUnreadDmCount = createSelector([getCommunity], (community) => community?.unreadDm);