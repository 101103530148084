import { CHANNEL_TYPES } from '../../../constants';
import { getForumPermissionsConfig, getPostsIdsForData } from '../forum/forumModel';

const retrievePostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.forum_post_id || item?.resource_post_id] = item;
  });
  return postIds;
};

export const postFeedDataModel = (data, forceValues) => {
  const {
    posts,
    links: { page, pages: totalPages },
    channel_permissions,
    post_votes,
    bookmarks,
  } = data;
  const feedPosts = [];

  const votedPostsIds = retrievePostsIdsForData(post_votes);
  const bookmarkedPostsIds = retrievePostsIdsForData(bookmarks);

  posts.forEach((post) => {
    let postData = post;

    const postVoted = votedPostsIds[postData.id];
    if (postData.channel_type === CHANNEL_TYPES.FORUM) {
      postData.forumPermissions = getForumPermissionsConfig(channel_permissions[postData.channel_id]);
    }

    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }

    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }

    if (bookmarkedPostsIds[postData.id]?.id) {
      postData.hasBookmarked = true;
    }

    feedPosts.push(postData);
  });

  return { posts: feedPosts, permissions: channel_permissions, page, totalPages };
};
