import server from '../api/server';
import serverV from '../api/serverVersionless';

export const fetchAllCommunityChannels = async (data) => await new Promise((resolve, reject) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  serverV
    .get(`/groups/${data?.groupId}/channels?timezone=${timezone}`)
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const createForumPostService = async (data) => await new Promise((resolve, reject) => {
  server
    .post(`/communities/${data.community_id}/channels/${data.channel_id}/forum_posts`, {
      forum_post: {
        description: data.description,
        image_url: data.image_url,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const createCommunityChannel = async (data) => await new Promise((resolve, reject) => {
  serverV
    .post(`/communities/${data.communityId}/groups/${data.groupId}/channels`, {
      channel: data.channel,
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const updateCommunityChannel = async (data) => await new Promise((resolve, reject) => {
  serverV
    .put(`/communities/${data.communityId}/groups/${data.groupId}/channels/${data.channelId}`, {
      channel: data.channel,
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const createChannelCategory = async ({ communityId, category, groupId }) => await new Promise((resolve, reject) => {
  serverV
    .post(`/communities/${communityId}/groups/${groupId}/community_categories`, {
      community_category: category,
    })
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const kickUserFromChannel = (communityId, channelId, user_id, groupId) => serverV.post(`/communities/${communityId}/groups/${groupId}/channels/${channelId}/channel_kicked_users`, {
  channel_kicked_user: {
    user_id,
  },
});

export const muteUserFromChannel = (communityId, channelId, user_id, groupId) => serverV.post(`/communities/${communityId}/groups/${groupId}/channels/${channelId}/channel_muted_users`, {
  channel_muted_user: {
    user_id,
  },
});

export const fetchChannelPermissions = (communityId, channelId, groupId) => serverV.get(`/communities/${communityId}/groups/${groupId}/channels/${channelId}/channel_community_roles`);

export const setChannelPermissions = (communityId, channelId, roleId, body, groupId) => serverV.put(
  `/communities/${communityId}/groups/${groupId}/channels/${channelId}/channel_community_roles/${roleId}`,
  body,
);

export const deleteCommunitychannel = (communityId, channelId, groupId) => serverV.delete(`/communities/${communityId}/groups/${groupId}/channels/${channelId}`);

export const updateChannelCategory = async ({
  communityId, categoryId, category, groupId,
}) => await new Promise((resolve, reject) => {
  serverV
    .put(`/communities/${communityId}/groups/${groupId}/community_categories/${categoryId}`, {
      community_category: category,
    })
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const updateCategoryPositionService = (groupId, categoryId, position) => server.patch(`/groups/${groupId}/community_categories/${categoryId}/move`, {
  position,
});

export const updateChannelPositionService = (groupId, channelId, position) => server.patch(`/groups/${groupId}/channels/${channelId}/move`, {
  position,
});

export const deleteChannelCategory = (communityId, categoryId, groupId) => serverV.delete(`/communities/${communityId}/groups/${groupId}/community_categories/${categoryId}`);

export const updateGroupPositionService = (communityId, groupId, position) => server.patch(`communities/${communityId}/groups/${groupId}/move`, {
  position,
});

export const fetchForumMediaChannelsWithPermisson = async (communityId, groupId) => await new Promise((resolve, reject) => {
  server
    .get(`/communities/${communityId}/create_post_channels?group_id=${groupId}`)
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});
