/* eslint-disable no-use-before-define */
import _ from 'lodash';
import NotificationService from '../../services/notificationService';
import { isGiphyUrl, arrayToBooleanObject } from '../../helpers';
import { getAuthToken, getUserIdFromAuthCookie } from '../../constants/authUtils';
import { imageFetch } from '../../services/preSignedAws';
import { initialiseSocket } from './textActions';
import { store } from '../../store/configureStore';
import { setSocket, setUserChannel } from './socketAction';
import { captureException, LOGGER_JAVASCRIPT } from '../../services/SentryService';
import {
  ADD_BLOCKED_USER_IDS_CHAT,
  CHAT_CHANNEL_CONNECTION_SUCCESS,
  REMOVE_BLOCKED_USER_IDS_CHAT,
  RESET_CHAT_STATE,
  SET_EARLIER_MESSAGES_CHAT,
  SET_LAST_MESSAGE_CHAT,
  SET_LOAD_MORE_CHAT,
  SET_TEXT_MESSAGES_CHAT,
  UPDATE_CHAT_STATE,
  SET_PINNED_MESSAGE,
  SET_NOTIFICATION_STATUS,
  SET_CHAT_LOADING,
  NEW_CHAT_REQUEST,
} from './actionTypes';

let timeoutVar;
let count = 0;
const MESSAGE_LIMIT = 25;
const TIME_LIMIT = 10000;
const _newMessages = [];

export const setLoadMoreChat = (value) => {
  return {
    type: SET_LOAD_MORE_CHAT,
    payload: {
      value,
    },
  };
};

export const setEarlierMessagesChat = (messages) => ({
  type: SET_EARLIER_MESSAGES_CHAT,
  payload: {
    messages,
  },
});

export const chatChannelConnectionSuccess = (
  chatChannel,
  channelData,
  channelConfigs,
  pinnedMessage,
  isNotificationMuted,
) => ({
  type: CHAT_CHANNEL_CONNECTION_SUCCESS,
  payload: { chatChannel, channelData, channelConfigs, pinnedMessage, isNotificationMuted },
});

export const addBlockedUserIdsChat = (users) => ({
  type: ADD_BLOCKED_USER_IDS_CHAT,
  payload: {
    users,
  },
});

export const removeBlockedUserIdsChat = (value) => {
  return {
    type: REMOVE_BLOCKED_USER_IDS_CHAT,
    payload: {
      value,
    },
  };
};

export const resetChatState = () => ({
  type: RESET_CHAT_STATE,
});

export const updateChatState = (data) => ({
  type: UPDATE_CHAT_STATE,
  payload: data,
});

export const renderChatChannelMessage = (messages, metadata) => (dispatch) => {
  let lastMessageId;
  let canLoadMore;

  if (metadata && metadata.after) {
    lastMessageId = metadata.after;
  }

  const newMessages = _.map(messages, generateMessageFromResponse);

  if (newMessages?.length > 0) {
    if (newMessages?.length < 15) {
      canLoadMore = false;
    } else {
      canLoadMore = true;
    }

    dispatch(
      updateChatState({
        messages: newMessages,
        lastMessageId,
        canLoadMore,
      }),
    );
  }
};

export const appendMessageChat = (newMessages) => (dispatch, getState) => {
  const { messages, hasEarlierMessages, blockedUserIds } = getState().chat;

  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = [...newMessages, ...messages];
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(updateChatState({ hasEarlierMessages: false }));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = [...newMessages, ...messages]
      .filter((item) => !blockedUserIds?.includes(item.user._id))
      .slice(0, MESSAGE_LIMIT);
  }
  dispatch(updateChatState({ messages: updatedMessages }));
};

export const deleteMessageChat = (message) => (dispatch, getState) => {
  const { messages, hasEarlierMessages, blockedUserIds } = getState().chat;
  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = messages.filter((item) => item._id !== message._id);
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(updateChatState({ hasEarlierMessages: false }));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = messages
      .filter((item) => !blockedUserIds?.includes(item.user._id) && item._id !== message._id)
      .slice(0, MESSAGE_LIMIT);
  }

  dispatch(updateChatState({ messages: updatedMessages }));
};

export const updateChatMessagesChat =
  (modVar = 10, delay = 500) =>
  (dispatch) => {
    count += 1;
    if (_newMessages?.length === 0) return;
    if (count === 1) {
      dispatch(appendMessageChat([..._newMessages]));
      _newMessages.length = 0;
      return;
    }
    if (timeoutVar && count % modVar !== 0) {
      clearTimeout(timeoutVar);
    }
    timeoutVar = setTimeout(() => {
      dispatch(appendMessageChat([..._newMessages]));
      _newMessages.length = 0;
    }, delay);
  };

export const setMessagesChat = (messages) => ({
  type: SET_TEXT_MESSAGES_CHAT,
  payload: { messages },
});

export const generateMessageFromResponse = (response, { level = 0 } = {}) => {
  if (!response) return null;
  let name = ""
  if (response.user.first_name !== undefined && response.user.first_name !== null) {
    name = `${response.user.first_name} ${response.user.last_name}`;
  } else if (response.title !== undefined && response.title !== null) {
    name = response.title || "";
  }
  const userState = store.getState().user;
  // const currentUser = userState?.attributes;
  const currentUserID = getUserIdFromAuthCookie();

  return {
    _id: response.id,
    message_id: response.message_id,
    isSent: response.isSent !== undefined && response.isSent !== null ? response.isSent : true,
    // position: response.user.id == currentUser.id ? 'right' : '',
    position: response.user.id === parseInt(currentUserID) ? 'right' : '',
    title: name,
    avatar: response.user.image ? imageFetch(response.user.image) : undefined,
    'is_cool_human?': response.user['is_cool_human?'],
    cool_human_badge_url: response.user.cool_human_badge_url,
    type: isGiphyUrl(response.body || '') ? 'photo' : 'text',
    image: isGiphyUrl(response.body || '') ? response.body : '',
    text: !isGiphyUrl(response.body || '') ? response.body : '',
    image_url: response.image_url,
    createdAt: response.inserted_at,
    date: response.inserted_at,
    user: {
      _id: response.user.id,
      name,
      avatar: response.user.image ? imageFetch(response.user.image) : undefined,
      'is_cool_human?': response.user['is_cool_human?'],
      cool_human_badge_url: response.user.cool_human_badge_url,
    },
    parent:
      response.parent_message && level === 0
        ? generateMessageFromResponse(response.parent_message, { level: 1 })
        : null,
  };
};

const receiveChatMessage = (messages) => (dispatch, getState) => {
  const { auth } = getState();
  const userId = getUserIdFromAuthCookie();
  const userUUID = Number(userId);
  const reciveObj = _.map(messages, generateMessageFromResponse);
  const message = reciveObj[0];

  if (message.user._id !== userUUID) {
    _newMessages.unshift(message);
  }

  dispatch(updateChatMessagesChat(30, 1000));
};

export const setLastMessageChat = (id) => ({
  type: SET_LAST_MESSAGE_CHAT,
  payload: {
    id,
  },
});

export const closeChatChannelConnection = () => async (dispatch, getState) => {
  try {
    const { chat, socket } = getState();

    const { chatChannel } = chat;

    if (chatChannel) {
      chatChannel.off(`message:new`);
      chatChannel.off(`message:pinned`);
      chatChannel.off(`message:deleted`);
      chatChannel.leave();
    }
    // socket?.socket?.disconnect();
    // dispatch(setSocket(null));
    dispatch(resetChatState());
  } catch (error) {
    captureException(`ChatActions closeChatChannelConnection Error: ${error}`, LOGGER_JAVASCRIPT);
    console.log({ error });
  }
};

export const messagePinned = (data) => async (dispatch, getState) => {
  const { pinnedMessage } = getState().chat;
  const updatedMessage = generateMessageFromResponse(data.message);

  if (updatedMessage?._id === pinnedMessage?._id) {
    dispatch(setPinnedMessage(null));
  } else {
    dispatch(setPinnedMessage(updatedMessage));
  }
};

// export const pinMessage = ({ message_id, pinned }) =>()=> {
//   const { chat } = getState();
//   const { chatChannel } = chat;
//   chatChannel.push(`message:pin`, {
//     message_id: message_id,
//     pinned, // true|false
//   });
// };

export const deletedChatMessage = (data) => (dispatch) => {
  const updatedMessage = generateMessageFromResponse(data.message);
  dispatch(deleteMessageChat(updatedMessage));
};

export const setPinnedMessage = (pinnedMessage) => ({
  type: SET_PINNED_MESSAGE,
  payload: { pinnedMessage },
});

export const getChatChannelConfigs = (data) => {
  const configObj = arrayToBooleanObject(data);

  return {
    canReadMessage: configObj.READ_MESSAGES,
    canSendMessage: configObj.SEND_MESSAGES,
    canReplyMessage: configObj.REPLY_MESSAGES,
    canManageMessage: configObj.MANAGE_MESSAGES,
  };
};
export const setNotificationStatus = (isNotificationMuted) => ({
  type: SET_NOTIFICATION_STATUS,
  payload: { isNotificationMuted },
});
export const muteChatNotifications = (value) => (dispatch, getState) => {
  try {
    const {
      chat: { chatChannel },
    } = getState();

    chatChannel.push(`notifications:mute`, { mute: value }).receive('ok', (data) => {
      dispatch(setNotificationStatus(data.muted));
    });
  } catch (error) {
    captureException(`ChatActions muteChatNotifications Error: ${error}`, LOGGER_JAVASCRIPT);
    console.log({ error });
  }
};

export const initialiseChatChannel = (channelId) => async (dispatch, getState) => {
  try {
    const {
      auth,
      chat: { channelData },
    } = getState();



    if (channelId === channelData?.channel?.id) {
      return;
    }
    if (!channelId) {
      throw new Error('ActiveChannelID not present');
    }
    dispatch(updateChatState({ channelLoading: true }));

    dispatch(closeChatChannelConnection());

    let socketConn = getState().socket.socket;
    if (!socketConn) {
      // await initialiseSocket({ auth, dispatch });
      await initialiseSocket({ auth, dispatch, channelId, getState });
      socketConn = getState().socket.socket;
    }

    const chatChannel = socketConn.channel(`text:${channelId}`, {});

    chatChannel
      .join()
      .receive('ok', async (data) => {
        dispatch(renderChatChannelMessage(data.messages.data, data.messages.metadata));
        dispatch(
          chatChannelConnectionSuccess(
            chatChannel,
            data,
            getChatChannelConfigs(data.channel_permissions),
            generateMessageFromResponse(data.pinned_message),
            data.notifs_muted,
          ),
        );
      })
      .receive('error', ({ reason }) => {
        if (reason === 'kicked from room') {
          NotificationService.error("You're not permitted to enter this room. Please try again later!");
          dispatch(closeChatChannelConnection(null, true));
        }
        dispatch(updateChatState({ redirect: true }));
      })
      .receive('timeout', (e) => {
        NotificationService.error('Network Issue!');

        dispatch(updateChatState({ redirect: true }));
      });

    chatChannel.on(`message:new`, (msg) => dispatch(receiveChatMessage(msg)));

    chatChannel.on(`message:pinned`, (data) => dispatch(messagePinned(data)));
    chatChannel.on(`message:deleted`, (data) => dispatch(deletedChatMessage(data)));
  } catch (error) {
    console.log({ error });
    captureException(`ChatActions initialiseChatChannel Error: ${error}`, LOGGER_JAVASCRIPT);
  }
};
export const setChannelLoading = (channelLoading) => ({
  type: SET_CHAT_LOADING,
  payload: channelLoading,
});
 export const setNewChatRequest = (payload) => ({
	type: NEW_CHAT_REQUEST,
	payload,
 })
