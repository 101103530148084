import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { Provider } from 'react-redux';
import { Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { CommunityFavicon } from './components/community/CommunityFavicon';
import CommunityNotFound from './components/shared/tailwind/CommunityNotFound';
import LoadingSpinner from './components/shared/tailwind/LoadingSpinner';
import { getAuthToken } from './constants/authUtils';
import { history } from './constants/utils';
import { getScreenNameForPath } from './constants/analyticsUtils';
import indexRoutes from './routes';
import CustomRoute from './routes/CustomRoute';
import AnalyticService from './services/AnalyticsService';
import {
  checkCommunityMember,
  checkForMaintenance,
  fetchPublicCommunityDetails,
  onboardingEntriesPost,
  setUserRoleOnInvite,
  verifyOnboardingEntry,
} from './services/communityService';
import PosthogService from './services/PosthogService';
import {
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
  ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS,
  ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
  SET_COMMUNITY_MEMBER_FLAG,
  SET_REDIRECT_URL,
  // VERIFY_COMMUNITY_MEMBER_FAILED,
  VERIFY_COMMUNITY_MEMBER_SUCCESS,
} from './store/actions/actionTypes';
import { setBranchInitError, setVerifyCommunityMemberLoading } from './store/actions/communityActions';
import { persistor, store } from './store/configureStore';
import NotificationService from './services/notificationService';
import { initialiseSocket } from './store/actions/textActions';

function App({ setPage }) {
  const [trackingSession, setTrackingSession] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainVerified, setIsDomainVerified] = useState(false);
  const authToken = getAuthToken();
  const { user } = store.getState();
  const redirectUrl = store.getState().auth?.redirectUrl;
  const publicCommunityDetails = store.getState().community?.publicCommunityDetails;
  const onboardingVerifyStepStatus = store.getState()?.onboarding?.onboardingVerifyStepStatus;
  const isCommunityMemberVerified = store.getState()?.community?.isCommunityMemberVerified || false;
  const isCustomOnboarding = publicCommunityDetails?.custom_onboarding || false;
  const entryApproval = publicCommunityDetails?.entry_approval;

  let previousPath = '';

  const getActiveGroup = useCallback(() => {
    const { currentCommunity, activeGroup } = store.getState().community;
    return currentCommunity?.groups?.find((data) => Number(data?.id) === Number(activeGroup));
  }, []);

  const trackRouteChange = useCallback((pathname) => {
    if (pathname === previousPath) return;

    const publicCommunityDetails = store.getState().community?.publicCommunityDetails;
    const activeChannelData = store.getState().channel?.activeChannelData;

    const community_id = publicCommunityDetails?.id;
    const community_name = publicCommunityDetails?.name;
    const activeGroup = getActiveGroup();
    const { channel_type, name } = activeChannelData;

    const logPayload = {
      community_id,
      community_name,
      screen_name: pathname ?? '',
      group_id: activeGroup?.id,
      group_name: activeGroup?.name,
      channel_name: name ?? 'NA',
      channel_type: channel_type ?? 'NA',
      Source: 'Browser',
    };
    PosthogService.capture('Page Visit', logPayload);

    AnalyticService.logMixpanelEvent('Screen Navigation', {
      'Screen Name': getScreenNameForPath(pathname),
      'Community ID': community_id,
    });

    previousPath = pathname;
  }, []);

  const initializeBranch = (branchKey) => {
    const key = branchKey || process.env.REACT_APP_BRANCH_KEY;
    window.branch?.init(key, (err, data) => {
      if (err) {
        store.dispatch(setBranchInitError(true));
        console.log('\n\nBranch Init Err: ', err);
        return;
      }
      store.dispatch(setBranchInitError(false));
      const params = data.data_parsed;
      const customData = params?.custom_data ? JSON.parse(params?.custom_data) : {};
      const referralCode = customData?.referral_code;
      const inviteCode = customData?.invite_code;

      if (referralCode) {
        localStorage.setItem('ReferredBy', referralCode);
      }

      if (inviteCode) {
        localStorage.setItem('InviteCode', inviteCode);
      }
    });
  };

  const fetchPublicDetails = useCallback(async () => {
    try {
      const response = await fetchPublicCommunityDetails();
      initializeBranch(response?.data?.branch_key);
      store.dispatch({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: response,
      });

      // if (
      //   window.location.hostname !== response?.data?.domain_address &&
      //   response?.data?.domain_status &&
      //   response?.data?.domain_address &&
      //   process.env.REACT_APP_ENV === 'staging'
      // ) {
      //   const check =
      //     /((https?:\/\/)?(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(
      //       response?.data?.domain_address,
      //     );
      //   if (check) {
      //     window.location.href = `https://${response?.data?.domain_address}`;
      //   }
      // }

      if (authToken) {
        const onboardingStepVerify = await verifyOnboardingEntry();
        store.dispatch({
          type: ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS,
          payload: onboardingStepVerify?.data,
        });

        await checkCommunityMember()
          .then(async (res) => {
            const socketConn = store.getState().socket.socket;

            if (!socketConn) {
              const { dispatch } = store;
              const { getState } = store;
              await initialiseSocket({
                auth: getState()?.auth,
                dispatch,
                channelId: null,
                getState,
              });
            }
            store.dispatch({
              type: VERIFY_COMMUNITY_MEMBER_SUCCESS,
              payload: res?.data?.community_user,
            });
          })
          .catch(async (error) => {
            if (
              user?.onboarding_status == 'voice_onboarding_completed'
              || user?.onboarding_status == 'voice_contacts_synced'
            ) {
              if (!onboardingStepVerify?.data?.status || (entryApproval == false && isCustomOnboarding == false)) {
                if (entryApproval == true && isCustomOnboarding == false) {
                  // API
                  const referralCode = localStorage.getItem('ReferredBy');
                  const inviteCode = localStorage.getItem('InviteCode');
                  const onboardingPayload = {
                    metadata: {},
                  };

                  if (referralCode) onboardingPayload.metadata.referral_code = referralCode;
                  if (inviteCode) onboardingPayload.metadata.invite_code = inviteCode;

                  const response = await onboardingEntriesPost(onboardingPayload);
                  store.dispatch({
                    type: ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
                    payload: response?.data?.status,
                  });
                  window.location.href = window.location.origin;
                }
                return;
              }
            }

            if (onboardingStepVerify?.data?.status) {
              history.replace('/enter-community');
            }

            if (error.response.status >= 500 && error.response.status <= 510) {
              store.dispatch({
                type: SET_COMMUNITY_MEMBER_FLAG,
                payload: null,
              });
              history.replace('/public/internal-server-error');
            } else {
              store.dispatch({
                type: SET_COMMUNITY_MEMBER_FLAG,
                payload: false,
              });
            }
          })
          .finally(() => {
            store.dispatch(setVerifyCommunityMemberLoading(false));
          });
      } else if (!authToken && window?.location?.pathname === '/' && response?.data?.settings?.guest_view) {
        history.push('/guest');
      }

      setIsDomainVerified(true);
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status && error.response.status == 503) {
        setPage('maintenance');
      }
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user && (process.env.REACT_APP_ENV === 'ggnation' || process.env.REACT_APP_ENV === 'production')) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
      setupLogRocketReact(LogRocket);
    }
    fetchPublicDetails();
    new AnalyticService();

    function beforeExitReset() {
      AnalyticService.clearUser();
    }

    window.addEventListener('beforeunload', beforeExitReset, { capture: true });

    return window.removeEventListener('beforeunload', beforeExitReset, { capture: true });
  }, []);

  useEffect(() => {
    PosthogService.initialize();
    const unlisten = history.listen((listener) => {
      const { pathname } = listener;
      trackRouteChange(pathname);
    });
    const currentLocal = localStorage.getItem('currentLocal');
    if (currentLocal === 'he') {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('dir', 'rtl');
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('dir', 'ltr');
    }

    return unlisten;
  }, []);

  useEffect(() => {
    if (!publicCommunityDetails?.id) return;
    trackRouteChange(history.location.pathname);
  }, [publicCommunityDetails]);

  useEffect(() => {
    if (!user?.username) return;
    if (user && user?.id) {
      const community_id = publicCommunityDetails?.id;
      const community_name = publicCommunityDetails?.name;
      LogRocket.identify(user?.id, {
        name: user?.username,
        email: user?.email,
        community_id,
        community_name,
      });
    }
    PosthogService.identify(user);
  }, [user]);

  const logSessionBeforeUnload = (eventProperties = {}) => {
    const logEvent = () => {
      AnalyticService.logMixpanelEvent('Community Session Duration', eventProperties);
      const StageIsConnected = JSON.parse(sessionStorage.getItem('StageIsConnected') || false);
      if (StageIsConnected) {
        AnalyticService.logMixpanelEvent('Stage Connected Duration', eventProperties);
      }
    };

    window.addEventListener('beforeunload', logEvent);

    return window.removeEventListener('beforeunload', logEvent);
  };

  useEffect(() => {
    if (user?.name && publicCommunityDetails?.name && !trackingSession) {
      setTrackingSession(true);
      const eventProperties = {
        'Community ID': publicCommunityDetails.id,
        'Community Name': publicCommunityDetails.name,
        Username: user.username,
      };
      sessionStorage.setItem('MixpanelSessionProperties', JSON.stringify(eventProperties));
      AnalyticService.setMixpanelIdentify(user.id);
      AnalyticService.logMixpanelEvent('Community Session', eventProperties);
      AnalyticService.logMixpanelTimingEvent('Community Session Duration');
      logSessionBeforeUnload(eventProperties);
    }
  }, [user, publicCommunityDetails]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (authToken) {
    if (user?.onboarding_status == 'voice_onboarding_completed' || user?.onboarding_status == 'voice_contacts_synced') {
      if (isCommunityMemberVerified === false && (isCustomOnboarding || entryApproval)) {
        if ((isCustomOnboarding && !onboardingVerifyStepStatus) || onboardingVerifyStepStatus == 'in_process') {
          history.replace('/onboarding/application-form');
        } else if (
          (isCustomOnboarding && onboardingVerifyStepStatus)
          || (entryApproval && onboardingVerifyStepStatus)
        ) {
          history.replace('/onboarding/application-status');
        } else {
          history.replace('/onboarding/application-error');
        }
      } else if (
        /* isCustomOnboarding && */
        isCommunityMemberVerified === true
        && !redirectUrl
        && (window.location.pathname === '/onboarding/application-form'
          || window.location.pathname === '/onboarding/application-status'
          || window.location.pathname === '/onboarding/application-error'
          || window.location.pathname === '/onboarding/mobile'
          || window.location.pathname === '/register/email/verify-otp'
          || window.location.pathname === '/enter-community'
          || window.location.pathname === '/onboarding/create-account')
      ) {
        if (entryApproval || !onboardingVerifyStepStatus) {
          history.replace('/');
        }
      } else if (redirectUrl) {
        history.replace(redirectUrl);
        store.dispatch({
          type: SET_REDIRECT_URL,
          payload: null,
        });
      }
    } else if (user?.onboarding_status == 'voice_onboard_start') {
      history.replace('/register/email/verify-otp');
    } else if (user?.onboarding_status === 'voice_email_confirmed' || user?.onboarding_status == 'voice_otp_sent') {
      history.replace('/onboarding/mobile');
    } else if (user?.onboarding_status === 'voice_otp_verified') {
      history.replace('/onboarding/create-account');
    }
  }

  if (isDomainVerified) {
    return (
      <Router history={history}>
        <Suspense fallback={<LoadingSpinner />}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Switch>
                {indexRoutes.map((route) => (
                  <CustomRoute
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    skipCommunityMemberVerification={route.skipCommunityMemberVerification}
                    exact={route.exact}
                    isProtected={route.isProtected}
                  />
                ))}
              </Switch>
              <ToastContainer
                className="px-4 md:px-6 lg:px-4 w-full max-w-7xl"
                autoClose={3000}
                position="bottom-center"
                hideProgressBar
                pauseOnFocusLoss={false}
                closeButton
                closeOnClick={false}
                draggable={false}
                limit={3}
              />
              <CommunityFavicon />
            </PersistGate>
          </Provider>
        </Suspense>
      </Router>
    );
  }

  return <CommunityNotFound />;
}

export default App;
