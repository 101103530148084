/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import {
  LIST_INBOX_USERS_REQUEST, SET_ACTIVE_PRIVATE_ROOM,
  ADD_BLOCKED_USER_IDS_PRIVATE_CHAT,
  PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS,
  REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT,
  RESET_PRIVATE_CHAT_STATE,
  SET_EARLIER_MESSAGES_PRIVATE_CHAT,
  SET_LAST_MESSAGE_PRIVATE_CHAT,
  SET_LOAD_MORE_PRIVATE_CHAT,
  SET_TEXT_MESSAGES_PRIVATE_CHAT,
  UPDATE_PRIVATE_CHAT_STATE,
  SET_PINNED_MESSAGE_PRIVATE_CHAT,
  SET_NOTIFICATION_STATUS_PRIVATE_CHAT,
  SET_PRIVATE_CHAT_LOADING,
  SET_PRIVATE_INBOX_TYPE,
  REQUEST_JOIN_TEXT_ROOM,
  CREATE_PRIVATE_TEXT_ROOM_REQUEST,
  SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT,
  UPDATE_PRIVATE_CHAT_UNREAD_STATUS,
  SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID,
  SET_LIST_INBOX_USERS_LOADING,
  REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET,
  RESET_PRIVATE_CHAT_TEXT_ROOM_STATE,
  SET_PRIVATE_ROOMS,
  SET_DM_USERS,
} from './actionTypes';
import { isGiphyUrl } from '../../helpers';
import { initialiseSocket } from './textActions';
import { captureException, LOGGER_JAVASCRIPT } from '../../services/SentryService';
import { imageFetch } from '../../services/preSignedAws';
import NotificationService from '../../services/notificationService';
import { getUserIdFromAuthCookie } from '../../constants/authUtils';
import { history } from '../../constants/utils';
import { Presence } from '../../sockets/phoenix';

let presences = {};

export const listPrivateRoomsForInbox = (data) => ({
  type: LIST_INBOX_USERS_REQUEST,
  payload: data,
});

export const listRequestForInbox = (data) => ({
  type: LIST_INBOX_USERS_REQUEST,
  payload: data,
});

export const setActivePrivateRoom = (privateRoomId) => ({
  type: SET_ACTIVE_PRIVATE_ROOM,
  payload: privateRoomId,
});

export const removeActivePrivateRoomAndReset = () => ({
  type: REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET,
});

export const setListInboxUserLoading = (loading) => ({
  type: SET_LIST_INBOX_USERS_LOADING,
  payload: loading,
});

export const setActivePrivateRoomFromUserId = (payload) => ({
  type: SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID,
  payload,
});

export const setPrivateInboxType = (type) => ({
  type: SET_PRIVATE_INBOX_TYPE,
  payload: type,
});

export const requestJoinTextRoom = (data) => ({
  type: REQUEST_JOIN_TEXT_ROOM,
  payload: data,
});

export const createPrivateTextRoom = (data) => ({
  type: CREATE_PRIVATE_TEXT_ROOM_REQUEST,
  payload: data,
});

export const setPrivateTextRoomRequestCount = (data) => ({
  type: SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT,
  payload: data,
});

/* All the chat related actions are below */
let timeoutVar;
let count = 0;
const MESSAGE_LIMIT = 25;
const TIME_LIMIT = 10000;
const _newMessages = [];

export const setLoadMorePrivateChat = (value) => ({
  type: SET_LOAD_MORE_PRIVATE_CHAT,
  payload: {
    value,
  },
});

export const setEarlierMessagesPrivateChat = (messages) => ({
  type: SET_EARLIER_MESSAGES_PRIVATE_CHAT,
  payload: {
    messages,
  },
});

export const privateChatChannelConnectionSuccess = (chatChannel, channelData) => ({
  type: PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS,
  payload: { chatChannel, channelData },
});

export const addBlockedUserIdsPrivateChat = (users) => ({
  type: ADD_BLOCKED_USER_IDS_PRIVATE_CHAT,
  payload: {
    users,
  },
});

export const removeBlockedUserIdsPrivateChat = (value) => ({
  type: REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT,
  payload: {
    value,
  },
});

export const resetPrivateChatState = () => ({
  type: RESET_PRIVATE_CHAT_STATE,
});

export const resetPrivateChatTextRoomState = () => ({
  type: RESET_PRIVATE_CHAT_TEXT_ROOM_STATE,
});

export const updatePrivateChatUnreadStatus = (roomId) => ({
  type: UPDATE_PRIVATE_CHAT_UNREAD_STATUS,
  payload: roomId,
});

export const updatePrivateChatState = (data) => ({
  type: UPDATE_PRIVATE_CHAT_STATE,
  payload: data,
});

export const renderPrivateChatChannelMessage = (messages, metadata) => (dispatch) => {
  let lastMessageId;
  let canLoadMore;

  if (metadata && metadata.after) {
    lastMessageId = metadata.after;
  }

  const newMessages = _.map(messages, generatePrivateMessageFromResponse);

  if (newMessages?.length > 0) {
    if (newMessages?.length < 15) {
      canLoadMore = false;
    } else {
      canLoadMore = true;
    }

    dispatch(
      updatePrivateChatState({
        messages: newMessages,
        lastMessageId,
        canLoadMore,
      }),
    );
  }
};

export const appendMessagePrivateChat = (newMessages) => (dispatch, getState) => {
  const { messages, hasEarlierMessages, blockedUserIds } = getState().privateChat;
  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = [...newMessages, ...messages];
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(updatePrivateChatState({ hasEarlierMessages: false }));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = [...newMessages, ...messages]
      .filter((item) => !blockedUserIds?.includes(item.user._id))
      .slice(0, MESSAGE_LIMIT);
  }
  dispatch(updatePrivateChatState({ messages: updatedMessages }));
};

export const deleteMessagePrivateChat = (message) => (dispatch, getState) => {
  const { messages, hasEarlierMessages, blockedUserIds } = getState().privateChat;
  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = messages.filter((item) => item._id !== message._id);
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(updatePrivateChatState({ hasEarlierMessages: false }));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = messages
      .filter((item) => !blockedUserIds?.includes(item.user._id) && item._id !== message._id)
      .slice(0, MESSAGE_LIMIT);
  }

  dispatch(updatePrivateChatState({ messages: updatedMessages }));
};

export const updateChatMessagesPrivateChat = (modVar = 10, delay = 500) => (dispatch) => {
  count += 1;
  if (_newMessages?.length === 0) return;
  if (count === 1) {
    dispatch(appendMessagePrivateChat([..._newMessages]));
    _newMessages.length = 0;
    return;
  }
  if (timeoutVar && count % modVar !== 0) {
    clearTimeout(timeoutVar);
  }
  timeoutVar = setTimeout(() => {
    dispatch(appendMessagePrivateChat([..._newMessages]));
    _newMessages.length = 0;
  }, delay);
};

export const setMessagesPrivateChat = (messages) => ({
  type: SET_TEXT_MESSAGES_PRIVATE_CHAT,
  payload: { messages },
});

export const generatePrivateMessageFromResponse = (response, { level = 0 } = {}) => {
  if (!response) return null;

  const currentUserID = getUserIdFromAuthCookie();
  console.error('response?.user? =>', response.parent_message);
  return {
    _id: response.id,
    message_id: response.message_id,
    isSent: response.isSent !== undefined && response.isSent !== null ? response.isSent : true,
    image: isGiphyUrl(response.body || '') ? response.body : '',
    text: !isGiphyUrl(response.body || '') ? response.body : '',
    image_url: response.image_url,
    createdAt: response.inserted_at,
    avatar: response?.user?.image ? imageFetch(response.user.image) : undefined,
    position: response?.user?.id === Number(currentUserID) ? 'right' : '',
    type: isGiphyUrl(response.body || '') ? 'photo' : 'text',
    user: {
      _id: response?.user?.id,
      avatar: response?.user?.image ? imageFetch(response.user.image) : undefined,
      'is_cool_human?': response.user['is_cool_human?'] || '',
      cool_human_badge_url: response?.user?.cool_human_badge_url,
      first_name: response.user?.first_name,
      last_name: response.user?.last_name,
      username: response.user?.username,
    },
    parent:
      response.parent_message && level === 0
        ? generatePrivateMessageFromResponse(response.parent_message, { level: 1 })
        : null,
  };
};

const receivePrivateChatMessage = (messages) => (dispatch, getState) => {
  const { auth } = getState();
  const userUUID = Number(auth.id);
  const reciveObj = _.map(messages, generatePrivateMessageFromResponse);
  const message = reciveObj[0];
  if (message.user._id !== userUUID) {
    _newMessages.unshift(message);
  }

  dispatch(updateChatMessagesPrivateChat(30, 1000));
};

export const setLastMessagePrivateChat = (id) => ({
  type: SET_LAST_MESSAGE_PRIVATE_CHAT,
  payload: {
    id,
  },
});

export const closePrivateChatChannelConnection = () => async (dispatch, getState) => {
  try {
    const { privateChat } = getState();

    const { chatChannel } = privateChat;

    if (chatChannel) {
      chatChannel.off('message:new');
      chatChannel.off('message:pinned');
      chatChannel.off('message:deleted');
	  chatChannel.push('leave');
      chatChannel.leave();
    }
    dispatch(resetPrivateChatState());
  } catch (error) {
    console.log({ error });
  }
};

export const privateMessagePinned = (data) => async (dispatch, getState) => {
  const { pinnedMessage } = getState().privateChat;
  const updatedMessage = generatePrivateMessageFromResponse(data.message);

  if (updatedMessage?._id === pinnedMessage?._id) {
    dispatch(setPinnedPrivateMessage(null));
  } else {
    dispatch(setPinnedPrivateMessage(updatedMessage));
  }
};

export const deletedPrivateChatMessage = (data) => (dispatch) => {
  const updatedMessage = generatePrivateMessageFromResponse(data.message);
  dispatch(deleteMessagePrivateChat(updatedMessage));
};

export const currentActiveRoomDeleted = () => (dispatch) => {
  dispatch(removeActivePrivateRoomAndReset());
  history.replace('/inbox');
};

export const setPinnedPrivateMessage = (pinnedMessage) => ({
  type: SET_PINNED_MESSAGE_PRIVATE_CHAT,
  payload: { pinnedMessage },
});

export const setNotificationStatus = (isNotificationMuted) => ({
  type: SET_NOTIFICATION_STATUS_PRIVATE_CHAT,
  payload: { isNotificationMuted },
});
export const mutePrivateChatNotifications = (value) => (dispatch, getState) => {
  try {
    const {
      privateChat: { chatChannel },
    } = getState();

    chatChannel.push('notifications:mute', { mute: value }).receive('ok', (data) => {
      dispatch(setNotificationStatus(data.muted));
    });
  } catch (error) {
    console.log({ error });
  }
};

export const initializePrivateChatChannel = (roomId) => async (dispatch, getState) => {
  try {
    const { auth, privateChat } = getState();
    let isProcessedError = false;

    if (roomId === privateChat?.roomId) {
      return;
    }

    if (!roomId) {
      throw new Error('ActiveroomId not present');
    }
    dispatch(updatePrivateChatState({ channelLoading: true }));
    dispatch(closePrivateChatChannelConnection());
    let socketConn = getState().socket.socket;
    if (!socketConn) {
      await initialiseSocket({ auth, dispatch, getState });
      socketConn = getState().socket.socket;
    }
    const chatChannel = socketConn.channel(`private_text:${roomId}`, {});

    chatChannel
      .join()
      .receive('ok', async (data) => {
        dispatch(renderPrivateChatChannelMessage(data.messages.data, data.messages.metadata));
        dispatch(
          privateChatChannelConnectionSuccess(
            chatChannel,
            data,
            // getPrivateChatChannelConfigs(data.channel_permissions),
            // generatePrivateMessageFromResponse(data.pinned_message),
            // data.notifs_muted,
          ),
        );
      })
      .receive('error', (error) => {
        if (error.code === 404 && isProcessedError === false) {
          isProcessedError = true;
          dispatch(currentActiveRoomDeleted());
          dispatch(closePrivateChatChannelConnection());
          dispatch(updatePrivateChatState({ redirect: true }));
        }
      })
      .receive('timeout', () => {
        NotificationService.error('Network Issue!');
        dispatch(updatePrivateChatState({ redirect: true }));
      });

    // const presence = new Presence(chatChannel);
    const currentUser = getState().user;
    chatChannel.on('message:new', (msg) => dispatch(receivePrivateChatMessage(msg)));
    chatChannel.on('presence_diff', (diff) => {
      presences = Presence.syncDiff(presences, diff);
      dispatch({
        type: SET_DM_USERS,
        payload: Object.keys(presences),
      });
    });
    chatChannel.on('presence_state', (state) => {
      presences = Presence.syncState(presences, state);
      dispatch({
        type: SET_DM_USERS,
        payload: Object.keys(presences),
      });
    });
    chatChannel.on('message:pinned', (data) => dispatch(privateMessagePinned(data)));
    chatChannel.on('message:deleted', (data) => dispatch(deletedPrivateChatMessage(data)));
    chatChannel.on('room:deleted', () => {
      dispatch(currentActiveRoomDeleted());
      dispatch({
        type: SET_DM_USERS,
        payload: [],
      });
    });
  } catch (error) {
    console.log({ error });
    captureException(error, LOGGER_JAVASCRIPT);
  }
};

export const setPrivateChatChannelLoading = (channelLoading) => ({
  type: SET_PRIVATE_CHAT_LOADING,
  payload: channelLoading,
});

export const setPrivateRooms = (payload) => ({
  type: SET_PRIVATE_ROOMS,
  payload,
});
