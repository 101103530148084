/* eslint-disable import/no-cycle */
import { all, fork, put, select, takeLatest } from 'redux-saga/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import { getActiveGroupId, getGroups } from '../selectors/CommunitySelectors';
import NotificationService from '../services/notificationService';
import {
  createShop,
  updateShop,
  createProduct,
  getShopItems,
  deleteProduct,
  updateProduct,
  purchaseProduct,
  verifyPurchase,
  getPurchasedShopItems,
  getShop,
} from '../services/shopServices';
import {
  GET_SHOP_ITEMS,
  SET_COMMUNITY_SHOP,
  DELETE_PRODUCT,
  SET_COMMUNITY_SHOP_SUCCESS,
  SET_COMMUNITY_SHOP_FAILED,
  UPDATE_COMMUNITY_SHOP,
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  PURCHSE_PRODUCT,
  VERIFY_STRIPE_PURCHASE,
  GET_PURCHASED_SHOP_ITEMS,
  GET_COMMUNITY_SHOP,
  UPDATE_CHANNEL_LIST,
  CREATE_GROUP_SUCCESS,
  UPDATE_COMMUNITY_SHOP_SUCCESS,
  VERIFY_RAZORPAY_PURCHASE,
  SET_CREATE_SHOP_LOADING,
} from '../store/actions/actionTypes';
import {
  initializePurchase,
  deleteProductSuccess,
  getShopItemsSuccess,
  setPurchaseSuccessData,
  getPurchasedShopItemsSuccess,
  setFormSubmitting,
  getShopItemsFailed,
  getPurchasedShopItemsFailed,
} from '../store/actions/shopActions';
import { getCreateProductData, getEditProductData, paymentStatus, Product } from '../store/models/ShopModel';
import { ITEMABLE_TYPE } from '../views/shopListing/constants';

function* getShopItemsSaga() {
  yield takeLatest(GET_SHOP_ITEMS, function* ({ payload }) {
    try {
      const response = yield getShopItems(payload);
      yield put(getShopItemsSuccess({ response, isFirstPage: payload?.isFirstPage, latest: payload?.latest }));
    } catch (error) {
      yield put(getShopItemsFailed());
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* getPurchasedShopItemsSaga() {
  yield takeLatest(GET_PURCHASED_SHOP_ITEMS, function* ({ payload }) {
    try {
      const response = yield getPurchasedShopItems(payload);
      yield put(getPurchasedShopItemsSuccess(response));
    } catch (error) {
      yield put(getPurchasedShopItemsFailed());
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* getShopSaga() {
  yield takeLatest(GET_COMMUNITY_SHOP, function* ({ payload: { communityId } }) {
    try {
      const response = yield getShop({ communityId });
      const info = response?.community_shop?.data;
      const included = response?.community_shop?.included;
      let stripe = null;
      let razorpay = null;
      if (info?.attributes?.stripe_account_id) {
        stripe = {
          stripe_account_status: info?.attributes?.stripe_account_status,
          stripe_account_id: info?.attributes?.stripe_account_id,
          isStripeConnected: info?.attributes?.stripe_account_status === 'connected',
          stripeCurrency : info?.attributes?.stripe_currency
        };
      }

      if (info?.relationships?.razorpay_account?.data?.id && info?.relationships?.razorpay_account?.data) {
        const razorpayData = included?.find((el) => info?.relationships?.razorpay_account?.data?.id == el?.id);
        razorpay = {
          account_number: razorpayData?.attributes?.account_number,
          account_type: razorpayData?.attributes?.account_type,
          beneficiary_name: razorpayData?.attributes?.beneficiary_name,
          business_name: razorpayData?.attributes?.business_name,
          business_type: razorpayData?.attributes?.business_type,
          community_shop_id: razorpayData?.attributes?.community_shop_id,
          email: razorpayData?.attributes?.email,
          ifsc_code: razorpayData?.attributes?.ifsc_code,
          metadata: razorpayData?.attributes?.metadata,
          name: razorpayData?.attributes?.name,
          status: razorpayData?.attributes?.status,
        };
      }

      const data = {
        id: info?.id,
        name: info?.attributes?.name,
        enabled: info?.attributes?.enabled,
        stripe,
        razorpay,
        payment_provider: info?.attributes?.payment_provider || '',
        go_live: info?.attributes?.go_live || false,
      };
      yield put({ type: SET_COMMUNITY_SHOP_SUCCESS, payload: data });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createShopSaga() {
  yield takeLatest(SET_COMMUNITY_SHOP, function* ({ payload }) {
    try {
      const response = yield createShop({
        communityId: payload?.communityId,
        name: payload?.shopName,
        enabled: payload?.isShopEnabled,
      });
      const data = {
        name: response?.data?.attributes?.name,
        enabled: response?.data?.attributes?.enabled,
        id: response?.data?.id,
      };
      yield put({ type: SET_COMMUNITY_SHOP_SUCCESS, payload: data });
      NotificationService.success('Shop has been created successfully');
    } catch (error) {
      yield put({ type: SET_COMMUNITY_SHOP_FAILED });
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateShopSaga() {
  yield takeLatest(UPDATE_COMMUNITY_SHOP, function* ({ payload }) {
    try {
      yield put({
        type: SET_CREATE_SHOP_LOADING,
        payload: { isLoading: true },
      });
      const response = yield updateShop({
        communityId: payload?.communityId,
        name: payload?.shopName,
        enabled: payload?.isShopEnabled,
        go_live: payload?.go_live,
        payment_provider: payload?.payment_provider || '',
      });
      const data = {
        name: response?.data?.attributes?.name,
        enabled: response?.data?.attributes?.enabled,
        id: response?.data?.id,
        go_live: payload?.go_live,
        payment_provider: payload?.payment_provider || '',
      };
      yield put({ type: UPDATE_COMMUNITY_SHOP_SUCCESS, payload: data });
      yield put({
        type: SET_CREATE_SHOP_LOADING,
        payload: { isLoading: false },
      });
      NotificationService.success('Community updated successfully');
    } catch (error) {
      yield put({ type: SET_COMMUNITY_SHOP_FAILED });
      yield put({
        type: SET_CREATE_SHOP_LOADING,
        payload: { isLoading: false },
      });
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createProductSaga() {
  yield takeLatest(CREATE_PRODUCT, function* ({ payload }) {
    try {
      const data = getCreateProductData(payload);
      const response = yield createProduct({ communityId: payload?.communityId, data });
      data.shop_item.id = response?.data?.id;
      yield put({
        type: CREATE_PRODUCT_SUCCESS,
        payload: data,
      });

      const activeGroupId = yield select(getActiveGroupId);

      if (
        data?.shop_item?.itemable_type === ITEMABLE_TYPE.COMMUNITY_CATEGORY &&
        activeGroupId === data?.shop_item?.group_id
      ) {
        const channels = yield select((state) => state?.channel?.channels);
        const newCategory = {
          channels: [],
          community_id: payload?.communityId,
          group_id: data?.shop_item?.group_id,
          id: response?.data?.itemable_id,
          isCollapsed: true,
          name: data?.shop_item?.itemable_name,
          position: channels[channels?.length - 1]?.position + 1,
        };
        yield put({
          type: UPDATE_CHANNEL_LIST,
          payload: [...channels, newCategory],
        });
      }

      if (data?.shop_item?.itemable_type === ITEMABLE_TYPE.GROUP) {
        const groups = yield select(getGroups);
        yield put({
          type: CREATE_GROUP_SUCCESS,
          payload: {
            id: response?.data?.itemable_id,
            name: data?.shop_item?.itemable_name,
            icon: data?.shop_item?.icon,
            main_group: false,
            community_id: payload?.community_id,
            position: groups?.length,
          },
          home_screen_metadata: {},
        });
      }
      NotificationService.success('Product has been created successfully');
    } catch (error) {
      yield put(setFormSubmitting({ isFormSubmitting: false }));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateProductSaga() {
  yield takeLatest(UPDATE_PRODUCT, function* ({ payload }) {
    try {
      const data = getEditProductData(payload);
      const products = [...payload.products];
      const response = yield updateProduct({
        data,
        communityId: payload?.communityId,
        productId: payload?.productId,
      });
      const editedItemIndex = products.findIndex((element) => {
        if (element?.id === payload?.productId) {
          return true;
        }
        return false;
      });
      const updatedProduct = new Product({
        ...data.shop_item,
        id: payload?.productId,
        itemable_type: payload?.productType,
      });
      products.splice(editedItemIndex, 1, updatedProduct);
      yield put({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: products,
      });
      NotificationService.success('Product has been updated successfully');
    } catch (error) {
      yield put(setFormSubmitting({ isFormSubmitting: false }));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* deleteProductListing() {
  yield takeLatest(DELETE_PRODUCT, function* ({ payload }) {
    try {
      const products = [...payload.products];
      const { productId, communityId } = payload;
      const response = yield deleteProduct({ communityId, productId });
      const updatedProductsArray = products.filter((item) => {
        if (item.id === productId) return false;
        return true;
      });
      yield put(deleteProductSuccess(updatedProductsArray));
      NotificationService.success('Product has been deleted successfully');
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* purchaseProductSaga() {
  yield takeLatest(PURCHSE_PRODUCT, function* ({ payload }) {
    try {
      const { communityId, productId } = payload;
      const data = { shop_item_purchase: {} };
      const response = yield purchaseProduct({ communityId, productId, data });
      yield put(initializePurchase(response));
      // NotificationService.success('Purchase successful');
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* verifyPurchaseSaga() {
  yield takeLatest(VERIFY_STRIPE_PURCHASE, function* ({ payload }) {
    try {
      const { communityId, purchaseId } = payload;
      const response = yield verifyPurchase({ communityId, purchaseId });
      const data = {
        ...response?.data?.shop_item,
        paymentStatus:
          response?.data?.payment_status === paymentStatus.INITIALIZED
            ? paymentStatus.PROCESSING
            : response?.data?.payment_status,
      };
      yield put(setPurchaseSuccessData(data));
      NotificationService.success('Purchase successful');
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* verifyRazorpayPurchaseSaga() {
  yield takeLatest(VERIFY_RAZORPAY_PURCHASE, function* ({ payload }) {
    try {
      const { communityId, shop_item_purchase, razorpay_order_id } = payload;
      const response = yield verifyPurchase({ communityId, purchaseId: razorpay_order_id });

      const data = {
        ...response?.data?.shop_item,
        paymentStatus:
          response?.data?.payment_status === paymentStatus.INITIALIZED
            ? paymentStatus.PROCESSING
            : response?.data?.payment_status,
      };
      yield put(setPurchaseSuccessData(data));
      NotificationService.success('Purchase successful');
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export default function* shopSaga() {
  yield all([
    fork(getShopItemsSaga),
    fork(getPurchasedShopItemsSaga),
    fork(getShopSaga),
    fork(createShopSaga),
    fork(updateProductSaga),
    fork(updateShopSaga),
    fork(createProductSaga),
    fork(deleteProductListing),
    fork(purchaseProductSaga),
    fork(verifyPurchaseSaga),
    fork(verifyRazorpayPurchaseSaga),
  ]);
}
