import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';
import { INBOX_TYPES } from '../constants';
import { history, parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  createPrivateTextRoomService, listInbox, listRequests, requestJoinTextRoom,
} from '../services/privateChatService';
import { fetchUserProfile } from '../services/UserService';
import {
  CREATE_PRIVATE_TEXT_ROOM_REQUEST,
  CREATE_PRIVATE_TEXT_ROOM_SUCCESS,
  LIST_INBOX_USERS_REQUEST,
  LIST_INBOX_USERS_SUCCESS,
  REQUEST_JOIN_TEXT_ROOM,
  REQUEST_JOIN_TEXT_ROOM_SUCCESS,
  SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID,
} from '../store/actions/actionTypes';
import { setNewChatRequest } from '../store/actions/chatActions';
import {
  listPrivateRoomsForInbox,
  setActivePrivateRoom,
  setPrivateInboxType,
  setPrivateTextRoomRequestCount,
  resetPrivateChatTextRoomState,
} from '../store/actions/privateChatActions';

const mapRecipientInInbox = (textRooms, recipients) => textRooms.map((room) => ({
  ...room,
  user: recipients[room?.id],
}));

function* listInboxUsers() {
  yield takeLatest(LIST_INBOX_USERS_REQUEST, function* ({ payload }) {
    try {
      let response;
      if (payload.inboxType === INBOX_TYPES.REQUESTS) {
        response = yield listRequests({ nextPageId: payload?.nextPageId });
      } else {
        response = yield listInbox({ nextPageId: payload?.nextPageId });
      }

      yield put({
        type: LIST_INBOX_USERS_SUCCESS,
        payload: {
          data: mapRecipientInInbox(
            response?.data?.private_text_rooms?.data || [],
            response?.data?.recipients || {},
          ) || [],
          nextPageId: response?.data?.private_text_rooms?.metadata?.after || null,
          requestCount: response?.data?.requests_count,
          reset: payload?.reset,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* requestJoinTextRoomSaga() {
  yield takeLatest(REQUEST_JOIN_TEXT_ROOM, function* ({ payload }) {
    try {
      const response = yield requestJoinTextRoom({
        accept: payload?.accept,
        textRoomId: payload?.textRoom?.id,
      });

      yield put(setPrivateTextRoomRequestCount(Number(payload.requestsCount) - 1));
      if (payload?.accept === false) {
        yield put(setActivePrivateRoom(null));

        yield put(listPrivateRoomsForInbox({
          reset: true,
          nextPageId: null,
          inboxType: INBOX_TYPES.REQUESTS,
        }));

        history.replace('/inbox');
        return;
      }

      yield put({
        type: REQUEST_JOIN_TEXT_ROOM_SUCCESS,
        payload: {
          textRoomId: response?.data?.private_text_room_id,
          user: payload?.textRoom?.user,
        },
      });

      history.replace(`/inbox?user_id=${payload?.textRoom?.user?.id}`);
      // console.log(response);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createPrivateTextRoomSaga() {
  yield takeLatest(CREATE_PRIVATE_TEXT_ROOM_REQUEST, function* ({ payload }) {
    try {
      const response = yield createPrivateTextRoomService({
        user_id: payload?.user_id,
      });

      yield put({
        type: CREATE_PRIVATE_TEXT_ROOM_SUCCESS,
        payload: {
          response,
          user: payload?.userData,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* setActivePrivateRoomByUserIdSaga() {
  yield takeLatest(SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID, function* ({ payload }) {
    try {
      // Check if user exist in current text room
      const checkExistInPrivateRoomList = payload?.privateTextRooms
        ?.find((data) => Number(data.user.id) === Number(payload.userId));

      if (checkExistInPrivateRoomList) {
        yield put(setActivePrivateRoom(checkExistInPrivateRoomList?.id));
        if (payload?.accept !== 'true') {
		  yield put(setNewChatRequest(false));
          return;
        }
      }

	  if(!checkExistInPrivateRoomList)
	  {
		yield put(setNewChatRequest(true));
		return
	  }

      // if (!currentUser.data.followed) {
      //   throw new Error('User is not followed.');
      // }

      const response = yield createPrivateTextRoomService({
        user_id: payload?.userId,
      });

      if (response?.data?.current_user?.room_type === 'request') {
        if (payload?.accept === 'true') {
          const requestJoinTextRoomResponse = yield requestJoinTextRoom({
            accept: true,
            textRoomId: response?.data?.private_text_room?.id,
          });

          if (Number(payload.requestsCount) > 0) {
            yield put(setPrivateTextRoomRequestCount(Number(payload.requestsCount) - 1));
          }

          history.replace(`/inbox?user_id=${payload?.userId}`);
        } else {
          history.push(`/inbox?user_id=${payload.userId}&type=request`);
          if (payload?.inboxType !== INBOX_TYPES.REQUESTS) {
            yield (put(resetPrivateChatTextRoomState()));
            yield (put(setPrivateInboxType(INBOX_TYPES.REQUESTS)));
            return;
          }
        }
      } else {
        history.push(`/inbox?user_id=${payload.userId}`);
        if (payload?.inboxType !== INBOX_TYPES.INBOX) {
          yield (put(resetPrivateChatTextRoomState()));
          yield (put(setPrivateInboxType(INBOX_TYPES.INBOX)));
          return;
        }
      }

      yield put({
        type: CREATE_PRIVATE_TEXT_ROOM_SUCCESS,
        payload: {
          response,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      payload.history.push('/inbox');
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(listInboxUsers),
    fork(requestJoinTextRoomSaga),
    fork(createPrivateTextRoomSaga),
    fork(setActivePrivateRoomByUserIdSaga),
  ]);
}
