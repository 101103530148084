/* eslint-disable no-useless-escape */
import translate from '../i18n';

const $translatei18n = translate;

export const API_ERROR_MESSAGES = {
  EMAIL_OR_PASSWORD_INVALID: 'Email or password is invalid',
};

export const CHANNEL_TYPES = {
  FORUM: 'forum',
  RESOURCE: 'resources',
  STAGE: 'stage',
  CALENDER: 'calendar',
  CHAT: 'text',
  TEXT: 'text',
  BOOKMARKS: 'bookmarks',
  ZOOM: 'zoom',
  EMBED: 'embed',
  STREAMING: 'streaming'
};

export const ADDITIONAL_CHANNEL_TYPES = {
  OVERVIEW: 'overview',
};

export const ROOM_TYPES = {
  MAIN: 'main',
  OVERFLOW: 'overflow',
};

export const REPORT_TYPE = {
  FORUM_POST: 'Forum Post',
  FORUM_COMMENT: 'Forum Comment',
  FORUM_REPLY: 'Forum Reply',
  RESOURCE_COMMENT: 'Resource Comment',
  USER_REPORT: 'User Report',
};

export const REPORT_REASON_TITLE = 'Why are you reporting this?';

export const REPORT_REASONS = [$translatei18n('Spam'), $translatei18n('Offensive'), $translatei18n('Bullying'), $translatei18n('HateSpeech'), $translatei18n('Inappropriate'), $translatei18n('Pornography'), $translatei18n('Others')];

export const ROUTE_PATH = {
  FORUM_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/communities/:community_id/group/:group_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/communities/:community_id/group/:group_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS:
    '/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE:
    '/communities/:community_id/group/:group_id/channels/:channel_id/resource/create/:resource_post_id?',
  COMMUNITY_OVERVIEW_DETAILS: '/',
  CALENDAR_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts',
  ZOOM_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/zoomposts',
  ZOOM_EVENT: '/communities/:community_id/group/:group_id/channels/:channel_id/zoomevent/:event_id',
  CALENDAR_POST_DETAILS: '/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/communities/:community_id/group/:group_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/stage',
  STREAMING_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/streaming',
  TEXT_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/text',
  TEXT_CHANNEL_EMBEDDED: '/communities/:community_id/group/:group_id/channels/:channel_id/embedded/text',
  EVENT_CREATE: '/communities/:community_id/group/:group_id/event/create',
  BOOKMARKS: '/profile/bookmarks',
  SHOPLISTING: '/shoplisting',
  EMBED: '/communities/:community_id/group/:group_id/channels/:channel_id/iframe',
  STREAMING_CHANNEL_VIEW: '/communities/:community_id/group/:group_id/channels/:channel_id/streaming/:event_id',
};

export const OLD_ROUTE_PATH = {
  FORUM_POSTS: '/communities/:community_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/communities/:community_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/communities/:community_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/communities/:community_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS: '/communities/:community_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE: '/communities/:community_id/channels/:channel_id/resource/create/:resource_post_id?',
  CALENDAR_POSTS: '/communities/:community_id/channels/:channel_id/calendarposts',
  CALENDAR_POST_DETAILS: '/communities/:community_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/communities/:community_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/communities/:community_id/channels/:channel_id/stage',
  TEXT_CHANNEL: '/communities/:community_id/channels/:channel_id/text',
  EVENT_CREATE: '/communities/:community_id/event/create',
};

export const ROUTE_PATH_PUBLIC = {
  FORUM_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resource/create/:resource_post_id?',
  COMMUNITY_OVERVIEW_DETAILS: '/guest',
  CALENDAR_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts',
  ZOOM_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/zoomposts',
  ZOOM_EVENT: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/zoomevent/:event_id',
  CALENDAR_POST_DETAILS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/stage',
  TEXT_CHANNEL: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/text',
  TEXT_CHANNEL_EMBEDDED: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/embedded/text',
  EVENT_CREATE: '/guest/communities/:community_id/group/:group_id/event/create',
  BOOKMARKS: '/profile/bookmarks',
  SHOPLISTING: '/shoplisting',
  EMBED: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/iframe',
};

export const FORUM_REQUEST_ORIGINS = {
  FORUM_LIST_PAGE: 'FORUM_LIST_PAGE',
  FORUM_DETAILS_PAGE: 'FORUM_DETAILS_PAGE',
  COMMUNITY_FEED_PAGE: 'COMMUNITY_FEED_PAGE',
};

export const SEE_MORE_LINES = {
  FORUM_POST: 5,
  FORUM_COMMENT: 5,
  FORUM_REPLY: 5,
  RESOURCE_FEED: 5,
  REPORT_SUMMARY: 5,
};

export const LOGIN_IFRAME_ID = 'login-ifr';

export const ONBOARD_IFRAME_ID = 'onboard-ifr';

export const COMMUNITY_COLOR_ACCENT = [
  '#F87171',
  '#F59E0B',
  '#10B981',
  '#3B82F6',
  '#6366F1',
  '#8B5CF6',
  '#F472B6',
  '#0EA5E9',
  '#1F2937',
];

export const COMMUNITY_ROLE_COLOR = [
  '#FDE68A',
  '#FECACA',
  '#E5E7EB',
  '#A7F3D0',
  '#BFDBFE',
  '#C7D2FE',
  '#DDD6FE',
  '#FBCFE8',
  '#FED7AA',
  '#D9F99D',
  '#BBF7D0',
  '#A5F3FC',
  '#E9D5FF',
  '#FECDD3',
];

export const URL_REGEX = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/;
export const LINK_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const VIDEO_URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.\+#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%\+.#?&//=]*)/;

export const INBOX_TYPES = {
  INBOX: 'INBOX',
  REQUESTS: 'REQUESTS',
};

export const GLOBAL_SEARCH_TYPES = [
  { key: 'all', identifier: 'all', label: $translatei18n('All') },
  { key: 'forum', identifier: 'forum_posts', label: $translatei18n('Forums') },
  { key: 'resource', identifier: 'resource_posts', label: $translatei18n('Resources') },
  { key: 'calendar_event', identifier: 'calendar_events', label: $translatei18n('Events') },
  { key: 'user', identifier: 'community_users', label: $translatei18n('People') },
  { key: 'group', identifier: 'groups', label: $translatei18n('Groups') },
  { key: 'channel', identifier: 'channels', label: $translatei18n('Channels') },
];

export const GLOBAL_PUBLIC_SEARCH_TYPES = [
  { key: 'all', identifier: 'all', label: $translatei18n('All') },
  { key: 'forum', identifier: 'forum_posts', label: $translatei18n('Forums') },
  { key: 'resource', identifier: 'resource_posts', label: $translatei18n('Resources') },
  { key: 'calendar_event', identifier: 'calendar_events', label: $translatei18n('Events') },
  { key: 'channel', identifier: 'channels', label: $translatei18n('Channels') },
];

export const CREATE_INVITE_EXPIRY = [
  { key: '1_hour', label: 'An Hour' },
  { key: '12_hour', label: '12 Hours' },
  { key: '1_day', label: 'A Day' },
  { key: '1_week', label: 'A Week' },
  { key: '1_month', label: 'A Month' },
];

export const chatLayouts = {
  whatsapp_telegram_style: 'Whatsapp / Telegram Style',
  slack_discord_style: 'Slack / Discord Style',
};

export const chatLayoutForApi = {
  'Whatsapp / Telegram Style': 'whatsapp_telegram_style',
  'Slack / Discord Style': 'slack_discord_style',
};

export const ScreenShareError = {
  SAFARI_BROWSER:
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  OTHER_BROWSER: 'Permission denied by system',
};
