import posthog from 'posthog-js';
const REACT_APP_ENV = process.env.REACT_APP_ENV;

export default class PosthogService {
  static initialize() {
    if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
      posthog.init(process.env.REACT_APP_POSTHOG_TRACKING_KEY, {
        api_host: 'https://app.posthog.com',
        autocapture: false,
        debug: true,
        session_recording: false,
      });
    }
  }

  static identify(user) {
    if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
      posthog.identify(user?.id, {
        username: user?.username,
        email: user?.email,
        first_name: user?.first_name,
        last_name: user?.last_name,
        gender: user?.gender,
        id: user?.id,
        onboarding_status: user?.onboarding_status,
        uuid: user?.uuid,
        verified: user?.verified,
        voice_onboarding_status: user?.voice_onboarding_status,
        avatar: user?.image,
      });
    }
  }

  static capture(event_name, properties = {}) {
    if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
      posthog.capture(event_name, properties);
    }
  }

  static setProperty(properties = {}) {
    if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
      posthog.people.set(properties);
    }
  }

  static reset() {
    posthog.reset(true);
  }
}
