import React from 'react';
import { useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/solid';
import {
  CubeIcon,
  DocumentIcon,
  GiftIcon,
  HomeIcon,
  VolumeUpIcon,
} from '@heroicons/react/outline';
import { imageFetch } from '../../services/preSignedAws';
import { getActiveCommunity } from '../../selectors/CommunitySelectors';
import personIcon from '../../assets/images/icons/person.svg';
import person1 from '../../assets/images/icons/person1.svg';
import person2 from '../../assets/images/icons/person2.svg';
import person3 from '../../assets/images/icons/person3.svg';
import person4 from '../../assets/images/icons/person4.svg';
import person5 from '../../assets/images/icons/person5.svg';

export function GroupSection() {
  const currentActiveCommunity = useSelector(getActiveCommunity);
  return (
    <div className="absolute top-5 -right-2 card shadow rounded-lg h-3/4" style={{ width: '35%' }}>
      <div className="absolute inset-0 flex flex-col space-y-6 items-center justify-center">
        <div className="relative">
          <img
            className="h-9 w-9 rounded-lg sm:h-32 sm:w-32"
            src={imageFetch(currentActiveCommunity?.icon)}
            alt=""
          />
          <div className="main--background flex items-center space-x-1 px-1 rounded-3xl border absolute -left-1 -bottom-2">
            <LockClosedIcon className="w-2 h-2 main__icon" />
            <span style={{ fontSize: 8 }} className="text__body">Locked</span>
          </div>
        </div>
        <div className="relative" style={{ filter: 'grayscale(1)' }}>
          <img
            className="h-9 w-9 rounded-lg sm:h-32 sm:w-32"
            src={imageFetch(currentActiveCommunity?.icon)}
            alt=""
          />
          <div className="main--background flex items-center space-x-1 px-1 rounded-3xl border absolute -left-1 -bottom-2">
            <LockClosedIcon className="w-2 h-2 main__icon" />
            <span style={{ fontSize: 8 }} className="text__body">Locked</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CategorySection() {
  return (
    <div className="absolute top-5 -right-2 card shadow rounded-lg h-3/4 p-3" style={{ width: '35%' }}>
      <div className="flex items-center mb-3">
        <span className="text__body ltr:mr-2 rtl:ml-2" style={{ fontSize: 10 }}>Sales Hacker</span>
        <div className="main--background flex items-center space-x-1 px-1 rounded-3xl border">
          <LockClosedIcon className="w-2 h-2 main__icon" />
          <span style={{ fontSize: 8 }} className="text__body">Locked</span>
        </div>
      </div>
      <div className="flex items-center mb-3">
        <HomeIcon className="w-3 h-3 ltr:mr-2 rtl:ml-2  main__icon" />
        <div className="main--background w-2/3 h-2 rounded-3xl" />
      </div>

      <div className="flex items-center mb-3">
        <GiftIcon className="w-3 h-3 ltr:mr-2 rtl:ml-2  main__icon" />
        <div className="main--background w-2/4 h-2 rounded-3xl" />
      </div>

      <div className="flex items-center mb-3">
        <VolumeUpIcon className="w-3 h-3 ltr:mr-2 rtl:ml-2  main__icon" />
        <div className="main--background w-3/4 h-2 rounded-3xl" />
      </div>

      <div className="flex items-center mb-3">
        <DocumentIcon className="w-3 h-3 ltr:mr-2 rtl:ml-2  main__icon" />
        <div className="main--background w-2/4 h-2 rounded-3xl" />
      </div>

      <div className="flex items-center">
        <CubeIcon className="w-3 h-3 ltr:mr-2 rtl:ml-2  main__icon" />
        <div className="main--background w-3/5 h-2 rounded-3xl" />
      </div>
    </div>
  );
}

export function RoleSection() {
  return (
    <div className="absolute top-5 -right-2 card shadow rounded-lg h-3/4 p-3" style={{ width: '35%' }}>
      <div>
        <div className="bg-gray-50 max-w-max flex items-center space-x-1 px-1 rounded-lg border">
          <LockClosedIcon className="w-2 h-2 text-purple-700" />
          <span style={{ fontSize: 8 }} className="text-purple-700">OG Members</span>
        </div>
        <div className="my-2">
          <div className="flex items-center space-x-1 my-1">
            <img src={personIcon} alt="person" className="h-3 w-3 rounded-full" />
            <div className="flex flex-col w-full space-y-1">
              <div className="bg-gray-50 w-1/5 h-0.5 rounded-3xl" />
              <div className="bg-gray-50 w-3/6 h-0.5 rounded-3xl" />
            </div>
          </div>
          <div className="flex items-center space-x-1 my-1">
            <img src={personIcon} alt="person" className="h-3 w-3 rounded-full" />
            <div className="flex flex-col w-full space-y-1">
              <div className="bg-gray-50 w-1/5 h-0.5 rounded-3xl" />
              <div className="bg-gray-50 w-3/6 h-0.5 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="bg-red-50 max-w-max flex items-center space-x-1 px-1 rounded-lg border">
          <LockClosedIcon className="w-2 h-2 text-red-700" />
          <span style={{ fontSize: 8 }} className="text-red-700">Design Mafia</span>
        </div>
        <div className="my-2">
          <div className="flex items-center space-x-1 my-1">
            <img src={personIcon} alt="person" className="h-3 w-3 rounded-full" />
            <div className="flex flex-col w-full space-y-1">
              <div className="bg-gray-50 w-1/5 h-0.5 rounded-3xl" />
              <div className="bg-gray-50 w-3/6 h-0.5 rounded-3xl" />
            </div>
          </div>
          <div className="flex items-center space-x-1 my-1">
            <img src={personIcon} alt="person" className="h-3 w-3 rounded-full" />
            <div className="flex flex-col w-full space-y-1">
              <div className="bg-gray-50 w-1/5 h-0.5 rounded-3xl" />
              <div className="bg-gray-50 w-3/6 h-0.5 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <div className="bg-blue-50 max-w-max flex items-center space-x-1 px-1 rounded-lg border">
          <LockClosedIcon className="w-2 h-2 text-blue-700" />
          <span style={{ fontSize: 8 }} className="text-blue-700">OG Members</span>
        </div>
        <div className="my-2">
          <div className="flex items-center space-x-1 my-1">
            <img src={personIcon} alt="person" className="h-3 w-3 rounded-full" />
            <div className="flex flex-col w-full space-y-1">
              <div className="bg-gray-50 w-1/5 h-0.5 rounded-3xl" />
              <div className="bg-gray-50 w-3/6 h-0.5 rounded-3xl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
